import {
  Box,
  Drawer,
  Stack,
  Divider,
  Typography,
  IconButton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { formatDate } from "../../../../utils";

import useHrmStore from "../../../../stores/hrManagement";

const HrmEmployeeTaxPayerDetailDrawer = () => {
  const state = useHrmStore((state) => state.drawer);
  const data: any = useHrmStore((state) => state.drawerData);
  const toggleDrawer = useHrmStore((state) => state.toggleDrawer);

  return (
    <Drawer
      anchor="right"
      open={state}
      onClose={() => {
        toggleDrawer();
      }}
    >
      <Box sx={{ p: 3, pt: 2, width: 400 }} width={300} height="100%">
        <Stack height="100%" spacing={2}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Stack
              direction="row"
              spacing={1}
              sx={{ flex: 1 }}
              alignItems="center"
            >
              <Typography
                variant="body1"
                sx={{ width: 170, wordWrap: "break-word" }}
              >
                Detalhes #{data.i_empregados}
              </Typography>
            </Stack>
            <IconButton
              onClick={() => {
                toggleDrawer();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider sx={{ my: 2 }} />
          <Typography variant="body1" fontWeight={600}>
            {data.nome}
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Stack direction="row" spacing={1}>
            <Typography variant="caption" fontWeight="bold" sx={{ flex: 1 }}>
              Data de Admissão
            </Typography>
            <Typography variant="caption">
              {formatDate(data.admissao, "D/M/Y")}
            </Typography>
          </Stack>
          <Divider sx={{ my: 1 }} />
          <Stack direction="row" spacing={1}>
            <Typography variant="caption" fontWeight="bold" sx={{ flex: 1 }}>
              Código no Sistema
            </Typography>
            <Typography variant="caption">{data.i_empregados}</Typography>
          </Stack>
          <Divider sx={{ my: 1 }} />
          <Stack direction="row" spacing={1}>
            <Typography variant="caption" fontWeight="bold" sx={{ flex: 1 }}>
              Data de Nascimento
            </Typography>
            <Typography variant="caption">
              {formatDate(data.data_nascimento, "D/M/Y")}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Drawer>
  );
};

export default HrmEmployeeTaxPayerDetailDrawer;
