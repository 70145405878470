import { create } from "zustand";
import { fetchData } from "../fetchData";
import useAuthStore from "./auth";
import useNavStore from "./nav";

type State = {
  drawer: boolean;
  progress: boolean;
  path: string;
  data: object[];
  drawerData: object;
};

const initialState: State = {
  drawer: false,
  progress: false,
  path: "uploads",
  data: [],
  drawerData: {},
};

type Actions = {
  setPath: (path: string) => void;
  setBack: () => void;
  getData: () => void;
  reset: () => void;
  breadcrumb: (index: number) => void;
  getFolderName: (path: string) => string;
  toggleDrawer: (data?: object) => void;
  createFolder: (name: string) => Promise<boolean>;
  deleteFolder: () => void;
};

const useCloudStore = create<State & Actions>((set, get) => ({
  ...initialState,

  setPath: (path: string) => {
    const regex = /\/$/;

    set(() => ({
      data: [],
      path: path
        .replace(`${useAuthStore.getState().company._id}/`, "")
        .replace(regex, ""),
    }));
  },

  setBack: () => {
    const regex = /\/$/;
    const array = get().path.split("/");
    array.pop();

    set(() => ({
      path: array.join("/").replace(regex, ""),
    }));
  },

  breadcrumb: (current: number) => {
    const array = get().path.split("/");
    const newPath: string[] = [];

    array.map((path: string, index: number) => {
      if (index <= current) {
        newPath.push(path);
      }
    });

    get().setPath(newPath.join("/"));
  },

  getFolderName: (path: string) => {
    const array = path.split("/");
    array.pop();
    const folderName = array[array.length - 1];

    return folderName;
  },

  getData: async () => {
    set(() => ({ data: initialState.data }));
    set(() => ({ progress: true }));

    const id = useAuthStore.getState().company._id;
    try {
      const result = await fetchData(`/cloud/${id}?path=${get().path}`, {
        method: "GET",
      });

      const folders = result.folders.map((row: any) => {
        return {
          path: row,
          name: get().getFolderName(row),
          type: "folder",
          id: row,
        };
      });
      const files = result.files.map((row: any) => ({
        ...row,
        type: "file",
      }));

      set(() => ({ data: [...folders, ...files] }));
    } catch (error) {
      console.log(error);
    }

    set(() => ({ progress: false }));
  },

  reset: () =>
    set(() => ({
      ...initialState,
    })),

  toggleDrawer: (data?: object) => {
    set((state: State) => ({
      drawer: !state.drawer,
      drawerData: data ? data : [],
    }));
  },

  createFolder: async (name: string) => {
    try {
      await fetchData(
        `/cloud/${useAuthStore.getState().company._id}/newFolder`,
        {
          method: "PUT",
          body: JSON.stringify({ key: `${get().path}/${name}` }),
        },
      );

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  deleteFolder: async () => {
    const snackbar = useNavStore.getState().setSnackbar;
    console.log(`${useAuthStore.getState().company._id}/${get().path}`);

    fetchData(
      `/cloud?key=${useAuthStore.getState().company._id}/${get().path}/`,
      {
        method: "DELETE",
      },
      false,
    )
      .then((response) => {
        get().setBack();
        snackbar("Pasta excluída com sucesso!", "success");
      })
      .catch((error) => {
        console.log(error);
        snackbar("Ops! Não foi possível excluir a pasta.", "error");
      });
  },
}));

export default useCloudStore;
