import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  CircularProgress,
  Button,
  Link,
  TextField,
  Alert,
} from "@mui/material";

import useAuthStore from "../../stores/auth";
import useNavStore from "../../stores/nav";

const SignIn = () => {
  const navigate = useNavigate();
  const snackbar = useNavStore((state) => state.setSnackbar);
  const login = useAuthStore((state) => state.login);
  const [progress, setProgress] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
  });

  function onSubmit(data: any) {
    setProgress(true);

    login(data.username, data.password).then((result) => {
      if (!result) {
        snackbar("Suas credenciais de acesso estão incorretas!", "error");
        setProgress(false);
      }
    });
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          {...register("username", {
            required: "Informe seu nome de usuário.",
          })}
          label="Nome de usuário"
          variant="outlined"
          fullWidth
          margin="dense"
          error={errors?.username ? true : false}
        />
        {errors?.username && (
          <Alert severity="error">{errors.username.message}</Alert>
        )}
        <TextField
          {...register("password", {
            required: "Informe sua senha de acesso.",
          })}
          label="Senha"
          variant="outlined"
          fullWidth
          margin="dense"
          type="password"
          error={errors?.password ? true : false}
        />
        {errors?.password && (
          <Alert severity="error">{errors.password.message}</Alert>
        )}
        <Button
          fullWidth={true}
          color="primary"
          variant="contained"
          disabled={progress}
          size="large"
          type="submit"
          sx={{ mt: 1 }}
        >
          {progress && <CircularProgress size={24} />}
          {!progress && "Entrar"}
        </Button>
      </form>
      <Link
        component="button"
        variant="body2"
        color="secondary"
        onClick={() => {
          navigate("/recover");
        }}
        sx={{
          marginTop: 3,
          fontWeight: "400",
          textTransform: "initial",
        }}
      >
        Esqueceu sua senha?
      </Link>
    </>
  );
};

export default SignIn;
