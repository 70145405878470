import { useState, useRef, useEffect } from "react";
import { PatternFormat } from "react-number-format";
import DatePickerField from "../../../../../components/DatePickerField";

import {
  Box,
  Drawer,
  Stack,
  Divider,
  Typography,
  IconButton,
  Button,
  Chip,
  CircularProgress,
  TextField,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import CloudDoneIcon from "@mui/icons-material/CloudDone";

import useHrmEmployeeStore from "../../../../../stores/hrEmployee";

const grau_de_instrucao = [
  "Analfabeto",
  "Mestrado",
  "Doutorado",
  "Ph. D.",
  "Pós Graduação",
  "Primario incompleto",
  "Primario completo",
  "Ginasio incompleto",
  "Ginasio completo",
  "Ensino Fundamental até 5º Incompleto",
  "Ensino Fundamental 5º Completo",
  "Ensino Fundamento 6º ao 9º Incompleto  ",
  "Ensino Fundamental Completo",
  "2o. grau incompleto",
  "2o. grau completo",
  "Superior incompleto",
  "Superior completo",
];

const nacionalidade = [
  "Brasileiro",
  "Naturalizado",
  "Argentino",
  "Boliviano",
  "Chileno",
  "Paraguaio",
  "Uruguaio",
  "Alemao",
  "Belga",
  "Britanico",
  "Canadense",
  "Espanhol",
  "Norte-Americano",
  "Frances",
  "Suico",
  "Italiano",
  "Japones",
  "Chines",
  "Coreano",
  "Portugues",
  "Outros latino-am.",
  "Outros asiaticos",
  "Outros",
];

const raca_cor = ["Indigena", "Branca", "Preta", "Amarela", "Parda"];

const estado_civil = [
  "Solteiro",
  "Casado",
  "Viúvo",
  "Divorciado",
  "Concubinato",
  "Separado judicialmente",
  "União estável",
];

const HRManagementEmployeePersonal = () => {
  const [state, setState] = useState(false);
  const openRef = useRef(null);
  const saveDraft = useHrmEmployeeStore((state: any) => state.saveDraft);
  const changeDraft = useHrmEmployeeStore((state: any) => state.changeDraft);
  const addDrawersRef = useHrmEmployeeStore(
    (state: any) => state.addDrawersRef,
  );
  const isSaving = useHrmEmployeeStore((state: any) => state.isSaving);
  const data = useHrmEmployeeStore((state: any) => state.data);

  useEffect(() => addDrawersRef({ name: "personal", ref: openRef }), []);

  return (
    <>
      <Button
        fullWidth
        ref={openRef}
        variant="outlined"
        onClick={() => {
          setState(true);
        }}
        disabled={data?.status === "editing" ? false : true}
      >
        <Stack spacing={1} alignItems="center">
          <PersonIcon fontSize="large" />
          <div>Pessoal</div>
        </Stack>
      </Button>
      <Drawer
        anchor="left"
        open={state}
        onClose={() => {
          setState(false);
        }}
      >
        <Box sx={{ p: 3, pt: 2, width: 400 }} width={300} height="100%">
          <Stack height="100%" spacing={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack
                direction="row"
                spacing={1}
                sx={{ flex: 1 }}
                alignItems="center"
              >
                <Typography variant="body1">Pessoal</Typography>
                {data.status === "editing" && (
                  <Chip
                    color="success"
                    variant="outlined"
                    icon={
                      isSaving === true ? (
                        <CircularProgress color="warning" size={18} />
                      ) : (
                        <CloudDoneIcon fontSize="small" />
                      )
                    }
                    label={isSaving ? "Salvando..." : "Tudo Salvo"}
                  />
                )}
              </Stack>
              <IconButton
                onClick={() => {
                  setState(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Divider sx={{ my: 2 }} />
            <PatternFormat
              format="##/##/####"
              name="data_de_nascimento"
              fullWidth
              variant="filled"
              label="Data de Nascimento"
              defaultValue={data.draft?.data_de_nascimento || null}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              customInput={TextField}
              sx={{ mb: 1 }}
              required
            />
            <TextField
              name="grau_de_instrucao"
              fullWidth
              variant="filled"
              select
              size="small"
              label="Grau de Instrução"
              defaultValue={data.draft?.grau_de_instrucao || null}
              SelectProps={{ native: true }}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
              required
            >
              <option value=""></option>
              {grau_de_instrucao.map((row: string, index: number) => (
                <option value={row} key={`grau_de_instrucao_${index}`}>
                  {row}
                </option>
              ))}
            </TextField>
            <TextField
              name="genero"
              fullWidth
              variant="filled"
              select
              size="small"
              label="Gênero"
              defaultValue={data.draft?.genero || null}
              SelectProps={{ native: true }}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
              required
            >
              <option value=""></option>
              <option value="Masculino">Masculino</option>
              <option value="Feminino">Feminino</option>
            </TextField>
            <TextField
              name="nacionalidade"
              fullWidth
              variant="filled"
              select
              size="small"
              label="Nacionalidade"
              defaultValue={data.draft?.nacionalidade || null}
              SelectProps={{ native: true }}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
              required
            >
              <option value=""></option>
              {nacionalidade.map((row: string, index: number) => (
                <option value={row} key={`nacionalidade_${index}`}>
                  {row}
                </option>
              ))}
            </TextField>
            <TextField
              name="estadonascimento"
              fullWidth
              variant="filled"
              select
              size="small"
              label="Estado de Nascimento"
              defaultValue={data.draft?.estadonascimento || null}
              SelectProps={{ native: true }}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
              required
            >
              <option value=""></option>
              <option value="AC">AC</option>
              <option value="AL">AL</option>
              <option value="AM">AM</option>
              <option value="AP">AP</option>
              <option value="BA">BA</option>
              <option value="CE">CE</option>
              <option value="DF">DF</option>
              <option value="ES">ES</option>
              <option value="EX">EX</option>
              <option value="GO">GO</option>
              <option value="MA">MA</option>
              <option value="MG">MG</option>
              <option value="MS">MS</option>
              <option value="MT">MT</option>
              <option value="PA">PA</option>
              <option value="PB">PB</option>
              <option value="PE">PE</option>
              <option value="PI">PI</option>
              <option value="PR">PR</option>
              <option value="RJ">RJ</option>
              <option value="RN">RN</option>
              <option value="RO">RO</option>
              <option value="RR">RR</option>
              <option value="RS">RS</option>
              <option value="SC">SC</option>
              <option value="SE">SE</option>
              <option value="SP">SP</option>
              <option value="TO">TO</option>
            </TextField>
            <TextField
              name="municipionascimento"
              fullWidth
              variant="filled"
              label="Município de Nascimento"
              defaultValue={data.draft?.municipionascimento || null}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
              required
            />
            <TextField
              name="raca_cor"
              fullWidth
              variant="filled"
              select
              size="small"
              label="Raça/Cor"
              defaultValue={data.draft?.raca_cor || null}
              SelectProps={{ native: true }}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
              required
            >
              <option value=""></option>
              {raca_cor.map((row: string, index: number) => (
                <option value={row} key={`raca_cor_${index}`}>
                  {row}
                </option>
              ))}
            </TextField>

            <TextField
              name="estado_civil"
              fullWidth
              variant="filled"
              select
              size="small"
              label="Estado Civil"
              defaultValue={data.draft?.estado_civil || null}
              SelectProps={{ native: true }}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
              required
            >
              <option value=""></option>
              {estado_civil.map((row: string, index: number) => (
                <option value={row} key={`estado_civil_${index}`}>
                  {row}
                </option>
              ))}
            </TextField>
            <TextField
              name="nome_mae"
              fullWidth
              variant="filled"
              label="Nome da Mãe"
              defaultValue={data.draft?.nome_mae || null}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
              required
            />
            <TextField
              name="nome_pai"
              fullWidth
              variant="filled"
              label="Nome do Pai"
              defaultValue={data.draft?.nome_pai || null}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
            />
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default HRManagementEmployeePersonal;
