import { useEffect, useState } from "react";
import {
  Box,
  Drawer,
  Stack,
  Divider,
  Typography,
  IconButton,
  Chip,
  Button,
  CircularProgress,
  Skeleton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import BlockIcon from "@mui/icons-material/Block";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { formatDate } from "../../../../utils";
import { profileSwitch } from "../../../../utils/profileHandler";
import { fetchData } from "../../../../fetchData";

import useContactStore from "../../../../stores/contact";

const ContactDetailDrawer = () => {
  const state = useContactStore((state) => state.detailDrawer);
  const data: any = useContactStore((state) => state.detailDrawerData);
  const toggleDrawer = useContactStore((state) => state.toggleDetailDrawer);
  const [row, setRow] = useState<any>({});
  const [progress, setProgress] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const fetchContactData = async () => {
    setProgress(true);

    try {
      const result = await fetchData(`/users/${data.id}`, {
        method: "GET",
      });

      console.log(result);
      setRow(result);
    } catch (error) {
      console.log(error);
    }

    setProgress(false);
  };

  const handleStatusChange = async () => {
    const data = row;
    row.status = !row.status;
    setIsSaving(true);

    try {
      await fetchData(`/users/${data._id}`, {
        method: "PUT",
        body: JSON.stringify(data),
      });

      setRow(data);
    } catch (error) {
      console.log(error);
    }

    setIsSaving(false);
  };

  useEffect(() => {
    if (data.id && state) {
      fetchContactData();
    }
  }, [data.id]);

  return (
    <Drawer
      anchor="right"
      open={state}
      onClose={() => {
        toggleDrawer();
      }}
    >
      <Box sx={{ p: 3, pt: 2, width: 400 }} width={300} height="100%">
        <Stack height="100%" spacing={2}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Stack
              direction="row"
              spacing={1}
              sx={{ flex: 1 }}
              alignItems="center"
            >
              <Typography
                variant="body1"
                sx={{ width: 170, wordWrap: "break-word" }}
              >
                Detalhes
              </Typography>
            </Stack>
            <IconButton
              onClick={() => {
                toggleDrawer();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider sx={{ my: 2 }} />
          <Typography variant="body1" fontWeight={600}>
            {!progress && row.name}
            {progress && <Skeleton variant="rectangular" />}
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Stack direction="row" spacing={1}>
            <Typography variant="caption" fontWeight="bold" sx={{ flex: 1 }}>
              Login de acesso
            </Typography>
            <Typography variant="caption">
              {!progress && row.login}
              {progress && <Skeleton variant="rectangular" width={100} />}
            </Typography>
          </Stack>
          <Divider sx={{ my: 1 }} />
          <Stack direction="row" spacing={1}>
            <Typography variant="caption" fontWeight="bold" sx={{ flex: 1 }}>
              Tipo de acesso
            </Typography>
            <ExpandMoreIcon />
          </Stack>
          <Stack direction="row" spacing={1}>
            {row.profile &&
              row.profile.map((row: string) => (
                <Typography key={row} variant="caption" sx={{ flex: 1 }}>
                  {profileSwitch(row)}
                </Typography>
              ))}
            {progress && <Skeleton variant="rectangular" width="100%" />}
          </Stack>
          <Divider sx={{ my: 1 }} />
          <Stack direction="row" spacing={1}>
            <Typography variant="caption" fontWeight="bold" sx={{ flex: 1 }}>
              Situação
            </Typography>
            {progress && <Skeleton variant="rectangular" width={100} />}
            {!progress && (
              <Chip
                size="small"
                label={row.status ? "Ativo" : "Inativo"}
                color={row.status ? "primary" : "error"}
                variant="outlined"
                sx={{ textTransform: "uppercase" }}
              />
            )}
          </Stack>
          <Divider sx={{ my: 1 }} />
          <Stack direction="row" spacing={1}>
            <Typography variant="caption" fontWeight="bold" sx={{ flex: 1 }}>
              Documento
            </Typography>
            <Typography variant="caption">
              {!progress && row.document}
              {progress && <Skeleton variant="rectangular" width={100} />}
            </Typography>
          </Stack>
          <Divider sx={{ my: 1 }} />
          <Stack direction="row" spacing={1}>
            <Typography variant="caption" fontWeight="bold" sx={{ flex: 1 }}>
              Criado em
            </Typography>
            <Typography variant="caption">
              {!progress && formatDate(row.created_at, "D/M/Y")}
              {progress && <Skeleton variant="rectangular" width={100} />}
            </Typography>
          </Stack>
          <Divider sx={{ my: 1 }} />
          <Stack direction="column" spacing={2}>
            {!progress && (
              <Button
                startIcon={
                  row.status ? <BlockIcon /> : <CheckCircleOutlineIcon />
                }
                variant="outlined"
                color={row.status ? "error" : "success"}
                fullWidth
                size="small"
                onClick={() => handleStatusChange()}
              >
                {row.status && !isSaving && "Inativar Contato"}
                {!row.status && !isSaving && "Reativar Contato"}
                {isSaving && (
                  <CircularProgress
                    size={22}
                    color={row.status ? "error" : "success"}
                  />
                )}
              </Button>
            )}
            {progress && (
              <Skeleton variant="rectangular" width="100%" height={30} />
            )}
          </Stack>
        </Stack>
      </Box>
    </Drawer>
  );
};

export default ContactDetailDrawer;
