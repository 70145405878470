import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Typography,
  Button,
  Divider,
  LinearProgress,
  Stack,
  Alert,
  AlertTitle,
  Link,
  Box,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import BusinessIcon from "@mui/icons-material/Business";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import SupportIcon from "@mui/icons-material/Support";

import SwitchOrganization from "./drawers/switchOrganization";
import Ticket from "../Ticket";

import useAuthStore from "../../../stores/auth";
import useNavStore from "../../../stores/nav";

import DashboardRRTasks from "./rrTasks";

function Dashboard() {
  const navigate = useNavigate();
  const user: any = useAuthStore((state) => state.user);
  const company = useAuthStore((state) => state.company);
  const setHeaderTitle = useNavStore((state) => state.setHeader);
  const setModal = useNavStore((state: any) => state.setModal);
  const closeModal = useNavStore((state: any) => state.closeModal);

  useEffect(() => {
    setHeaderTitle(`Olá, ${user?.nickname}.`);
  }, []);

  return (
    <>
      <Grid container spacing={3}>
        <Grid xs={12} md={4}>
          <Card>
            {company.state && <LinearProgress />}
            {!company.state && (
              <>
                <Typography variant="h3" fontWeight={700}>
                  {company?.reference}
                </Typography>
                <Typography variant="body2" color="text.disabled">
                  {company?.name}
                </Typography>
                <Stack
                  spacing={2}
                  direction={{ xs: "column", xl: "row" }}
                  sx={{ mt: 2 }}
                >
                  {user?.client.length > 1 && <SwitchOrganization />}
                  <Button
                    sx={{ flex: 1 }}
                    variant="outlined"
                    size="medium"
                    startIcon={<BusinessIcon />}
                    onClick={() => navigate("/mycompany")}
                  >
                    Minha PJ
                  </Button>
                </Stack>
              </>
            )}
          </Card>
          <Card sx={{ mt: 3 }}>
            <Typography variant="h5">Acesso Rápido</Typography>
            <Divider sx={{ my: 3 }} />
            <Button
              fullWidth
              variant="contained"
              size="medium"
              color="success"
              startIcon={<WhatsAppIcon />}
              sx={{ mb: 2 }}
              onClick={() => window.open("https://wa.me/551130030506")}
            >
              WhatsApp
            </Button>
            <Ticket />
            {user.profile.includes("ADMIN") && (
              <Button
                fullWidth
                variant="contained"
                size="medium"
                color="secondary"
                startIcon={<PermContactCalendarIcon />}
                sx={{ mt: 2 }}
                onClick={() => navigate("/contacts")}
              >
                Contatos de Relacionamento
              </Button>
            )}
            <Button
              fullWidth
              variant="contained"
              size="medium"
              color="error"
              startIcon={<SupportIcon />}
              sx={{ mt: 2 }}
              onClick={() => navigate("/help")}
            >
              Central de Ajuda
            </Button>
          </Card>
        </Grid>
        <Grid xs={12} md={8}>
          <Alert severity="info" sx={{ mb: 3 }}>
            <AlertTitle>Nova funcionalidade disponível</AlertTitle>
            Admissão de Pessoas.{" "}
            <Link
              component="button"
              onClick={() =>
                setModal(
                  "Admissão de Pessoas",
                  <Box
                    component="iframe"
                    src={`https://www.youtube.com/embed/ma9w6tUSDng?si=h1ZfZ12zcbK6JwyG`}
                    width="100%"
                    height={315}
                    frameBorder={0}
                    allowFullScreen
                  />,
                  "Novidade",
                )
              }
            >
              Clique aqui
            </Link>{" "}
            e veja como funciona.
          </Alert>
          <Card>
            <Typography variant="h5">Últimas atividades</Typography>
            <Divider sx={{ my: 3 }} />
            <DashboardRRTasks />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default Dashboard;
