import { useState } from "react";

import {
  Box,
  Drawer,
  Stack,
  Divider,
  Typography,
  IconButton,
  Button,
  Card,
  CardContent,
  TextField,
  CardActions,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  List,
  ListItem,
  ListItemButton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import CachedIcon from "@mui/icons-material/Cached";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

import useAuthStore from "../../../../stores/auth";
import useCloudStore from "../../../../stores/cloud";

const SwitchOrganization = () => {
  const [state, setState] = useState(false);
  const organizations = useAuthStore((state) => state.user?.client);
  const switchOrg = useAuthStore((state) => state.switchOrganization);
  const selected = useAuthStore((state) => state.company.reference);
  const resetCloud = useCloudStore((state) => state.reset);

  const switchRef = (data: any) => {
    switchOrg({ ...data, state: false });
    setState(false);
    resetCloud();
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={() => {
          setState(true);
        }}
        size="medium"
        startIcon={<CachedIcon />}
      >
        Alternar empresa
      </Button>
      <Drawer
        anchor="left"
        open={state}
        onClose={() => {
          setState(false);
        }}
      >
        <Box sx={{ p: 3, pt: 2, width: 400 }} width={300} height="100%">
          <Stack height="100%" spacing={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack
                direction="row"
                spacing={1}
                sx={{ flex: 1 }}
                alignItems="center"
              >
                <Typography
                  variant="body1"
                  sx={{ width: 170, wordWrap: "break-word" }}
                >
                  Selecione a empresa
                </Typography>
              </Stack>
              <IconButton
                onClick={() => {
                  setState(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Divider sx={{ my: 2 }} />
            <List>
              {organizations?.map((row: any) => (
                <ListItem key={row.reference} disablePadding divider>
                  <ListItemButton
                    onClick={() => {
                      switchRef(row);
                    }}
                    selected={selected === row.reference}
                    disabled={selected === row.reference}
                  >
                    <ListItemIcon>
                      {selected === row.reference && (
                        <RadioButtonCheckedIcon color="primary" />
                      )}
                      {selected !== row.reference && (
                        <RadioButtonUncheckedIcon color="primary" />
                      )}
                    </ListItemIcon>
                    <ListItemText disableTypography>
                      <Typography
                        variant="body2"
                        fontWeight={selected === row.reference ? 600 : 400}
                      >
                        {row.name}
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default SwitchOrganization;
