import { useState, useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import {
  Avatar,
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Container,
  Button,
  Typography,
  Tooltip,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";

import Logo from "../../assets/images/symbol.svg";
import LogoHorizontal from "../../assets/images/logoHorizontal.svg";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

import Modal from "../../components/Modal";
import Snackbar from "../../components/Snackbar";

import useAuthStore from "../../stores/auth";
import useNavStore from "../../stores/nav";
import useCloudStore from "../../stores/cloud";
import { stringAvatar } from "../../utils/stringAvatar";

interface Props {
  window?: () => Window;
}

const drawerWidth = 240;

export default function Client(props: Props) {
  const { window } = props;
  const setPath = useCloudStore((state: any) => state.setPath);
  const logout = useAuthStore((state: any) => state.logout);
  const company = useAuthStore((state: any) => state.company);
  const tokenVerify = useAuthStore((state) => state.tokenVerify);
  const user = useAuthStore((state) => state.user);
  const headerTitle = useNavStore((state: any) => state.header);
  const currentItem = useNavStore((state: any) => state.currentItem);
  const setCurrentItem = useNavStore((state: any) => state.setCurrentItem);
  const toolbar = useNavStore((state: any) => state.toolbar);
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorElUser, setAnchorElUser] = useState<null>(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const date = new Date();
  const navItems = [
    {
      id: "dashboard",
      title: "Dashboard",
      link: "/",
      show: true,
    },
    {
      id: "tasks",
      title: "Atividades",
      link: "tasks",
      show: true,
    },
    {
      id: "files",
      title: "Arquivos",
      link: "cloud",
      show: true,
    },
    {
      id: "pj",
      title: "Minha PJ",
      link: "mycompany",
      show: true,
    },
    {
      id: "pessoas",
      title: "Pessoas",
      link: "hr",
      show: true,
    },
  ].filter((item) => item.show);

  useEffect(() => {
    tokenVerify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <img src={Logo} width="30" alt={process.env.REACT_APP_NAME} />
      <Divider />
      <List>
        {navItems.map((item: any, index: number) => (
          <ListItem key={item.title} disablePadding>
            <ListItemButton
              sx={{ textAlign: "center" }}
              onClick={() => {
                navigate(item.link);
              }}
            >
              <ListItemText primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{
          py: 1,
          boxShadow: 0,
          backgroundColor: "#181d3a",
          borderBottom: "1px solid #28316A",
        }}
      >
        <Toolbar component={Container} maxWidth="xl">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <img
            src={Logo}
            width="30"
            alt={process.env.REACT_APP_NAME}
            style={{ marginBottom: "2px" }}
          />
          <Box sx={{ display: { xs: "none", sm: "block" }, ml: 4 }}>
            {navItems.map((item: any, index: number) => (
              <Button
                key={item.title}
                sx={{ color: "#fff" }}
                size="small"
                variant={
                  item.link === "/" && location.pathname === "/"
                    ? "contained"
                    : location.pathname.includes(item.link) && item.link !== "/"
                    ? "contained"
                    : "text"
                }
                color="primary"
                onClick={() => {
                  setCurrentItem(item.id);

                  if (item.id === "files") {
                    setPath("uploads");
                  }

                  navigate(item.link);
                }}
              >
                {item.title}
              </Button>
            ))}
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              paddingLeft: "1em",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                paddingLeft: "1em",
                display: { xs: "none", md: "flex" },
              }}
            ></Box>
            {/* <Box sx={{ flexGrow: 0 }}>
          {/* <Search /> }
        </Box> */}
            <Box sx={{ flexGrow: 0, paddingLeft: "1em" }}>
              <Tooltip title={user?.name}>
                <IconButton
                  onClick={(e) => {
                    handleOpenUserMenu(e);
                  }}
                  sx={{ p: 0 }}
                >
                  {/* <Avatar alt="John Doe" src="/static/images/avatar/2.jpg" /> */}
                  <Avatar {...stringAvatar(user?.name)} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {/* <MenuItem
                  onClick={() => {
                    handleCloseUserMenu();
                    navigate("/preferences");
                  }}
                >
                  <SettingsIcon
                    sx={{ color: "text.primary", marginRight: ".5em" }}
                    onClick={() => {
                      navigate("/preferences");
                    }}
                  />
                  <Typography textAlign="center">Preferências</Typography>
                </MenuItem>
                <Divider /> */}
                <MenuItem onClick={logout}>
                  <LogoutOutlinedIcon
                    sx={{ color: "text.primary", marginRight: ".5em" }}
                  />
                  <Typography textAlign="center">Sair</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Toolbar sx={{ backgroundColor: "#181d3a" }} />
      <Container
        maxWidth={false}
        disableGutters
        sx={{ backgroundColor: "#181d3a", pb: 10, mb: "-60px" }}
      >
        <Container maxWidth="xl" sx={{ pt: 10 }}>
          <Typography variant="h3" color="white" textTransform="capitalize">
            {headerTitle.toLowerCase() || `Olá, ${user?.nickname}.`}
          </Typography>
          {toolbar && (
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              alignItems="flex-end"
            >
              {toolbar}
            </Stack>
          )}
        </Container>
      </Container>
      <Container maxWidth="xl" sx={{ zIndex: 1 }}>
        <Box component="main" sx={{ pb: 5 }}>
          <Outlet />
          <Stack alignItems="center">
            <Divider sx={{ width: "100%", my: 5 }} />
            <Stack direction="row" alignItems="center" spacing={1}>
              <img
                src={LogoHorizontal}
                width="120"
                alt={process.env.REACT_APP_NAME}
                style={{ marginBottom: "2px" }}
              />
              <Typography variant="caption" sx={{ pt: "3px" }}>
                © {date.getFullYear()}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Container>
      <Modal />
      <Snackbar />
    </>
  );
}
