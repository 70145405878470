import { createRef, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

import useNavStore from "../../stores/nav";
import useAuthRecoverStore from "../../stores/authRecover";

const validationItems = [
  {
    name: "uppercase",
    label: "Pelo menos uma letra maiúscula.",
  },
  {
    name: "lowercase",
    label: "Pelo menos uma letra minúscula.",
  },
  {
    name: "specialChar",
    label: "Pelo menos um caractere especial (@#$!%&).",
  },
  {
    name: "number",
    label: "Pelo menos um número.",
  },
  {
    name: "minLength",
    label: "Pelo menos 10 caracteres de comprimento.",
  },
];

const ForgetPasswordStepFour = () => {
  const snackbar = useNavStore((state) => state.setSnackbar);
  const login = useAuthRecoverStore((state) => state.login);
  const nextStep = useAuthRecoverStore((state) => state.nextStep);
  const setLogin = useAuthRecoverStore((state) => state.setLogin);
  const updatePassword = useAuthRecoverStore((state) => state.updatePassword);
  const [progress, setProgress] = useState(false);
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [validations, setValidations] = useState<any>({
    uppercase: false,
    lowercase: false,
    specialChar: false,
    number: false,
    minLength: false,
  });

  const validation = () => {
    // Verificações das condições
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasSpecialChar = /[@#$!%&]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasMinLength = password.length >= 10;

    setValidations({
      uppercase: hasUppercase,
      lowercase: hasLowercase,
      specialChar: hasSpecialChar,
      number: hasNumber,
      minLength: hasMinLength,
    });
  };

  const areAllValidationsTrue = () => {
    return (
      validations.uppercase &&
      validations.lowercase &&
      validations.specialChar &&
      validations.number &&
      validations.minLength
    );
  };

  const onSubmit = async () => {
    if (!password) {
      snackbar("Informe a senha para continuar.", "warning");
      return;
    }
    if (!areAllValidationsTrue()) {
      snackbar(
        "Por favor, verifique os critérios de senha para continuar.",
        "warning",
      );
      return;
    }
    if (!confirm) {
      snackbar("Confirme a nova senha para continuar.", "warning");
      return;
    }
    if (password !== confirm) {
      snackbar(
        "A confirmação de senha não confere com a senha informada",
        "error",
      );
      return;
    }

    setProgress(true);

    const result: any = await updatePassword(password);

    if (!result) {
      snackbar("Ops! Não foi possível atualizar a senha no momento.", "error");
    } else {
      nextStep();
    }

    setProgress(false);
  };

  useEffect(() => validation(), [password]);

  return (
    <>
      <Typography
        variant="h6"
        component="div"
        gutterBottom
        sx={{ fontWeight: 300, marginBottom: 0 }}
      >
        Defina sua nova senha.
      </Typography>
      <Typography
        variant="caption"
        component="div"
        gutterBottom
        sx={{ fontWeight: 300, marginBottom: 1 }}
      >
        Sua nova senha precisa obedecer
        <br />
        os seguintes critérios:
      </Typography>
      <Stack sx={{ mb: 1 }}>
        <Divider sx={{ my: 1 }} />
        {validationItems.map((row: any, index: number) => (
          <Box key={index}>
            <Stack direction="row" spacing={1} justifyContent="start">
              {password === "" && (
                <RadioButtonUncheckedIcon fontSize="small" color="disabled" />
              )}
              {password !== "" && validations[row.name] && (
                <CheckCircleIcon fontSize="small" color="success" />
              )}
              {password !== "" && !validations[row.name] && (
                <CancelIcon fontSize="small" color="error" />
              )}
              <Typography variant="caption" textAlign="left">
                {row.label}
              </Typography>
            </Stack>
            <Divider sx={{ my: "3px" }} />
          </Box>
        ))}
      </Stack>
      <TextField
        label="Senha"
        variant="outlined"
        type="password"
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
        }}
        color="primary"
        fullWidth={true}
        margin="dense"
      />
      <TextField
        label="Confirme a Senha"
        variant="outlined"
        value={confirm}
        type="password"
        onChange={(e) => {
          setConfirm(e.target.value);
        }}
        color="primary"
        fullWidth={true}
        margin="dense"
      />
      <Button
        fullWidth={true}
        color="primary"
        variant="contained"
        disabled={progress}
        onClick={() => onSubmit()}
        size="large"
        style={{ marginTop: "8px" }}
      >
        {progress && <CircularProgress size={24} />}
        {!progress && "Alterar"}
      </Button>
    </>
  );
};

export default ForgetPasswordStepFour;
