import React from "react";

import {
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Box,
  Chip,
  SelectChangeEvent,
} from "@mui/material";

interface Props {
  register: any;
  get: any;
  label: string;
  required?: string;
  name: string;
  error: boolean;
  children: React.ReactNode;
  size: "medium" | "small" | undefined;
}

const SelectChipField = (props: Props) => {
  const { onChange, ref } = props.register(props.name, {
    // eslint-disable-next-line react/destructuring-assignment
    required: props.required,
  });

  const [value, setValue] = React.useState<string[]>(props.get(props.name));

  const handleChange = (event: SelectChangeEvent<typeof value>) => {
    const {
      target: { value },
    } = event;
    setValue(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };

  return (
    <FormControl margin="dense" sx={{ width: "100%" }}>
      <InputLabel id={`${props.name}-multiple-chip-label`}>
        {props.label}
      </InputLabel>
      <Select
        size={props.size}
        name={props.name}
        ref={ref}
        onChange={(e) => {
          handleChange(e);
          onChange(e);
        }}
        labelId={`${props.name}-multiple-chip-label`}
        id={`${props.name}-multiple-chip`}
        multiple
        error={props.error}
        value={value}
        input={
          <OutlinedInput
            id={`${props.name}-select-multiple-chip`}
            label={props.label}
          />
        }
        renderValue={(item: any) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {item.map((value: string) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
      >
        {props.children}
      </Select>
    </FormControl>
  );
};

SelectChipField.defaultProps = {
  size: "medium",
};

export default SelectChipField;
