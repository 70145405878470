import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import TagManager from "react-gtm-module";

const container = document.getElementById("root");
const root = createRoot(container as HTMLElement);

const tagManagerArgs = {
  gtmId: "GTM-PNN39DZ5",
};

TagManager.initialize(tagManagerArgs);

root.render(
  // <StrictMode>
  <App />,
  // </StrictMode>,
);
