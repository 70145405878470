import { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import {
  Box,
  Drawer,
  Stack,
  Divider,
  Typography,
  IconButton,
  Button,
  Card,
  CardContent,
  TextField,
  CardActions,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  List,
  ListItem,
  ListItemButton,
  Link,
} from "@mui/material";

import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CloseIcon from "@mui/icons-material/Close";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import LinkIcon from "@mui/icons-material/Link";

import { formatDate } from "../../../../utils";
import { Progress } from "../../../../components/DataGrid";

import { fetchRRData } from "../../../../fetchRRData";
import useTaskStore from "../../../../stores/task";

const TaskDetailDrawer = () => {
  const state = useTaskStore((state) => state.drawer);
  const data: any = useTaskStore((state) => state.drawerData);
  const toggleDrawer = useTaskStore((state) => state.toggleDrawer);
  const download = useTaskStore((state) => state.download);
  const zipLinkCopy = useTaskStore((state) => state.zipLinkCopy);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchFiles = async () => {
    try {
      const docs = await fetchRRData(`/tasks/${data.id}/documents`, {
        method: "GET",
      });

      setFiles(docs);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (state) {
      fetchFiles();
    } else {
      setFiles([]);
      setLoading(true);
    }
  }, [state]);

  const columns: GridColDef[] = [
    {
      field: "file_name",
      headerName: "Nome",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Stack
            direction="row"
            spacing={1}
            sx={{ cursor: "pointer" }}
            alignItems="center"
            onClick={() => download(params.row.id, "file")}
          >
            {params.row.type === "file" && (
              <InsertDriveFileIcon color="secondary" />
            )}
            <Typography variant="body2" color="primary">
              {params.row.file_name}
            </Typography>
          </Stack>
        );
      },
    },
  ];

  return (
    <Drawer
      anchor="right"
      open={state}
      onClose={() => {
        toggleDrawer();
      }}
    >
      <Box sx={{ p: 3, pt: 2, width: 400 }} width={300} height="100%">
        <Stack height="100%" spacing={2}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Stack
              direction="row"
              spacing={1}
              sx={{ flex: 1 }}
              alignItems="center"
            >
              <Typography
                variant="body1"
                sx={{ width: 170, wordWrap: "break-word" }}
              >
                Atividade #{data.id}
              </Typography>
            </Stack>
            <IconButton
              onClick={() => {
                toggleDrawer();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider sx={{ my: 2 }} />
          <Typography variant="body1" fontWeight={600}>
            {data.title}
          </Typography>
          <Divider sx={{ my: 1, mt: 3 }} />
          <Stack direction="column" spacing={2}>
            <Button
              variant="outlined"
              fullWidth
              size="small"
              color="success"
              startIcon={<CloudDownloadIcon />}
              onClick={() => download(data.id, "zip")}
            >
              Download Zip
            </Button>
            <Button
              startIcon={<LinkIcon />}
              variant="outlined"
              color="secondary"
              fullWidth
              size="small"
              onClick={() => zipLinkCopy(data.id)}
            >
              Copiar Link
            </Button>
          </Stack>
          <Divider sx={{ my: 1 }} />
          <Stack direction="row" spacing={1}>
            <Typography variant="caption" fontWeight="bold" sx={{ flex: 1 }}>
              Data
            </Typography>
            <Typography variant="caption">
              {formatDate(data.close_date)}
            </Typography>
          </Stack>
          <Divider sx={{ my: 1 }} />
          <Stack direction="row" spacing={1}>
            <Typography variant="caption" fontWeight="bold" sx={{ flex: 1 }}>
              Tipo
            </Typography>
            <Typography variant="caption">{data.type_name}</Typography>
          </Stack>
          <Divider sx={{ my: 1 }} />
          <Stack direction="row" spacing={1}>
            <Typography variant="caption" fontWeight="bold" sx={{ flex: 1 }}>
              Projeto
            </Typography>
            <Typography variant="caption">{data.project_name}</Typography>
          </Stack>
          <Divider sx={{ my: 1 }} />
          <Stack direction="row" spacing={1}>
            <Typography variant="caption" fontWeight="bold" sx={{ flex: 1 }}>
              Anexos
            </Typography>
            <Typography variant="caption">{data.attachments_count}</Typography>
          </Stack>
          <DataGrid
            rows={files}
            columns={columns}
            hideFooter={true}
            autoHeight
            loading={loading}
            disableRowSelectionOnClick
            columnHeaderHeight={0}
            slots={{
              loadingOverlay: Progress,
            }}
          />
        </Stack>
      </Box>
    </Drawer>
  );
};

export default TaskDetailDrawer;
