import { useEffect, useState } from "react";
import {
  Card,
  Button,
  Tabs,
  Tab,
  Stack,
  Unstable_Grid2 as Grid,
  Typography,
  Skeleton,
  Alert,
  LinearProgress,
} from "@mui/material";

import useNavStore from "../../../stores/nav";
import useAuthStore from "../../../stores/auth";

import { fetchData } from "../../../fetchData";

import BusinessIcon from "@mui/icons-material/Business";
import PlaceIcon from "@mui/icons-material/Place";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

const initialData = {
  fantasia: "",
  abertura: "",
  situacao: "",
  atividade_principal: [
    {
      code: 0,
      text: "",
    },
  ],
  logradouro: "",
  numero: 0,
  complemento: "",
  bairro: "",
  municipio: "",
  uf: "",
  cep: "",
  qsa: [],
};

const TypographyHeader = (props: any) => {
  return (
    <Typography
      variant="h6"
      fontWeight={600}
      sx={{
        pl: 1,
        mb: 2,
        borderLeft: "5px solid",
        borderLeftColor: "primary.main",
      }}
    >
      {props.children}
    </Typography>
  );
};

function MyCompany() {
  const company = useAuthStore((state: any) => state.company);
  const setHeaderTitle = useNavStore((state: any) => state.setHeader);
  const snackbar = useNavStore((state) => state.setSnackbar);
  const [progress, setProgress] = useState(false);
  const [cloudProgress, setCloudProgress] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState<any>(initialData);
  const [cloudError, setCloudError] = useState(false);
  const [cloud, setCloud] = useState<any>({
    folders: [],
    files: [],
  });

  const handleCloudApi = async () => {
    setCloudProgress(true);
    setError(false);

    try {
      const result = await fetchData(`/cloud/${company._id}?path=documents`, {
        method: "GET",
      });

      setCloud(result);
    } catch (error) {
      console.log(error);
      setCloudError(true);
    }

    setCloudProgress(false);
  };

  const handleData = async () => {
    setProgress(true);

    try {
      const response = await fetchData(
        `/organizations/${company.reference}/document`,
        {
          method: "GET",
        },
      );

      if (response) {
        setData(response);
      }
    } catch (error) {
      console.log(error);
      setError(true);
    }

    setProgress(false);
  };

  const handleError = (msg: string) => {
    snackbar(msg, "error");
  };

  const handleDownload = async (key: string, name: string) => {
    try {
      fetchData(
        `/cloud/download?key=${key}`,
        {
          method: "GET",
        },
        false,
      )
        .then((response) => response.blob())
        .then((blob) => URL.createObjectURL(blob))
        .then((url) => {
          const link = document.createElement("a");
          link.href = url;
          link.download = name;

          document.body.appendChild(link);

          link.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            }),
          );

          document.body.removeChild(link);
        })
        .catch((error) => {
          handleError("Ops! No momento não foi possível baixar o arquivo.");
        });
    } catch (error) {
      handleError("Ops! No momento não foi possível baixar o arquivo.");
    }
  };

  useEffect(() => {
    setHeaderTitle(company.name);
    handleData();
    handleCloudApi();
  }, []);

  return (
    <Grid container spacing={3}>
      {error && (
        <Grid xs={12} md={8}>
          <Alert
            severity="warning"
            sx={{
              flexDirection: "column",
              alignItems: "center",
              textAlign: "cenrer",
              borderRadius: 2,
            }}
          >
            <Stack spacing={2} alignItems="center" direction="column">
              <Typography variant="h6" fontWeight={600}>
                Ops!
              </Typography>
              <Typography variant="body2">
                Tempo da requisição excedido. Tente novamente em alguns minutos
              </Typography>
            </Stack>
          </Alert>
        </Grid>
      )}
      {!error && (
        <Grid xs={12} md={4}>
          {progress && (
            <Stack spacing={2}>
              <Skeleton variant="rounded" height={200} />
              <Skeleton variant="rounded" height={100} />
              <Skeleton variant="rounded" height={100} />
              <Skeleton variant="rounded" height={150} />
            </Stack>
          )}
          {!progress && (
            <>
              <Card>
                <>
                  <BusinessIcon sx={{ fontSize: "5em" }} />
                  <Typography variant="h4" fontWeight={600}>
                    {data.fantasia}
                  </Typography>
                  <Typography variant="body2" color="text.disabled">
                    {data.cnpj}
                  </Typography>
                </>
              </Card>
              <Card sx={{ mt: 2 }}>
                <TypographyHeader>Data de Abertura</TypographyHeader>
                <Typography variant="body1">{data.abertura}</Typography>
              </Card>
              <Card sx={{ mt: 2 }}>
                <TypographyHeader>Situação</TypographyHeader>
                <Typography variant="body1">{data.situacao}</Typography>
              </Card>
              <Card sx={{ mt: 2 }}>
                <TypographyHeader>Atividade Principal</TypographyHeader>
                <Typography variant="body2" color="text.disabled">
                  {data.atividade_principal[0].code}
                </Typography>
                <Typography variant="body1">
                  {data.atividade_principal[0].text}
                </Typography>
              </Card>
            </>
          )}
        </Grid>
      )}
      {!error && (
        <Grid xs={12} md={4}>
          {progress && (
            <Stack spacing={2}>
              <Skeleton variant="rounded" height={200} />
              <Skeleton variant="rounded" height={150} />
            </Stack>
          )}
          {!progress && (
            <>
              <Card sx={{ textAlign: "center" }}>
                <PlaceIcon sx={{ fontSize: "5em" }} color="error" />
                <Typography variant="h6" fontWeight={600}>
                  {data.logradouro}, {data.numero}
                </Typography>
                <Typography variant="body2" color="text.disabled">
                  {data.complemento && `${data.complemento}`}
                  {data.complemento && <br />}
                  {data.bairro} - {data.municipio} - {data.uf}
                  <br />
                  CEP: {data.cep}
                </Typography>
              </Card>
              <Card sx={{ mt: 2 }}>
                <TypographyHeader>Quadro Societário</TypographyHeader>
                {data.qsa.map((row: any) => (
                  <div key={row.nome}>
                    <Typography
                      variant="body2"
                      color="text.disabled"
                      sx={{ mt: 2 }}
                    >
                      {row.qual}
                    </Typography>
                    <Typography variant="body1">{row.nome}</Typography>
                  </div>
                ))}
              </Card>
            </>
          )}
        </Grid>
      )}
      <Grid xs={12} md={4}>
        <Card>
          <TypographyHeader>Arquivos disponíveis</TypographyHeader>
          {cloudProgress && <LinearProgress />}

          {!cloudProgress &&
            cloud.files.map((row: any) => (
              <Button
                sx={{ justifyContent: "start" }}
                key={row.id}
                variant="outlined"
                fullWidth
                startIcon={<InsertDriveFileIcon />}
                onClick={() => handleDownload(row.key, row.name)}
              >
                {row.name}
              </Button>
            ))}

          {cloud.files.length === 0 && (
            <Alert
              severity="info"
              sx={{
                flexDirection: "column",
                alignItems: "center",
                textAlign: "cenrer",
                borderRadius: 2,
              }}
            >
              <Stack spacing={2} alignItems="center" direction="column">
                <Typography variant="body2">
                  Nenhum arquivo disponível até o momento.
                </Typography>
              </Stack>
            </Alert>
          )}
        </Card>
      </Grid>
    </Grid>
  );
}

export default MyCompany;
