import { useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import {
  Box,
  Drawer,
  Stack,
  Divider,
  Typography,
  IconButton,
  Button,
  Chip,
  CircularProgress,
  Link,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";

import useHrmEmployeeStore from "../../../../../stores/hrEmployee";

import HRManagementEmployeeDependentDrawer from "./dependent";

const HRManagementEmployeeDependents = () => {
  const [state, setState] = useState(false);
  const data = useHrmEmployeeStore((state: any) => state.data);
  const isSaving = useHrmEmployeeStore((state: any) => state.isSaving);
  const setCurrentDependent = useHrmEmployeeStore(
    (state: any) => state.setCurrentDependent,
  );
  const toggleDependentDrawer = useHrmEmployeeStore(
    (state: any) => state.toggleDependentDrawer,
  );

  const columns: GridColDef[] = [
    {
      field: "nome",
      headerName: "Nome",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Stack
            direction="row"
            spacing={1}
            sx={{ cursor: "pointer" }}
            alignItems="center"
          >
            <Link
              component="button"
              onClick={() => {
                setCurrentDependent(
                  params.api.getAllRowIds().indexOf(params.id),
                );
                toggleDependentDrawer();
              }}
            >
              {params.row.nome}
            </Link>
          </Stack>
        );
      },
    },
  ];

  return (
    <>
      <Button
        fullWidth
        variant="outlined"
        onClick={() => {
          setState(true);
        }}
        disabled={data?.status === "editing" ? false : true}
      >
        <Stack spacing={1} alignItems="center">
          <SupervisedUserCircleIcon fontSize="large" />
          <div>Dependentes</div>
        </Stack>
      </Button>
      <Drawer
        anchor="left"
        open={state}
        onClose={() => {
          setState(false);
        }}
      >
        <Box sx={{ p: 3, pt: 2, width: 400 }} width={300} height="100%">
          <Stack height="100%" spacing={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack
                direction="row"
                spacing={1}
                sx={{ flex: 1 }}
                alignItems="center"
              >
                <Typography variant="body1">Dependentes</Typography>
                {data.status === "editing" && (
                  <Chip
                    color="success"
                    variant="outlined"
                    icon={
                      isSaving === true ? (
                        <CircularProgress color="warning" size={18} />
                      ) : (
                        <CloudDoneIcon fontSize="small" />
                      )
                    }
                    label={isSaving ? "Salvando..." : "Tudo Salvo"}
                  />
                )}
              </Stack>
              <IconButton
                onClick={() => {
                  setState(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Divider sx={{ my: 2 }} />
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => {
                toggleDependentDrawer();
              }}
            >
              Adicionar
            </Button>
            <DataGrid
              rows={data.draft.dependentes || []}
              columns={columns}
              hideFooter={true}
              autoHeight
              disableRowSelectionOnClick
              columnHeaderHeight={0}
              getRowId={(row) => row.nome}
              localeText={{ noRowsLabel: "Nenhum dependente." }}
            />
          </Stack>
        </Box>
      </Drawer>
      <HRManagementEmployeeDependentDrawer />
    </>
  );
};

export default HRManagementEmployeeDependents;
