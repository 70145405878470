export const contactLabels = [
  {
    label: "Residencial",
    value: "residencial",
  },
  {
    label: "Trabalho",
    value: "work",
  },
  {
    label: "Outro",
    value: "other",
  },
];

export const phoneLabels = contactLabels;

phoneLabels.unshift({
  label: "Celular",
  value: "mobile",
});

export const organizationsProfileLabels = [
  "Empresas",
  "Igrejas",
  "Save",
  "Terceiro Setor",
];

export const hrmLabels = (status: string) => {
  switch (status) {
    case "processing":
    case "queued":
    case "error":
      return "Processando";

    case "done":
      return "Entregue";

    default:
      return "Rascunho";
  }
};
