import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@mui/material";

const ForgetPasswordStepFive = () => {
  const navigate = useNavigate();

  return (
    <>
      <Typography
        variant="h6"
        component="div"
        gutterBottom
        sx={{ fontWeight: 300, marginBottom: 1 }}
      >
        Muito bem!
      </Typography>
      <Typography
        variant="body2"
        component="div"
        gutterBottom
        sx={{ fontWeight: 300, marginBottom: 1 }}
      >
        Sua senha atualizada com sucesso.
      </Typography>
      <Button
        fullWidth={true}
        color="primary"
        variant="contained"
        onClick={() => navigate(`/`)}
        size="large"
        style={{ marginTop: "8px" }}
      >
        Entrar
      </Button>
    </>
  );
};

export default ForgetPasswordStepFive;
