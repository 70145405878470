import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Button, Tabs, Tab, Stack } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";

import useNavStore from "../../../stores/nav";
import useAuthStore from "../../../stores/auth";
import useHrmEmployeeStore from "../../../stores/hrEmployee";

import HRManagementEmployees from "./Employees";
import HRManagementTaxpayers from "./Taxpayers";
import HRManagementEvents from "./Events";
import HRManagementDrafts from "./Drafts";
import ExportExcel from "../../../components/ExportExcel";

function HRManagement() {
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);
  const setHeaderTitle = useNavStore((state: any) => state.setHeader);
  const setToolbar = useNavStore((state: any) => state.setToolbar);
  const reset = useHrmEmployeeStore((state: any) => state.reset);

  const Toolbar = () => {
    const [state, setState] = useState(0);
    const company = useAuthStore((state) => state.company);
    const exportData = useNavStore((state) => state.exportData);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setState(newValue);
      setTab(newValue);
    };

    return (
      <>
        <Tabs
          value={state}
          onChange={handleChange}
          aria-label="basic tabs example"
          textColor="inherit"
          indicatorColor="primary"
        >
          <Tab label="Empregados" />
          <Tab label="Contribuintes" />
          <Tab label="Rascunhos" />
          <Tab label="Folha" />
        </Tabs>
        <Stack direction="row" spacing={1}>
          {state < 2 && exportData.length > 0 && (
            <ExportExcel
              data={exportData}
              fileName={`${company.reference}-${
                state === 0 ? "empregados" : "contribuintes"
              }`}
            />
          )}
          {state < 2 && (
            <Button
              variant="contained"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => {
                reset();
                navigate("/hr/employee", {
                  state: {
                    type: state === 0 ? "employee" : "taxpayer",
                  },
                });
              }}
            >
              {state === 0 ? "Novo Empregado" : "Novo Contribuinte"}
            </Button>
          )}
        </Stack>
      </>
    );
  };

  useEffect(() => {
    setHeaderTitle("Pessoas");
    setToolbar(<Toolbar />);
  }, []);

  return (
    <Card>
      {tab === 0 && <HRManagementEmployees />}
      {tab === 1 && <HRManagementTaxpayers />}
      {tab === 2 && <HRManagementDrafts />}
      {tab === 3 && <HRManagementEvents />}
    </Card>
  );
}

export default HRManagement;
