import React, { useEffect } from "react";

import useAuthRecoverStore from "../../stores/authRecover";
import ForgetPasswordStepOne from "./stepOne";
import ForgetPasswordStepTwo from "./stepTwo";
import ForgetPasswordStepThree from "./stepThree";
import ForgetPasswordStepFour from "./stepFour";
import ForgetPasswordStepFive from "./stepFive";

const ForgetPassword = () => {
  const step = useAuthRecoverStore((state) => state.step);
  const reset = useAuthRecoverStore((state) => state.reset);

  useEffect(() => reset(), []);

  return (
    <React.Fragment>
      {step === 0 && <ForgetPasswordStepOne />}
      {step === 1 && <ForgetPasswordStepTwo />}
      {step === 2 && <ForgetPasswordStepThree />}
      {step === 3 && <ForgetPasswordStepFour />}
      {step === 4 && <ForgetPasswordStepFive />}
    </React.Fragment>
  );
};

export default ForgetPassword;
