import { create } from "zustand";
import { fetchData } from "../fetchData";
import useAuthStore from "./auth";

type State = {
  drawer: boolean;
  data: object;
  detailDrawer: boolean;
  detailDrawerData: object;
};

const initialState: State = {
  drawer: false,
  data: {},
  detailDrawer: false,
  detailDrawerData: {},
};

type Actions = {
  setData: (data: object) => void;
  toggleDrawer: () => void;
  toggleDetailDrawer: (data?: object) => void;
};

const useContactStore = create<State & Actions>((set, get) => ({
  ...initialState,

  setData: (data: object) => set(() => ({ data })),

  toggleDrawer: () => set((state: State) => ({ drawer: !state.drawer })),

  toggleDetailDrawer: (data?: object) => {
    set((state: State) => ({
      detailDrawer: !state.detailDrawer,
      detailDrawerData: data ? data : {},
    }));
  },
}));

export default useContactStore;
