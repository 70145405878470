import { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import useNavStore from "../../../../stores/nav";
import {
  Button,
  ButtonGroup,
  Card,
  Chip,
  CircularProgress,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import TextFieldMasked from "../../../../components/TextFieldMasked";
import { PatternFormat } from "react-number-format";

import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

import HRManagementEmployeeAddress from "./drawers/address";
import HRManagementEmployeePersonal from "./drawers/personal";
import HRManagementEmployeeDocuments from "./drawers/documents";
import HRManagementEmployeeForeign from "./drawers/foreign";
import HRManagementEmployeeProfessional from "./drawers/professional";
import HRManagementEmployeeAdmission from "./drawers/admission";
import HRManagementEmployeeDependents from "./drawers/dependents";
import HRManagementEmployeeAttachments from "./drawers/attachments";
import HRManagementEmployeeDeficiency from "./drawers/deficiency";
import HRManagementEmployeeExtract from "./drawers/extract";

import useHrmEmployeeStore from "../../../../stores/hrEmployee";
import useAuthStore from "../../../../stores/auth";
import { hrmLabels } from "../../../../utils/labels";

function HRManagementEmployee() {
  const { id } = useParams();
  const location = useLocation();
  const company = useAuthStore((state: any) => state.company);
  const setState = useHrmEmployeeStore((state: any) => state.setState);
  const saveDraft = useHrmEmployeeStore((state: any) => state.saveDraft);
  const changeDraft = useHrmEmployeeStore((state: any) => state.changeDraft);
  const setReference = useHrmEmployeeStore((state: any) => state.setReference);
  const state = useHrmEmployeeStore((state: any) => state.data);
  const setHeaderTitle = useNavStore((state: any) => state.setHeader);
  const setToolbar = useNavStore((state: any) => state.setToolbar);
  const setModal = useNavStore((state: any) => state.setModal);
  const isEditing = useHrmEmployeeStore((state: any) => state.isEditing);
  const data = useHrmEmployeeStore((state: any) => state.data);

  const handleAditionalInfo = (e: any) => {
    setState({ ...state, draft: { ...state.draft, info: e.target.value } });
  };

  const Toolbar = () => {
    const [state, setState] = useState(0);
    const isSaving = useHrmEmployeeStore((state: any) => state.isSaving);
    const data = useHrmEmployeeStore((state: any) => state.data);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setState(newValue);
    };

    return (
      <>
        <Stack direction="row" spacing={1}>
          <Chip
            color="warning"
            variant="outlined"
            icon={<TaskAltIcon fontSize="small" />}
            label={hrmLabels(data.status)}
          />
        </Stack>
        <Stack direction="row" spacing={1}>
          {data.status === "editing" && (
            <Chip
              color="warning"
              variant="outlined"
              icon={
                isSaving === true ? (
                  <CircularProgress color="warning" size={18} />
                ) : (
                  <CloudDoneIcon fontSize="small" />
                )
              }
              label={isSaving ? "Salvando..." : "Alterações Salvas"}
            />
          )}
          {data.status === "editing" && <HRManagementEmployeeExtract />}
        </Stack>
      </>
    );
  };

  useEffect(() => {
    setHeaderTitle(
      state.draft?.nome
        ? state.draft?.nome
        : `Novo ${
            location.state?.type === undefined
              ? "Cadastro"
              : location.state?.type === "employee"
              ? "Empregado"
              : "Contribuinte"
          }`,
    );
    setToolbar(<Toolbar />);
  }, [state.draft?.nome]);

  useEffect(() => {
    if (!id) {
      setReference(company.reference);
    }
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid xs={4}>
        <Card>
          <Typography variant="h4" fontWeight={700}>
            {company.reference}
          </Typography>
          <Typography variant="body2" color="text.disabled">
            {company.name}
          </Typography>
        </Card>
        <Card sx={{ mt: 3 }}>
          <TextField
            name="nome"
            fullWidth
            variant="filled"
            label="Nome Completo"
            defaultValue={state.draft?.nome || null}
            onBlur={() => saveDraft()}
            onChange={changeDraft}
            sx={{ mb: 1 }}
            required
            disabled={data?.status === "editing" ? false : true}
          />
          <PatternFormat
            format="###.###.###-##"
            name="cpf"
            autoComplete="password"
            fullWidth
            variant="filled"
            label="CPF"
            defaultValue={state.draft?.cpf || null}
            onBlur={() => saveDraft()}
            onChange={changeDraft}
            customInput={TextField}
            sx={{ mb: 1 }}
            required
            disabled={data?.status === "editing" ? false : true}
          />
          <TextField
            name="email"
            fullWidth
            variant="filled"
            label="E-mail"
            defaultValue={state.draft?.email || null}
            onBlur={() => saveDraft()}
            onChange={changeDraft}
            sx={{ mb: 1 }}
            disabled={data?.status === "editing" ? false : true}
          />
          <PatternFormat
            format="(##) #####-####"
            name="telefone"
            fullWidth
            variant="filled"
            label="Telefone"
            defaultValue={state.draft?.telefone || null}
            onBlur={() => saveDraft()}
            onChange={changeDraft}
            customInput={TextField}
            sx={{ mb: 1 }}
            required
            disabled={data?.status === "editing" ? false : true}
          />
        </Card>
      </Grid>
      <Grid xs={8}>
        <Card>
          <Typography variant="h5">Informações</Typography>
          <Typography variant="body2">
            {data?.status === "editing" && (
              <div>
                Clique em cada um dos botões abaixo para preencher as
                informações necessárias para o cadastro.
                <br />
                <br />
                <Typography variant="body1" color="red">
                  IMPORTANTE: Certifique-se de que tem em mãos, o exame
                  admissional, a declaração étnico-racial e documentos para
                  enviar como anexo a esta admissão.
                </Typography>
              </div>
            )}
            {data?.status !== "editing" &&
              "No momento estamos processando o cadastro internamente, caso haja alguma necessidade entre em contato com nossos canais de relacionamento."}
          </Typography>
          <Divider sx={{ my: 3 }} />
          <Grid container spacing={2}>
            <Grid xs={12} lg={4}>
              <HRManagementEmployeePersonal />
            </Grid>
            <Grid xs={12} lg={4}>
              <HRManagementEmployeeAddress />
            </Grid>
            <Grid xs={12} lg={4}>
              <HRManagementEmployeeDocuments />
            </Grid>
            <Grid xs={12} lg={4}>
              <HRManagementEmployeeForeign />
            </Grid>
            <Grid xs={12} lg={4}>
              <HRManagementEmployeeProfessional />
            </Grid>
            <Grid xs={12} lg={4}>
              <HRManagementEmployeeAdmission />
            </Grid>
            <Grid xs={12} lg={4}>
              <HRManagementEmployeeDeficiency />
            </Grid>
            <Grid xs={12} lg={4}>
              <HRManagementEmployeeDependents />
            </Grid>
            <Grid xs={12} lg={4}>
              <HRManagementEmployeeAttachments />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}

export default HRManagementEmployee;
