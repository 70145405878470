import { create } from "zustand";
import { fetchData } from "../fetchData";

type Result = {
  status: boolean;
  emails: Array<string>;
  phones: Array<string>;
};

type State = {
  step: number;
  result: Result;
  login: string;
  code: number;
};

type Actions = {
  setLogin: (login: string) => void;
  checkLogin: () => Promise<object>;
  sendCode: (option: string) => Promise<boolean>;
  checkCode: (code: number) => Promise<boolean>;
  updatePassword: (password: string) => Promise<boolean>;
  prevStep: () => void;
  nextStep: () => void;
  reset: () => void;
};

const resultInitialState: Result = {
  status: false,
  emails: [],
  phones: [],
};

const initialState: State = {
  step: 0,
  result: resultInitialState,
  login: "",
  code: 0,
};

const useAuthRecoverStore = create<State & Actions>((set, get) => ({
  ...initialState,

  setLogin: (login: string) =>
    set(() => ({
      login,
    })),

  prevStep: () =>
    set(() => ({
      step: get().step - 1,
    })),

  nextStep: () =>
    set(() => ({
      step: get().step + 1,
    })),

  reset: () =>
    set(() => ({
      ...initialState,
    })),

  checkLogin: async () => {
    try {
      const result = await fetchData(`/auth/recover`, {
        method: "POST",
        body: JSON.stringify({
          username: get().login,
        }),
      });

      set(() => ({ result: result.user }));

      return result ? result : { status: false };
    } catch (error) {
      return { status: false };
    }
  },

  sendCode: async (option: string) => {
    try {
      const result = await fetchData(`/auth/recover/send`, {
        method: "POST",
        body: JSON.stringify({
          username: get().login,
          option,
        }),
      });

      return result && result.status ? result.status : false;
    } catch (error) {
      return false;
    }
  },

  checkCode: async (code: number) => {
    try {
      const result = await fetchData(`/auth/recover/check`, {
        method: "POST",
        body: JSON.stringify({
          username: get().login,
          code,
        }),
      });

      set(() => ({ code }));

      return result && result.status ? result.status : false;
    } catch (error) {
      return false;
    }
  },

  updatePassword: async (password: string) => {
    try {
      const result = await fetchData(`/auth/recover/update/password`, {
        method: "POST",
        body: JSON.stringify({
          username: get().login,
          code: get().code,
          password,
        }),
      });

      return result && result.status ? result.status : false;
    } catch (error) {
      return false;
    }
  },
}));

export default useAuthRecoverStore;
