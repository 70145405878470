import { create } from "zustand";

type Modal = {
  state: boolean;
  close: boolean;
  children: undefined | React.Component;
  title: string;
  chip: undefined | string;
};

type AlertSeverity = "error" | "info" | "success" | "warning";

type Snackbar = {
  state: boolean;
  close: boolean;
  message: string;
  severity: AlertSeverity;
};

type State = {
  header: string;
  currentItem: string;
  toolbar: undefined | React.Component;
  modal: Modal;
  snackbar: Snackbar;
  exportData: object[];
};

type Actions = {
  setHeader: (text: string) => void;
  setCurrentItem: (text: string) => void;
  setToolbar: (component: React.Component) => void;
  setModal: (
    title: string,
    children: React.Component,
    chip?: undefined | string,
  ) => void;
  closeModal: () => void;
  setSnackbar: (message: string, severity?: AlertSeverity) => void;
  closeSnackbar: () => void;
  reset: () => void;
  setExportData: (data: object[]) => void;
};

const initialModalState: Modal = {
  state: false,
  close: true,
  children: undefined,
  title: "",
  chip: undefined,
};

const initialSnackbarState: Snackbar = {
  state: false,
  close: true,
  message: "",
  severity: "warning",
};

const initialState: State = {
  header: "",
  currentItem: "dashboard",
  toolbar: undefined,
  modal: initialModalState,
  snackbar: initialSnackbarState,
  exportData: [],
};

const useNavStore = create<State & Actions>((set, get) => ({
  ...initialState,

  setHeader: (header: string) => {
    get().reset();
    set(() => ({ header }));
  },

  setCurrentItem: (currentItem: string) => set(() => ({ currentItem })),

  setToolbar: (component: React.Component) =>
    set(() => ({ toolbar: component })),

  setModal: (
    title: string,
    children: React.Component,
    chip: undefined | string = undefined,
  ) =>
    set(() => ({
      modal: { ...initialModalState, state: true, title, children, chip },
    })),

  closeModal: () =>
    set(() => ({
      modal: initialModalState,
    })),

  setSnackbar: (message: string, severity: AlertSeverity = "warning") =>
    set(() => ({
      snackbar: { ...initialSnackbarState, state: true, message, severity },
    })),

  closeSnackbar: () =>
    set(() => ({
      snackbar: initialSnackbarState,
    })),

  reset: () =>
    set((state: State) => ({
      ...initialState,
      currentItem: state.currentItem,
    })),

  setExportData: (data: object[]) =>
    set(() => ({
      exportData: data,
    })),
}));

export default useNavStore;
