import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
  },
  section: {
    margin: 10,
    padding: 70,
    flexGrow: 1,
    fontSize: 14,
    lineHeight: 1.5,
  },
  h1: {
    fontSize: 16,
    textTransform: "uppercase",
    textAlign: "center",
    marginBottom: 20,
    fontWeight: "bold",
  },
  paragraph: {
    marginBottom: 20,
    textAlign: "justify",
  },
});

function formatDocument(cpf: string) {
  // Remove qualquer caractere que não seja um dígito
  cpf = cpf.replace(/\D/g, "");

  // Aplica a formatação com regex
  cpf = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");

  return cpf;
}

const MyDocument = (props: any) => {
  const now = new Date();
  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.h1}>Autodeclaração Étnico-Racial</Text>
          <Text style={styles.paragraph}>
            Eu, {props.name}, inscrito no CPF sob o nº.{" "}
            {props.document
              ? formatDocument(props.document)
              : "_________________"}
            , AUTODECLARO, sob as penas da lei, minha raça/etnia sendo:{" "}
          </Text>
          <Text>[{"   "}] Branca</Text>
          <Text>[{"   "}] Preta</Text>
          <Text>[{"   "}] Parda</Text>
          <Text>[{"   "}] Amarela</Text>
          <Text style={styles.paragraph}>[{"   "}] Indígena</Text>
          <Text style={styles.paragraph}>
            Esta autodeclaração atende a exigência do art. 39, § 8º, da Lei nº
            12.288/2010, alterado pela Lei nº 14.553/2023 e da Portaria MTE nº
            3.784/2023, que obriga a prestação da informação nas inclusões,
            alterações ou retificações cadastrais dos trabalhadores ocorridas a
            partir de 1º de janeiro de 2024, respeitando o critério de
            autodeclaração do trabalhador, em conformidade com a classificação
            utilizada pelo Instituto Brasileiro de Geografia e Estatística -
            IBGE.{" "}
          </Text>
          <Text style={styles.paragraph}>
            Por ser expressão da verdade, firmo e assino a presente para que a
            mesma produza seus efeitos legais e de direito.
          </Text>
          <Text style={{ marginTop: 40 }}>{props.local}</Text>
          <Text style={styles.paragraph}>
            {`${now.getUTCDate()} de ${
              months[now.getUTCMonth()]
            } de ${now.getUTCFullYear()}`}
          </Text>
          <Text style={{ marginTop: 40 }}>
            _______________________________________
          </Text>
          <Text style={styles.paragraph}>{props.name} (assinatura)</Text>
        </View>
      </Page>
    </Document>
  );
};

export default MyDocument;
