import { useEffect, useState } from "react";
import {
  Card,
  Box,
  LinearProgress,
  Alert,
  Stack,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import useNavStore from "../../../stores/nav";

function Help() {
  const setHeaderTitle = useNavStore((state) => state.setHeader);
  const playlistId = "PLB30Ly_OJ5dc08Ggl7Mx3-PqhoI7qkwle";
  const URL = `https://www.googleapis.com/youtube/v3/playlistItems?key=${process.env.REACT_APP_YOUTUBE_KEY}&playlistId=${playlistId}&part=snippet&maxResults=10`;
  const [list, setList] = useState([]);
  const [progress, setProgress] = useState(true);
  const [error, setError] = useState(false);

  const getPlaylistData = async () => {
    await fetch(URL, {
      method: "GET",
    })
      .then((response) => {
        if (response.status !== 200) {
          setError(true);
        }

        return response.json();
      })
      .then((data) => {
        setList(data.items);
      })
      .catch((error) => {
        console.log(error);
        setError(true);
      });

    setProgress(false);
  };

  useEffect(() => {
    setHeaderTitle("Central de Ajuda");
    getPlaylistData();
  }, []);

  if (progress) {
    return <LinearProgress />;
  }

  if (!progress && error) {
    return (
      <Alert
        severity="error"
        sx={{
          flexDirection: "column",
          alignItems: "center",
          textAlign: "cenrer",
        }}
      >
        <Stack spacing={2} alignItems="center" direction="column">
          <Typography textAlign="center" variant="h5">
            Ops!
          </Typography>
          <Typography textAlign="center">
            Não foi possível carregar a central de ajuda. Tente novamente mais
            tarde.
          </Typography>
        </Stack>
      </Alert>
    );
  }

  if (!progress && !error && list.length === 0) {
    return (
      <Alert
        severity="info"
        sx={{
          flexDirection: "column",
          alignItems: "center",
          textAlign: "cenrer",
        }}
      >
        <Stack spacing={2} alignItems="center" direction="column">
          <Typography textAlign="center">
            Nenhum conteúdo para listar.
          </Typography>
        </Stack>
      </Alert>
    );
  }

  return (
    <>
      <Grid container spacing={3}>
        {list.map((video: any) => (
          <Grid key={video.snippet.resourceId.videoId} xs={12} md={4}>
            <Card sx={{ p: 0 }}>
              <Box
                component="iframe"
                src={`https://www.youtube.com/embed/${video.snippet.resourceId.videoId}`}
                width="100%"
                height={315}
                frameBorder={0}
                allowFullScreen
              />
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default Help;
