interface Options extends RequestInit {
  headers?: { [key: string]: any };
}

export const fetchData = async (
  url: string,
  options: Options,
  jsonReturn = true,
) => {
  const token = localStorage.getItem(`${process.env.REACT_APP_KEYNAME}token`);
  const headers = {
    Authorization: "",
    "Content-Type": "application/json",
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  options.headers = { ...options.headers, ...headers };

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}${url}`,
      options,
    );

    if (!response.ok) {
      response.json().then((result) => console.log(result));

      if (response.status === 400) {
        throw new Error("Bad Request");
      } else if (response.status === 401) {
        throw new Error("Unauthorized");
      } else if (response.status === 403) {
        throw new Error("Forbidden");
      } else if (response.status === 404) {
        throw new Error("Not Found");
      } else if (response.status === 500) {
        throw new Error("Internal Server Error");
      } else {
        throw new Error(`HTTP error ${response.status}`);
      }
    }

    try {
      return jsonReturn ? await response.json() : response;
    } catch (error) {
      return jsonReturn ? response.json() : response;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};
