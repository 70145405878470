import { create } from "zustand";

type State = {
  drawer: boolean;
  drawerData: object;
};

type Actions = {
  toggleDrawer: (data?: object) => void;
};

const initialState: State = {
  drawer: false,
  drawerData: {},
};

const useHrmTerminationStore = create<State & Actions>((set, get) => ({
  ...initialState,

  toggleDrawer: (data?: object) => {
    set((state: State) => ({
      drawer: !state.drawer,
      drawerData: data ? data : [],
    }));
  },
}));

export default useHrmTerminationStore;
