import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Chip, Link, Skeleton, Stack } from "@mui/material";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AddIcon from "@mui/icons-material/Add";
import UndoIcon from "@mui/icons-material/Undo";
import EditIcon from "@mui/icons-material/Edit";

import ActionMenu from "../../../../components/ActionMenu";
import DataGrid from "../../../../components/DataGrid";

import pad from "../../../../utils/pad";
import { fetchData } from "../../../../fetchData";
import useAuthStore from "../../../../stores/auth";
import useHrmStore from "../../../../stores/hrManagement";
import useNavStore from "../../../../stores/nav";
import useTicketStore from "../../../../stores/ticket";
import { hrmLabels } from "../../../../utils/labels";
import Ticket from "../../Ticket";

interface ReopenProps {
  type: object;
  subject: string;
  untilDate: Date | undefined;
  description: string;
}

function HRManagementEvents() {
  const navigate = useNavigate();
  const company = useAuthStore((state) => state.company);
  const setState = useHrmStore((state) => state.setState);
  const toggleTicketDrawer = useTicketStore((state) => state.toggleDrawer);
  const setTicket = useTicketStore((state) => state.setState);
  const [progress, setProgress] = useState(true);
  const [error, setError] = useState(false);
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const columns: GridColDef[] = [
    {
      field: "competence",
      headerName: "Competência",
      flex: 1,
      renderCell: (params: any) => {
        const competence = new Date(params.row.month);
        const label = hrmLabels(params.row.status);

        return (
          <Stack direction="row" spacing={2}>
            <Link
              component="button"
              onClick={() => {
                setState(params.row);
                navigate(`/hr/competence/${params.row.id}`);
              }}
            >
              {`${pad(
                competence.getMonth() + 1,
                2,
              )}/${competence.getFullYear()}`}
            </Link>
            <Chip
              label={label}
              variant={params.row.status === "editing" ? "outlined" : "filled"}
              color={params.row.status === "editing" ? "secondary" : "primary"}
            />
          </Stack>
        );
      },
    },
    {
      field: "type",
      sortable: false,
      flex: 1,
      headerName: "Tipo",
      renderCell: (params: any) => "Mensal",
    },
    {
      field: "id",
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: any) => {
        const competence = new Date(params.row.month);
        const newDate = new Date();
        newDate.setDate(newDate.getDate() + 1);

        const items =
          params.row.status === "editing"
            ? [
                {
                  title: "Editar",
                  icon: <EditIcon />,
                  onClick: () => {
                    setState(params.row);
                    navigate(`/hr/competence/${params.row.id}`);
                  },
                },
              ]
            : [
                {
                  title: "Solicitar Reabertura",
                  icon: <UndoIcon />,
                  onClick: () => {
                    setTicket({
                      type: "Folha de Pagamento",
                      subject: `Reabertura da Folha ${pad(
                        competence.getMonth() + 1,
                        2,
                      )}/${competence.getFullYear()}`,
                      until_date: newDate,
                      description:
                        "Por favor reabrir a folha para inserção de novas informações",
                    });
                    toggleTicketDrawer();
                  },
                },
                {
                  title: "Ver detalhes",
                  icon: <AddIcon />,
                  onClick: () => {
                    navigate(`/hr/competence/${params.row.id}`);
                  },
                },
              ];
        return <ActionMenu items={items} />;
      },
      renderHeader: (params: any) => {
        return <MoreHorizIcon />;
      },
    },
  ];

  const data = async (limit: number, skip: number) => {
    setProgress(true);

    try {
      await fetchData(`/hrm/fopag/${company.reference}`, {
        method: "PATCH",
      });

      const result = await fetchData(
        `/hrm/fopag/${company.reference}?limit=${limit}&skip=${skip}`,
        {
          method: "GET",
        },
      );

      if (result) {
        setRowCount(result.count);
        setRows(
          result.rows.map((row: any) => ({
            id: row._id,
            month: row.month,
            status: row.status,
            reference: row.reference,
            draft: row.draft || {},
          })),
        );
        setProgress(false);
      } else {
        setError(true);
        console.log(error);
      }
    } catch (error) {
      setError(true);
    }
  };

  return (
    <>
      <DataGrid
        rows={rows}
        data={data}
        loading={progress}
        columns={columns}
        rowCount={rowCount}
      />
      <Ticket hideTrigger />
    </>
  );
}

export default HRManagementEvents;
