import { useState, useEffect } from "react";
import useNavStore from "../../../../stores/nav";
import {
  Button,
  ButtonGroup,
  Card,
  Chip,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

import HRManagementEventsDetailAddEmployee from "./modals/AddEmployee";
import HRManagementEventsDetailList from "./list";
import HRManagementEventsDetailEdit from "./edit";
import HRManagementEventsExtract from "./drawers/extract";

import useHrmStore from "../../../../stores/hrManagement";
import useAuthStore from "../../../../stores/auth";
import pad from "../../../../utils/pad";
import { hrmLabels } from "../../../../utils/labels";
import ExportExcel from "../../../../components/ExportExcel";
import TagManager from "react-gtm-module";

function HRManagementEventsDetail() {
  const company = useAuthStore((state: any) => state.company);
  const user = useAuthStore((state: any) => state.user);
  const month = useHrmStore((state: any) => new Date(state.data.month));
  const setState = useHrmStore((state: any) => state.setState);
  const saveDraft = useHrmStore((state: any) => state.saveDraft);
  const state = useHrmStore((state: any) => state.data);
  const setHeaderTitle = useNavStore((state: any) => state.setHeader);
  const setToolbar = useNavStore((state: any) => state.setToolbar);
  const setModal = useNavStore((state: any) => state.setModal);
  const isEditing = useHrmStore((state: any) => state.isEditing);

  const handleAditionalInfo = (e: any) => {
    setState({ ...state, draft: { ...state.draft, info: e.target.value } });
  };

  const Toolbar = () => {
    const [state, setState] = useState(0);
    const isSaving = useHrmStore((state: any) => state.isSaving);
    const data = useHrmStore((state: any) => state.data);
    const exportData = useHrmStore((state: any) => state.export);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setState(newValue);
    };

    return (
      <>
        <Stack direction="row" spacing={1}>
          <ExportExcel
            data={exportData()}
            fileName={`folha_mensal_${pad(
              month.getMonth() + 1,
              2,
            )}-${month.getFullYear()}`}
          />
          {data.status === "editing" && (
            <ButtonGroup>
              <Button
                variant="contained"
                size="small"
                startIcon={<AddIcon />}
                onClick={() =>
                  setModal(
                    "Selecione o empregado",
                    <HRManagementEventsDetailAddEmployee filter="empregado" />,
                  )
                }
              >
                Empregado
              </Button>
              <Button
                variant="contained"
                size="small"
                startIcon={<AddIcon />}
                onClick={() =>
                  setModal(
                    "Selecione o contribuinte",
                    <HRManagementEventsDetailAddEmployee filter="contribuinte" />,
                  )
                }
              >
                Contribuinte
              </Button>
            </ButtonGroup>
          )}
          {data.status !== "editing" && (
            <Chip
              color="warning"
              variant="outlined"
              icon={<TaskAltIcon fontSize="small" />}
              label={hrmLabels(data.status)}
            />
          )}
        </Stack>
        <Stack direction="row" spacing={1}>
          {data.status === "editing" && (
            <Chip
              color="warning"
              variant="outlined"
              icon={
                isSaving === true ? (
                  <CircularProgress color="warning" size={18} />
                ) : (
                  <CloudDoneIcon fontSize="small" />
                )
              }
              label={isSaving ? "Salvando..." : "Alterações Salvas"}
            />
          )}
          {data.status === "editing" && <HRManagementEventsExtract />}
        </Stack>
      </>
    );
  };

  useEffect(() => {
    setHeaderTitle("Gerenciamento de Folha");
    setToolbar(<Toolbar />);

    if (state.status === "editing") {
      TagManager.dataLayer({
        dataLayer: {
          event: `editing_folha`,
          organization: company,
          user,
        },
      });
    }
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid xs={4}>
        <Card>
          <Typography variant="h4" fontWeight={700}>
            {`${pad(month.getMonth() + 1, 2)}/${month.getFullYear()}`}
          </Typography>
          <Typography variant="body2" color="text.disabled">
            {company.name}
          </Typography>
        </Card>
        <Card sx={{ mt: 3 }}>
          <TextField
            multiline
            disabled={state.status !== "editing"}
            rows={10}
            fullWidth
            defaultValue={state.draft?.info || null}
            label="Informação adicional"
            placeholder="Escreva aqui quaisquer informações complementares."
            onChange={handleAditionalInfo}
            onBlur={() => saveDraft()}
          ></TextField>
        </Card>
      </Grid>
      <Grid xs={8}>
        {!isEditing && <HRManagementEventsDetailList />}
        {isEditing && <HRManagementEventsDetailEdit />}
      </Grid>
    </Grid>
  );
}

export default HRManagementEventsDetail;
