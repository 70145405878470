import { useState } from "react";

import {
  Box,
  Drawer,
  Stack,
  Divider,
  Typography,
  IconButton,
  Button,
  Tooltip,
  ButtonGroup,
  CircularProgress,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import LinkIcon from "@mui/icons-material/Link";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import useCloudStore from "../../../../stores/cloud";
import useNavStore from "../../../../stores/nav";
import { fetchData } from "../../../../fetchData";

const CloudFileFolderDetailDrawer = (props: any) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingProgress, setDeletingProgress] = useState(false);
  const snackbar = useNavStore((state) => state.setSnackbar);
  const data: any = useCloudStore((state) => state.drawerData);
  const drawer = useCloudStore((state) => state.toggleDrawer);
  const state = useCloudStore((state) => state.drawer);
  const reloadCloudData = useCloudStore((state) => state.getData);

  const handleError = (msg: string) => {
    snackbar(msg, "error");
  };

  const handleDownload = async (key: string, name: string) => {
    try {
      fetchData(
        `/cloud/download?key=${key}`,
        {
          method: "GET",
        },
        false,
      )
        .then((response) => response.blob())
        .then((blob) => URL.createObjectURL(blob))
        .then((url) => {
          const link = document.createElement("a");
          link.href = url;
          link.download = name;

          document.body.appendChild(link);

          link.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            }),
          );

          document.body.removeChild(link);
        })
        .catch((error) => {
          handleError("Ops! No momento não foi possível baixar o arquivo.");
        });
    } catch (error) {
      handleError("Ops! No momento não foi possível baixar o arquivo.");
    }
  };

  const handleLinkCopy = async (key: string) => {
    try {
      fetchData(`/cloud/share?key=${key}`, {
        method: "GET",
      })
        .then((response) => {
          navigator.clipboard.writeText(response.url);
          snackbar("Link copiado com sucesso!", "success");
        })
        .catch((error) => {
          handleError("Ops! Não foi possível gerar o link.");
        });
    } catch (error) {
      handleError("Ops! Não foi possível gerar o link.");
    }
  };

  const handleDelete = async (key: string) => {
    setDeletingProgress(true);
    try {
      await fetchData(
        `/cloud?key=${key}`,
        {
          method: "DELETE",
        },
        false,
      )
        .then((response) => {
          reloadCloudData();
          drawer();
          setIsDeleting(false);
          snackbar("Arquivo excluído com sucesso!", "success");
        })
        .catch((error) => {
          handleError("Ops! Não foi possível excluir o arquivo.");
        });
    } catch (error) {
      handleError("Ops! Não foi possível excluir o arquivo.");
    }

    setDeletingProgress(false);
  };

  return (
    <>
      <Drawer anchor="top" open={state} onClose={() => drawer()}>
        <Box sx={{ p: 3 }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <IconButton onClick={() => drawer()}>
              <CloseIcon />
            </IconButton>
            <Divider orientation="vertical" flexItem />
            <Box sx={{ pl: 2 }} flex={1}>
              {props.breadcrumbs}
              <Typography variant="body1" fontWeight={600} sx={{ mt: "-10px" }}>
                {data.name}
              </Typography>
            </Box>
            <Stack direction="row">
              {!isDeleting && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    startIcon={<FileDownloadIcon />}
                    onClick={() => handleDownload(data.key, data.name)}
                  >
                    Baixar
                  </Button>
                  <Tooltip title="Copiar Link" sx={{ ml: 1 }}>
                    <IconButton
                      color="secondary"
                      onClick={() => handleLinkCopy(data.key)}
                    >
                      <LinkIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Apagar arquivo">
                    <IconButton
                      color="error"
                      onClick={() => setIsDeleting(true)}
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </Tooltip>
                </>
              )}
              {isDeleting && (
                <Stack alignItems="center">
                  <Typography variant="body2" color="gray">
                    Confirma exclusão?
                  </Typography>
                  <ButtonGroup variant="outlined" size="small">
                    <Button
                      color="secondary"
                      onClick={() => setIsDeleting(false)}
                    >
                      NÃO
                    </Button>
                    <Button
                      color="error"
                      onClick={() => handleDelete(data.key)}
                    >
                      {!deletingProgress && "Sim"}
                      {deletingProgress && (
                        <CircularProgress color="inherit" size={18} />
                      )}
                    </Button>
                  </ButtonGroup>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default CloudFileFolderDetailDrawer;
