import { useState, useRef, useEffect } from "react";

import {
  Box,
  Drawer,
  Stack,
  Divider,
  Typography,
  IconButton,
  Button,
  Chip,
  CircularProgress,
  TextField,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import CloudDoneIcon from "@mui/icons-material/CloudDone";

import useHrmEmployeeStore from "../../../../../stores/hrEmployee";

const HRManagementEmployeeDeficiency = () => {
  const [state, setState] = useState(false);
  const openRef = useRef(null);
  const saveDraft = useHrmEmployeeStore((state: any) => state.saveDraft);
  const changeDraft = useHrmEmployeeStore((state: any) => state.changeDraft);
  const isSaving = useHrmEmployeeStore((state: any) => state.isSaving);
  const data = useHrmEmployeeStore((state: any) => state.data);
  const addDrawersRef = useHrmEmployeeStore(
    (state: any) => state.addDrawersRef,
  );

  useEffect(() => addDrawersRef({ name: "deficiency", ref: openRef }), []);

  return (
    <>
      <Button
        fullWidth
        ref={openRef}
        variant="outlined"
        onClick={() => {
          setState(true);
        }}
        disabled={data?.status === "editing" ? false : true}
      >
        <Stack spacing={1} alignItems="center">
          <AccessibilityNewIcon fontSize="large" />
          <div>PCD</div>
        </Stack>
      </Button>
      <Drawer
        anchor="left"
        open={state}
        onClose={() => {
          setState(false);
        }}
      >
        <Box sx={{ p: 3, pt: 2, width: 400 }} width={300} height="100%">
          <Stack height="100%" spacing={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack
                direction="row"
                spacing={1}
                sx={{ flex: 1 }}
                alignItems="center"
              >
                <Typography variant="body1">PCD</Typography>
                {data.status === "editing" && (
                  <Chip
                    color="success"
                    variant="outlined"
                    icon={
                      isSaving === true ? (
                        <CircularProgress color="warning" size={18} />
                      ) : (
                        <CloudDoneIcon fontSize="small" />
                      )
                    }
                    label={isSaving ? "Salvando..." : "Tudo Salvo"}
                  />
                )}
              </Stack>
              <IconButton
                onClick={() => {
                  setState(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Divider sx={{ my: 2 }} />
            <TextField
              name="deficiencia"
              fullWidth
              variant="filled"
              select
              size="small"
              label="Possui Deficiência?"
              defaultValue={data.draft?.deficiencia || null}
              SelectProps={{ native: true }}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
            >
              <option value=""></option>
              <option value="Não">Não</option>
              <option value="Sim">Sim</option>
            </TextField>
            {data.draft?.deficiencia === "Sim" && (
              <>
                <TextField
                  name="deficiencia_fisica"
                  fullWidth
                  variant="filled"
                  select
                  size="small"
                  label="Possui Deficiência Física?"
                  defaultValue={data.draft?.deficiencia_fisica || null}
                  SelectProps={{ native: true }}
                  onBlur={() => saveDraft()}
                  onChange={changeDraft}
                  sx={{ mb: 1 }}
                >
                  <option value=""></option>
                  <option value="Não">Não</option>
                  <option value="Sim">Sim</option>
                </TextField>
                <TextField
                  name="deficiencia_visual"
                  fullWidth
                  variant="filled"
                  select
                  size="small"
                  label="Possui Deficiência Visual?"
                  defaultValue={data.draft?.deficiencia_visual || null}
                  SelectProps={{ native: true }}
                  onBlur={() => saveDraft()}
                  onChange={changeDraft}
                  sx={{ mb: 1 }}
                >
                  <option value=""></option>
                  <option value="Não">Não</option>
                  <option value="Sim">Sim</option>
                </TextField>
                <TextField
                  name="deficiencia_auditiva"
                  fullWidth
                  variant="filled"
                  select
                  size="small"
                  label="Possui Deficiência Auditiva?"
                  defaultValue={data.draft?.deficiencia_auditiva || null}
                  SelectProps={{ native: true }}
                  onBlur={() => saveDraft()}
                  onChange={changeDraft}
                  sx={{ mb: 1 }}
                >
                  <option value=""></option>
                  <option value="Não">Não</option>
                  <option value="Sim">Sim</option>
                </TextField>
                <TextField
                  name="deficiencia_intelectual"
                  fullWidth
                  variant="filled"
                  select
                  size="small"
                  label="Possui Deficiência Intelectual?"
                  defaultValue={data.draft?.deficiencia_intelectual || null}
                  SelectProps={{ native: true }}
                  onBlur={() => saveDraft()}
                  onChange={changeDraft}
                  sx={{ mb: 1 }}
                >
                  <option value=""></option>
                  <option value="Não">Não</option>
                  <option value="Sim">Sim</option>
                </TextField>
                <TextField
                  name="deficiencia_mental"
                  fullWidth
                  variant="filled"
                  select
                  size="small"
                  label="Possui Deficiência Mental?"
                  defaultValue={data.draft?.deficiencia_mental || null}
                  SelectProps={{ native: true }}
                  onBlur={() => saveDraft()}
                  onChange={changeDraft}
                  sx={{ mb: 1 }}
                >
                  <option value=""></option>
                  <option value="Não">Não</option>
                  <option value="Sim">Sim</option>
                </TextField>
                <TextField
                  name="pcd_cotista"
                  fullWidth
                  variant="filled"
                  select
                  size="small"
                  label="PCD - Cotista?"
                  defaultValue={data.draft?.pcd_cotista || null}
                  SelectProps={{ native: true }}
                  onBlur={() => saveDraft()}
                  onChange={changeDraft}
                  sx={{ mb: 1 }}
                >
                  <option value=""></option>
                  <option value="Não">Não</option>
                  <option value="Sim">Sim</option>
                </TextField>
                <TextField
                  name="deficiente_reabilitado"
                  fullWidth
                  variant="filled"
                  select
                  size="small"
                  label="Reabilitado?"
                  defaultValue={data.draft?.deficiente_reabilitado || null}
                  SelectProps={{ native: true }}
                  onBlur={() => saveDraft()}
                  onChange={changeDraft}
                  sx={{ mb: 1 }}
                >
                  <option value=""></option>
                  <option value="Não">Não</option>
                  <option value="Sim">Sim</option>
                </TextField>
              </>
            )}
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default HRManagementEmployeeDeficiency;
