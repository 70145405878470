import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt-br";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TextFieldVariants } from "@mui/material";

interface Props {
  register: any;
  get: any;
  set: any;
  label: string;
  required?: boolean;
  requiredtxt?: string;
  name: string;
  variant: TextFieldVariants;
}

const DatePickerField = (props: Props) => {
  let defaultValue = props.get(props.name);
  defaultValue = defaultValue ? dayjs(defaultValue) : null;
  const options: { required?: string } = {};

  if (props.required) {
    options.required = props.requiredtxt;
  }

  const { ref } = props.register(props.name, options);

  const handleChange = (event: any) => {
    if (!event) return;

    props.set(props.name, event.toISOString());
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
      <MobileDatePicker
        onChange={handleChange}
        slotProps={{
          textField: {
            inputRef: ref,
            size: "small",
            name: props.name,
            fullWidth: true,
            margin: "dense",
            defaultValue,
            required: props.required,
            label: props.label,
            variant: props.variant,
          },
        }}
      />
    </LocalizationProvider>
  );
};

DatePickerField.defaultProps = {
  required: false,
  variant: "outlined",
};

export default DatePickerField;
