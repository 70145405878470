import { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { Card, Button, Stack, Link, Chip } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";

import useNavStore from "../../../stores/nav";
import useAuthStore from "../../../stores/auth";
import useContactStore from "../../../stores/contact";
import { fetchData } from "../../../fetchData";

import DataGrid from "../../../components/DataGrid";
import NewContactDrawer from "./drawers/new";
import ContactDetailDrawer from "./drawers/detail";

function Contacts() {
  const company = useAuthStore((state) => state.company);
  const detailDrawer = useContactStore((state) => state.detailDrawer);
  const toggleDrawer = useContactStore((state: any) => state.toggleDrawer);
  const toggleDetailDrawer = useContactStore(
    (state: any) => state.toggleDetailDrawer,
  );
  const setHeaderTitle = useNavStore((state: any) => state.setHeader);
  const setToolbar = useNavStore((state: any) => state.setToolbar);
  const [progress, setProgress] = useState(false);
  const [error, setError] = useState(false);
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const data = async (limit: number, skip: number) => {
    setProgress(true);

    try {
      const result = await fetchData(
        `/users?is_admin=false&client=${company.reference}&sort=name&limit=${limit}&skip=${skip}`,
        {
          method: "GET",
        },
      );

      if (result) {
        setRowCount(result.count);
        setRows(
          result.rows.map((user: any) => ({
            id: user._id,
            name: user.name,
            status: user.status,
          })),
        );
        setProgress(false);
      } else {
        setError(true);
        console.log(error);
      }
    } catch (error) {
      setError(true);
    }
  };

  const Toolbar = () => {
    const [state, setState] = useState(0);
    const company = useAuthStore((state) => state.company);

    return (
      <>
        <Stack direction="row" spacing={1}>
          {state < 2 && (
            <Button
              variant="contained"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => toggleDrawer()}
            >
              Novo Contato
            </Button>
          )}
        </Stack>
      </>
    );
  };

  useEffect(() => {
    setHeaderTitle("Contatos de Relacionamento");
    setToolbar(<Toolbar />);
  }, []);

  useEffect(() => {
    if (!detailDrawer) {
      data(12, 0);
      setRowCount(0);
      setRows([]);
    }
  }, [detailDrawer]);

  const columns: GridColDef[] = [
    {
      field: "name",
      sortable: false,
      headerName: "Nome",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Link
            component="button"
            onClick={() => toggleDetailDrawer(params.row)}
          >
            {params.row.name}
          </Link>
        );
      },
    },
    {
      field: "status",
      sortable: false,
      headerName: "Situação",
      renderCell: (params: any) => {
        return (
          <Chip
            size="small"
            label={params.row.status ? "Ativo" : "Inativo"}
            color={params.row.status ? "primary" : "error"}
            variant="outlined"
            sx={{ textTransform: "uppercase" }}
          />
        );
      },
    },
  ];

  return (
    <Card>
      <DataGrid
        rows={rows}
        data={data}
        loading={progress}
        columns={columns}
        rowCount={rowCount}
      />
      <NewContactDrawer getData={data} />
      <ContactDetailDrawer />
    </Card>
  );
}

export default Contacts;
