import { useEffect, useState } from "react";
import {
  Card,
  Button,
  Tabs,
  Tab,
  Stack,
  Unstable_Grid2 as Grid,
  Typography,
  Skeleton,
  Alert,
  LinearProgress,
  Box,
  Link,
  Breadcrumbs,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FolderIcon from "@mui/icons-material/Folder";
import FolderDeleteIcon from "@mui/icons-material/FolderDelete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import useNavStore from "../../../stores/nav";
import useAuthStore from "../../../stores/auth";
import useCloudStore from "../../../stores/cloud";

import { fetchData } from "../../../fetchData";
import { fetchRRData } from "../../../fetchRRData";

import CloudUpload from "./drawers/upload";
import CloudFileFolderDetailDrawer from "./drawers/detail";
import CloudNewFolder from "./modals/newFolder";

function Cloud() {
  const setHeaderTitle = useNavStore((state: any) => state.setHeader);
  const setToolbar = useNavStore((state: any) => state.setToolbar);
  const progress = useCloudStore((state) => state.progress);
  const path = useCloudStore((state) => state.path);
  const setPath = useCloudStore((state) => state.setPath);
  const data = useCloudStore((state) => state.data);
  const getData = useCloudStore((state) => state.getData);
  const detailDrawer = useCloudStore((state) => state.toggleDrawer);
  const breadcrumb = useCloudStore((state) => state.breadcrumb);

  const BreadCrumbsCustom = ({ isDrawer }: { isDrawer?: boolean }) => {
    const path = useCloudStore((state) => state.path);

    return (
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={
          <ArrowRightIcon
            sx={{ color: isDrawer ? "secondary.text" : "#FFF", mt: "1px" }}
          />
        }
        sx={{ opacity: ".8", mb: 2 }}
      >
        <Link
          component="button"
          underline="hover"
          color="#FFF"
          onClick={() => {
            setPath("uploads");
            if (isDrawer) detailDrawer();
          }}
        >
          <Typography
            variant={isDrawer ? "caption" : "body2"}
            color={isDrawer ? "black" : "white"}
          >
            Início
          </Typography>
        </Link>
        {path.split("/").map((row: any, index: number) => (
          <Box key={row}>
            {index + 1 === path.split("/").length && (
              <Typography
                variant={isDrawer ? "caption" : "body2"}
                color="primary"
                sx={{ cursor: "default" }}
              >
                {row}
              </Typography>
            )}
            {index + 1 !== path.split("/").length && (
              <Link
                component="button"
                underline="hover"
                onClick={() => {
                  breadcrumb(index);
                  if (isDrawer) detailDrawer();
                }}
              >
                <Typography
                  variant={isDrawer ? "caption" : "body2"}
                  color={isDrawer ? "black" : "white"}
                >
                  {row}
                </Typography>
              </Link>
            )}
          </Box>
        ))}
      </Breadcrumbs>
    );
  };

  const Toolbar = () => {
    const path = useCloudStore((state) => state.path);
    const back = useCloudStore((state) => state.setBack);
    const setModal = useNavStore((state: any) => state.setModal);
    const data = useCloudStore((state) => state.data);
    const deleteFolder = useCloudStore((state) => state.deleteFolder);

    return (
      <>
        <Stack direction="column" spacing={1}>
          {path !== "uploads" && (
            <div>
              <BreadCrumbsCustom />
              <Stack direction="row" spacing={1}>
                <Button
                  variant="outlined"
                  size="small"
                  color="warning"
                  startIcon={<ArrowBackIcon />}
                  onClick={() => back()}
                >
                  Voltar
                </Button>
                {data.length === 0 && path !== "uploads" && (
                  <Button
                    variant="outlined"
                    size="small"
                    color="error"
                    startIcon={<FolderDeleteIcon />}
                    onClick={() => deleteFolder()}
                  >
                    Apagar pasta
                  </Button>
                )}
              </Stack>
            </div>
          )}
        </Stack>
        <Stack direction="row" spacing={1}>
          <Button
            variant="outlined"
            size="small"
            color="warning"
            startIcon={<CreateNewFolderIcon />}
            onClick={() => setModal("Criar pasta", <CloudNewFolder />)}
          >
            Criar Pasta
          </Button>
          <div>
            <CloudUpload />
          </div>
        </Stack>
      </>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Nome",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Stack
            direction="row"
            spacing={1}
            color="text.main"
            sx={{ cursor: "pointer" }}
            alignItems="center"
            onClick={() =>
              params.row.type === "folder"
                ? setPath(params.row.path)
                : detailDrawer(params.row)
            }
          >
            {params.row.type === "file" && (
              <InsertDriveFileIcon color="secondary" />
            )}
            {params.row.type === "folder" && <FolderIcon color="primary" />}
            <Typography variant="body2">{params.row.name}</Typography>
          </Stack>
        );
      },
    },
    {
      field: "lastModifiedFormated",
      headerName: "Modificado",
      minWidth: 170,
      renderCell: (params: any) =>
        params.row.type === "folder" ? "--" : params.row.lastModifiedFormated,
    },
    {
      field: "type",
      headerName: "Tipo",
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) =>
        params.row.type === "folder" ? "Pasta" : "Arquivo",
    },
  ];

  useEffect(() => {
    setHeaderTitle("Arquivos");
    setToolbar(<Toolbar />);
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [path]);

  const Progress = () => {
    return (
      <>
        {Array.from({ length: 2 }, (_, index) => (
          <Stack
            key={index}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            height={52}
            spacing={2}
            sx={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Skeleton
                variant="circular"
                animation="wave"
                width={30}
                height={30}
                sx={{ ml: 1 }}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                width={150}
                height={20}
              />
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              sx={{ pr: 3 }}
            >
              <Skeleton
                variant="rectangular"
                animation="wave"
                width={120}
                height={20}
                sx={{ mr: 2 }}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                width={70}
                height={20}
              />
            </Stack>
          </Stack>
        ))}
      </>
    );
  };

  return (
    <Card>
      <DataGrid
        rows={data}
        columns={columns}
        hideFooter={true}
        autoHeight
        loading={progress}
        disableRowSelectionOnClick
        slots={{
          noRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              Pasta vazia.
            </Stack>
          ),
          loadingOverlay: Progress,
        }}
      />
      <CloudFileFolderDetailDrawer
        breadcrumbs={<BreadCrumbsCustom isDrawer />}
      />
    </Card>
  );
}

export default Cloud;
