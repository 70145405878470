import { useEffect, useState } from "react";
import {
  Button,
  Stack,
  Typography,
  Alert,
  Drawer,
  Box,
  IconButton,
  Divider,
  CircularProgress,
  Stepper,
  Step,
  StepLabel,
  StepContent,
} from "@mui/material";

import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import CloseIcon from "@mui/icons-material/Close";

import useTicketStore from "../../../stores/ticket";
import TicketForm from "./steps/form";
import TicketAttachments from "./steps/attachments";

interface Props {
  hideTrigger: boolean;
}

const steps = [
  {
    label: "Informações",
    description:
      "Preencha os campos abaixo para continuar com a abertura do ticket.",
    outlet: <TicketForm />,
  },
  {
    label: "Anexar arquivos?",
    description: "Deseja anexar arquivos ao ticket?",
    outlet: <></>,
  },
  {
    label: "Anexos",
    description:
      "Selecione os arquivos desejados para anexar ao ticket. Obs: Selecione um arquivo por vez para envio.",
    outlet: <TicketAttachments />,
  },
  {
    label: "Conclusão",
    description: "",
    outlet: <></>,
  },
];

function Ticket(props: Props) {
  const { hideTrigger } = props;
  const createTicket = useTicketStore((state) => state.create);
  const state = useTicketStore((state) => state.drawer);
  const setState = useTicketStore((state) => state.toggleDrawer);
  const activeStep = useTicketStore((state) => state.step);
  const nextStep = useTicketStore((state) => state.nextStep);
  const prevStep = useTicketStore((state) => state.prevStep);
  const setStep = useTicketStore((state) => state.setStep);
  const ticketID = useTicketStore((state) => state.id);
  const reset = useTicketStore((state) => state.reset);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [progress, setProgress] = useState(false);

  const ErrorAlert = () => {
    return (
      <Alert
        severity="error"
        sx={{
          flexDirection: "column",
          alignItems: "center",
          textAlign: "cenrer",
        }}
      >
        <Stack spacing={1} alignItems="center" direction="column">
          <Typography textAlign="center" variant="h6">
            Ops!
          </Typography>
          <Typography textAlign="center">
            Não foi possível registrar a sua solicitação no momento. Tente
            novamente mais tarde.
          </Typography>
          <Button
            size="medium"
            variant="outlined"
            color="error"
            onClick={prevStep}
            sx={{ mt: 1, mr: 1 }}
          >
            Voltar
          </Button>
        </Stack>
      </Alert>
    );
  };

  const SuccessAlert = () => {
    return (
      <Alert
        severity="success"
        sx={{
          flexDirection: "column",
          alignItems: "center",
          textAlign: "cenrer",
        }}
      >
        <Stack spacing={1} alignItems="center" direction="column">
          <Typography textAlign="center" variant="h6">
            Ticket #{ticketID} criado!
          </Typography>
          <Typography textAlign="center">
            Sua solicitação foi registrada com sucesso.
          </Typography>
          <Button
            size="medium"
            variant="outlined"
            color="success"
            onClick={() => {
              setSuccess(false);
              setError(false);
              reset();
            }}
            sx={{ mt: 1, mr: 1 }}
          >
            Fechar
          </Button>
        </Stack>
      </Alert>
    );
  };

  const Progress = () => {
    return (
      <Alert
        severity="info"
        sx={{
          flexDirection: "column",
          alignItems: "center",
          textAlign: "cenrer",
        }}
        icon={false}
      >
        <Stack spacing={1} alignItems="center" direction="column">
          <CircularProgress />
          <Typography textAlign="center" variant="h6">
            Gerando ticket
          </Typography>
          <Typography textAlign="center">Aguarde...</Typography>
        </Stack>
      </Alert>
    );
  };

  const onSubmit = async () => {
    setStep(3);
    setProgress(true);

    if (await createTicket()) {
      setSuccess(true);
    } else {
      setError(true);
    }

    setProgress(false);
  };

  return (
    <>
      {!hideTrigger && (
        <Button
          fullWidth
          variant="contained"
          size="medium"
          color="warning"
          startIcon={<SupportAgentIcon />}
          onClick={() => {
            setSuccess(false);
            setError(false);
            reset();
            setState();
          }}
        >
          Abrir solicitação
        </Button>
      )}
      <Drawer
        anchor="left"
        open={state}
        onClose={() => {
          setState();
        }}
      >
        <Box sx={{ p: 3, pt: 2, width: 400 }} width={300} height="100%">
          <Stack height="100%" spacing={2} sx={{ pb: 10 }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack
                direction="row"
                spacing={1}
                sx={{ flex: 1 }}
                alignItems="center"
              >
                <Typography
                  variant="body1"
                  sx={{ width: 170, wordWrap: "break-word" }}
                >
                  Nova Solicitação
                </Typography>
              </Stack>
              <IconButton
                onClick={() => {
                  setState();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Divider sx={{ my: 2 }} />
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel>{step.label}</StepLabel>
                  <StepContent>
                    {step.description && (
                      <Typography variant="body2" sx={{ mb: 2 }}>
                        {step.description}
                      </Typography>
                    )}
                    {step.outlet}
                    {success && <SuccessAlert />}
                    {error && <ErrorAlert />}
                    {progress && <Progress />}
                    {index < 3 && (
                      <Box sx={{ mb: 2 }}>
                        <div>
                          {index === 1 && (
                            <Button
                              size="small"
                              variant="contained"
                              onClick={
                                index === steps.length - 2 ? onSubmit : nextStep
                              }
                              sx={{ mt: 1, mr: 1 }}
                              type="button"
                            >
                              SIM
                            </Button>
                          )}
                          <Button
                            size="small"
                            variant="contained"
                            onClick={
                              index === steps.length - 2
                                ? onSubmit
                                : index === 1
                                ? onSubmit
                                : nextStep
                            }
                            sx={{ mt: 1, mr: 1 }}
                            type="button"
                          >
                            {index === steps.length - 2
                              ? "Finalizar"
                              : index === 1
                              ? "Não. Finalizar"
                              : "Continuar"}
                          </Button>
                          <Button
                            size="small"
                            disabled={index === 0}
                            onClick={prevStep}
                            sx={{ mt: 1, mr: 1 }}
                          >
                            Voltar
                          </Button>
                        </div>
                      </Box>
                    )}
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
}

Ticket.defaultProps = {
  hideTrigger: false,
};

export default Ticket;
