import { useEffect, useState } from "react";

import {
  Box,
  Drawer,
  Stack,
  Divider,
  Typography,
  IconButton,
  Button,
  Card,
  Alert,
  Link,
  Backdrop,
  CircularProgress,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import CelebrationIcon from "@mui/icons-material/Celebration";

import useAuthStore from "../../../../../stores/auth";
import useHrmEmployeeStore from "../../../../../stores/hrEmployee";
import useNavStore from "../../../../../stores/nav";

import { fetchData } from "../../../../../fetchData";
import { personRequiredFields } from "../../../../../utils/personRequiredFields";

const HRManagementEmployeeExtract = () => {
  const data: any = useHrmEmployeeStore((state) => state.data);
  const snackbar = useNavStore((state) => state.setSnackbar);
  const setData = useHrmEmployeeStore((state) => state.setState);
  const drawersRef = useHrmEmployeeStore((state) => state.drawersRef);
  const company = useAuthStore((state) => state.company);
  const [state, setState] = useState(false);
  const [error, setError] = useState(false);
  const [backdrop, setBackdrop] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [required, setRequired] = useState([]);

  const validation = () => {
    setEmpty(false);
    const isRequired: any = [];

    personRequiredFields.map((field: any) => {
      if (!data.draft[field.name]) {
        isRequired.push(field);
        return;
      }

      if (
        field.required &&
        field.required.length &&
        field.validation === data.draft[field.name]
      ) {
        field.required.map((subfield: any) => {
          if (!data.draft[subfield.name]) {
            isRequired.push(subfield);
          }
        });
      }
    });

    setRequired(isRequired);
    if (isRequired.length) {
      setEmpty(true);
    }
  };

  useEffect(() => {
    if (state) {
      validation();
    }
  }, [state]);

  const handleEmptyFieldRedirect = (field: any) => {
    if (!field.drawer) return;

    const drawer: any = drawersRef.filter(
      (row: any) => row.name === field.drawer,
    );

    if (drawer.length) {
      drawer[0].ref.current.click();
    }
  };

  const EmptyAlert = () => {
    return (
      <Alert
        severity="error"
        sx={{
          flexDirection: "column",
          alignItems: "center",
          textAlign: "cenrer",
          mb: 3,
        }}
      >
        <Typography textAlign="center" variant="body2">
          Você possui campos a serem preenchidos antes do envio, confira abaixo
          os campos.
        </Typography>
      </Alert>
    );
  };

  const AllGoodAlert = () => {
    return (
      <Alert
        severity="info"
        sx={{
          flexDirection: "column",
          alignItems: "center",
          textAlign: "cenrer",
          mb: 3,
        }}
        icon={<CelebrationIcon />}
      >
        <Typography textAlign="center" variant="h5" fontWeight={700}>
          Muito bem!
        </Typography>
        <Typography textAlign="center" variant="body2">
          As informações importantes foram preenchidas, você pode prosseguir com
          o enviando clicando no botão abaixo.
        </Typography>
      </Alert>
    );
  };

  const handleSubmit = async () => {
    setState(false);
    setBackdrop(true);
    setError(false);

    try {
      await fetchData(
        `/hrm/fopag/${company.reference}/person/drafts/${data?._id}/dispatch`,
        {
          method: "GET",
        },
      );

      setData({ ...data, status: "processing" });
      setBackdrop(false);
      snackbar(
        "Nova admissão enviada para processamento com sucesso!",
        "success",
      );
    } catch (error) {
      setError(true);
    }
  };

  return (
    <>
      {data?.status === "editing" && (
        <Button
          variant="contained"
          size="small"
          color="warning"
          onClick={() => {
            setState(true);
          }}
          startIcon={<SendIcon />}
          disabled={!data?._id}
        >
          Enviar
        </Button>
      )}
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          ml: "0 !important",
        }}
        open={backdrop}
      >
        {!error && <CircularProgress color="inherit" />}
        {error && (
          <Card>
            <Stack spacing={2}>
              <Typography textAlign="center" variant="h4" color="primary.text">
                Ops!
              </Typography>
              <Typography
                textAlign="center"
                variant="body2"
                color="primary.text"
              >
                Não foi possível enviar o cadastro no momento.
                <br />
                Tente novamente mais tarde.
              </Typography>
              <Button
                fullWidth
                variant="contained"
                size="small"
                color="primary"
                onClick={() => setBackdrop(false)}
              >
                Voltar
              </Button>
            </Stack>
          </Card>
        )}
      </Backdrop>
      <Drawer
        anchor="right"
        open={state}
        onClose={() => {
          setState(false);
        }}
      >
        <Box sx={{ p: 3, pt: 2, width: 400 }} width={300} height="100%">
          <Stack height="100%" spacing={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack
                direction="row"
                spacing={1}
                sx={{ flex: 1 }}
                alignItems="center"
              >
                <Typography
                  variant="body1"
                  sx={{ width: 170, wordWrap: "break-word" }}
                >
                  Confirme o envio
                </Typography>
              </Stack>
              <IconButton
                onClick={() => {
                  setState(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Divider sx={{ my: 2 }} />

            <Stack
              direction="row"
              spacing={2}
              position="fixed"
              right={0}
              bottom={0}
              zIndex={1}
              padding={2}
              sx={{
                width: 400,
                backgroundColor: "white",
                boxShadow: 5,
              }}
            >
              <Button
                fullWidth
                variant="outlined"
                size="small"
                color="secondary"
                onClick={() => setState(false)}
              >
                Cancelar
              </Button>
              <Button
                fullWidth
                variant="contained"
                size="small"
                color="warning"
                disabled={empty}
                startIcon={<SendIcon />}
                onClick={handleSubmit}
              >
                Enviar
              </Button>
            </Stack>

            <Box sx={{ pb: 10 }}>
              {empty && <EmptyAlert />}
              {!empty && (
                <>
                  <Card sx={{ border: "1px solid #CCC", p: 2, mb: 2 }}>
                    <Typography variant="body1" fontWeight={700}>
                      {`${data?.draft?.nome || "Nome não definido"}`}
                    </Typography>
                    <Typography variant="body2" color="text.primary">
                      {company.name}
                    </Typography>
                  </Card>
                  <AllGoodAlert />
                </>
              )}
              {required.length > 0 &&
                required.map((row: any, index: number) => (
                  <Box key={index}>
                    <Stack
                      direction="row"
                      spacing={1}
                      justifyContent="space-between"
                      sx={{
                        borderBottom: "1px solid #1f252c1f",
                        p: 1,
                      }}
                    >
                      <Link
                        component="button"
                        color="#FF0000"
                        onClick={() => {
                          setState(false);
                          handleEmptyFieldRedirect(row);
                        }}
                      >
                        <Typography
                          variant="body2"
                          color="#FF0000"
                          fontWeight={500}
                        >
                          {row.label}
                        </Typography>
                      </Link>
                    </Stack>
                  </Box>
                ))}
            </Box>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default HRManagementEmployeeExtract;
