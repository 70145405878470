import { Button } from "@mui/material";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

import DownloadIcon from "@mui/icons-material/Download";

type Props = {
  data: object[];
  fileName: string;
};

const ExportExcel = ({ data, fileName }: Props) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = "xlsx";

  const exportToExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const file = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(file, `${fileName}.${fileExtension}`);
  };

  return (
    <Button
      variant="outlined"
      size="small"
      startIcon={<DownloadIcon />}
      onClick={() => {
        exportToExcel();
      }}
    >
      Exportar
    </Button>
  );
};

export default ExportExcel;
