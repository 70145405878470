import {
  Modal as MuiModal,
  Card,
  CardContent,
  CardHeader,
  Stack,
  IconButton,
  Typography,
  Chip,
  Box,
} from "@mui/material";

import useNavStore from "../../stores/nav";

import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  p: 0,
};

const Modal = () => {
  const modal = useNavStore((state: any) => state.modal);
  const close = useNavStore((state: any) => state.closeModal);

  return (
    <MuiModal open={modal.state} onClose={close}>
      <Card sx={style}>
        <CardHeader
          sx={{ borderBottom: "1px solid #CCCC" }}
          title={
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Typography variant="body1" component="span" fontWeight={400}>
                  {modal.title}
                </Typography>
                {modal.chip && (
                  <Chip
                    label={modal.chip}
                    color="primary"
                    size="small"
                    sx={{ ml: 1 }}
                  />
                )}
              </Box>
              <IconButton
                aria-label="delete"
                size="small"
                onClick={close}
                edge="end"
              >
                <CloseIcon />
              </IconButton>
            </Stack>
          }
        />
        <CardContent>{modal.children}</CardContent>
      </Card>
    </MuiModal>
  );
};

export default Modal;
