import { create } from "zustand";
import { fetchData } from "../fetchData";
import { fetchRRData } from "../fetchRRData";
import useNavStore from "./nav";
import useAuthStore from "./auth";
import TagManager from "react-gtm-module";

type State = {
  drawer: boolean;
  data: object;
  drawerData: object;
};

const initialState: State = {
  drawer: false,
  data: {},
  drawerData: {},
};

type Actions = {
  setData: (data: object) => void;
  toggleDrawer: (data?: object) => void;
  getLinktoDownload: (id: number, type: string) => Promise<string>;
  download: (id: number, type: string) => void;
  zipLinkCopy: (id: number) => void;
};

const useTaskStore = create<State & Actions>((set, get) => ({
  ...initialState,

  setData: (data: object) => set(() => ({ data })),

  toggleDrawer: (data?: object) => {
    set((state: State) => ({
      drawer: !state.drawer,
      drawerData: data ? data : [],
    }));
  },

  getLinktoDownload: async (id: number, type: string) => {
    let ids = "";

    if (type === "zip") {
      const docs = await fetchRRData(`/tasks/${id}/documents`, {
        method: "GET",
      });
      ids = docs.map((row: any) => row.id).toString();
    }

    const url = type === "zip" ? `zip?ids=${ids}` : `file/${id}`;

    const zip = await fetchData(`/tasks/download/${url}`, {
      method: "GET",
    });

    return zip.link;
  },

  download: async (id: number, type: string) => {
    try {
      const organization = useAuthStore.getState().company;
      const user = useAuthStore.getState().user;
      const link = document.createElement("a");
      link.href = await get().getLinktoDownload(id, type);

      if (type !== "zip") link.target = "_blank";

      document.body.appendChild(link);

      TagManager.dataLayer({
        dataLayer: {
          event: `download_${type === "zip" ? "zip" : "file"}`,
          organization,
          user,
        },
      });

      link.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        }),
      );

      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  },

  zipLinkCopy: async (id: number) => {
    const snackbar = useNavStore.getState().setSnackbar;

    try {
      const link = await get().getLinktoDownload(id, "zip");
      navigator.clipboard.writeText(link);
      snackbar("Link copiado com sucesso!", "success");
    } catch (error) {
      snackbar("Ops! Não foi possível gerar o link.", "error");
      console.log(error);
    }
  },
}));

export default useTaskStore;
