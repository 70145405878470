import { useState, useEffect } from "react";
import useNavStore from "../../../../stores/nav";
import useHrmStore from "../../../../stores/hrManagement";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { NumericFormat } from "react-number-format";

import AddIcon from "@mui/icons-material/Add";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

import HRManagementEventsDetailAddEvent from "./modals/AddEvent";

function HRManagementEventsDetailEdit() {
  const [progress, setProgress] = useState(true);
  const data = useHrmStore((state: any) => state.data);
  const setModal = useNavStore((state: any) => state.setModal);
  const employee = useHrmStore((state: any) => state.employee);
  const removeEvent = useHrmStore((state: any) => state.removeEvent);
  const updateEvent = useHrmStore((state: any) => state.updateEvent);
  const toggleState = useHrmStore((state: any) => state.toggleState);

  const handleEventFieldChange = (e: any, row: any) => {
    updateEvent(row, e.target.value);
  };

  const EmptyAlert = () => {
    return (
      <Alert
        severity="info"
        sx={{
          flexDirection: "column",
          alignItems: "center",
          textAlign: "cenrer",
          mb: 3,
        }}
      >
        <Stack spacing={2} alignItems="center" direction="column">
          <Typography>Nenhum evento vinculado até o momento.</Typography>
          <Button
            variant="outlined"
            color="info"
            startIcon={<AddIcon />}
            onClick={() =>
              setModal(
                "Novo Evento",
                <HRManagementEventsDetailAddEvent />,
                employee.nome,
              )
            }
          >
            Adicione um Evento
          </Button>
        </Stack>
      </Alert>
    );
  };

  return (
    <Card>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Typography variant="h5">{employee.nome}</Typography>
        <Stack direction="row" spacing={2}>
          <Button
            size="small"
            variant="outlined"
            color="warning"
            startIcon={<NavigateBeforeIcon />}
            onClick={toggleState}
          >
            Voltar
          </Button>
          {data.status === "editing" && (
            <Button
              size="small"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() =>
                setModal(
                  "Novo Evento",
                  <HRManagementEventsDetailAddEvent />,
                  `${employee.nome}`,
                )
              }
            >
              Evento
            </Button>
          )}
        </Stack>
      </Stack>
      <Divider sx={{ my: 3 }} />

      {!employee.events && <EmptyAlert />}
      {employee.events && employee.events.length === 0 && <EmptyAlert />}

      <Grid container spacing={2}>
        {employee.events &&
          employee.events.map((row: any) => (
            <Grid key={row.i_eventos} xs={12} md={6} lg={4}>
              <Card
                sx={{ width: "100%", borderColor: "#CCC", borderWidth: 1 }}
                variant="outlined"
              >
                <CardContent sx={{ p: 1 }}>
                  <Typography
                    sx={{ fontSize: 14, mb: 1 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    {row.nome}
                  </Typography>
                  {row.tipo_info === "D" && (
                    <NumericFormat
                      label="Valor/Referência"
                      fullWidth
                      defaultValue={row.value || null}
                      onBlur={(e: any) => handleEventFieldChange(e, row)}
                      disabled={data.status !== "editing"}
                      size="small"
                      allowNegative={false}
                      placeholder="0"
                      prefix=""
                      customInput={TextField}
                    />
                  )}
                  {row.tipo_info !== "D" && (
                    <NumericFormat
                      label="Valor/Referência"
                      fullWidth
                      defaultValue={row.value || null}
                      onBlur={(e: any) => handleEventFieldChange(e, row)}
                      disabled={data.status !== "editing"}
                      size="small"
                      thousandSeparator={row.tipo_info === "V" ? "." : ""}
                      decimalSeparator={row.tipo_info === "H" ? ":" : ","}
                      decimalScale={2}
                      fixedDecimalScale
                      allowNegative={false}
                      placeholder={row.tipo_info === "H" ? "00:00" : "0,00"}
                      prefix=""
                      customInput={TextField}
                    />
                  )}
                </CardContent>
                {data.status === "editing" && (
                  <CardActions>
                    <Button
                      size="small"
                      color="error"
                      variant="outlined"
                      onClick={() => removeEvent(row)}
                    >
                      Remover
                    </Button>
                  </CardActions>
                )}
              </Card>
            </Grid>
          ))}
      </Grid>
    </Card>
  );
}

export default HRManagementEventsDetailEdit;
