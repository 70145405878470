import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";

import ActionMenu from "../../../../components/ActionMenu";
import DataGrid from "../../../../components/DataGrid";

import useAuthStore from "../../../../stores/auth";
import useNavStore from "../../../../stores/nav";
import useHrmEmployeeStore from "../../../../stores/hrEmployee";

import { fetchData } from "../../../../fetchData";
import { formatDate } from "../../../../utils";
import { hrmLabels } from "../../../../utils/labels";

function HRManagementDrafts() {
  const navigate = useNavigate();
  const snackbar = useNavStore((state) => state.setSnackbar);
  const setModal = useNavStore((state: any) => state.setModal);
  const closeModal = useNavStore((state: any) => state.closeModal);
  const company = useAuthStore((state) => state.company);
  const [backdrop, setBackdrop] = useState(false);
  const [progress, setProgress] = useState(false);
  const [error, setError] = useState(false);
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const setState = useHrmEmployeeStore((state: any) => state.setState);

  const data = async (limit: number, skip: number) => {
    setProgress(true);

    try {
      const response = await fetchData(
        `/hrm/fopag/${company.reference}/person/drafts?limit=${limit}&start=${
          skip + 1
        }`,
        {
          method: "GET",
        },
      );

      if (response) {
        setRowCount(response.count);
        setRows(response.rows);
      }
    } catch (error) {
      console.log(error);
      setError(true);
    }

    setProgress(false);
  };

  const handleDelete = async (id: string) => {
    closeModal();
    setBackdrop(true);

    try {
      await fetchData(
        `/hrm/fopag/${company.reference}/person/drafts/${id}`,
        {
          method: "DELETE",
        },
        false,
      );

      snackbar("Rascunho apagado com sucesso!", "success");
      data(25, 0);
    } catch (error) {
      console.log(error);

      snackbar("Ops, não foi possível apagar o rascunho!", "error");
    }

    setBackdrop(false);
  };

  const columns: GridColDef[] = [
    {
      field: "nome",
      sortable: false,
      headerName: "Nome",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Link
            component="button"
            onClick={() => {
              setState(params.row);
              navigate(`/hr/employee/${params.row._id}`);
            }}
          >
            {params.row?.draft?.nome
              ? params.row.draft.nome
              : "Sem nome definido"}
          </Link>
        );
      },
    },
    {
      field: "status",
      sortable: false,
      width: 150,
      headerName: "Situação",
      renderCell: (params: any) => {
        const label = hrmLabels(params.row.status);

        return (
          <Chip
            label={label}
            variant={params.row.status === "editing" ? "outlined" : "filled"}
            color={params.row.status === "editing" ? "secondary" : "primary"}
          />
        );
      },
    },
    {
      field: "created_at",
      sortable: false,
      headerName: "Criado em",
      renderCell: (params: any) => {
        return formatDate(params.row.created_at, "D/M/Y");
      },
    },
    {
      field: "id",
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: any) => {
        const items = [
          {
            title: params.row.status === "editing" ? "Editar" : "Ver",
            icon: params.row.status === "editing" ? <EditIcon /> : <AddIcon />,
            onClick: () => {
              setState(params.row);
              navigate(`/hr/employee/${params.row._id}`);
            },
          },
        ];

        if (params.row.status === "editing") {
          items.push({
            title: "Apagar",
            icon: <DeleteIcon />,
            onClick: () => {
              setModal(
                "Remover Rascunho?",
                <DeleteConfirmationModal row={params.row} />,
                params.row?.draft?.nome
                  ? params.row.draft.nome
                  : "Sem nome definido",
              );
            },
          });
        }

        return <ActionMenu items={items} />;
      },
      renderHeader: (params: any) => {
        return <MoreHorizIcon />;
      },
    },
  ];

  const DeleteConfirmationModal = (props: any) => {
    const { row } = props;

    return (
      <Box>
        <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
          <Button
            size="small"
            variant="outlined"
            sx={{ flex: 1 }}
            onClick={() => {
              closeModal();
            }}
          >
            Cancelar
          </Button>
          <Button
            size="small"
            variant="contained"
            sx={{ flex: 1 }}
            onClick={() => {
              handleDelete(row._id);
            }}
          >
            SIM
          </Button>
        </Stack>
      </Box>
    );
  };

  if (error) {
    return (
      <Alert severity="error">
        Ops, no momento não foi possível realizar a listagem de rascunhos.
      </Alert>
    );
  }

  return (
    <>
      {backdrop && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={backdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <DataGrid
        rows={rows}
        data={data}
        loading={progress}
        columns={columns}
        rowCount={rowCount}
      />
    </>
  );
}

export default HRManagementDrafts;
