import { useState } from "react";
import { PatternFormat } from "react-number-format";
import DatePickerField from "../../../../../components/DatePickerField";

import {
  Box,
  Drawer,
  Stack,
  Divider,
  Typography,
  IconButton,
  Button,
  Chip,
  CircularProgress,
  TextField,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import PublicIcon from "@mui/icons-material/Public";
import CloudDoneIcon from "@mui/icons-material/CloudDone";

import useHrmEmployeeStore from "../../../../../stores/hrEmployee";

const HRManagementEmployeeForeign = () => {
  const [state, setState] = useState(false);
  const saveDraft = useHrmEmployeeStore((state: any) => state.saveDraft);
  const changeDraft = useHrmEmployeeStore((state: any) => state.changeDraft);
  const isSaving = useHrmEmployeeStore((state: any) => state.isSaving);
  const data = useHrmEmployeeStore((state: any) => state.data);

  return (
    <>
      <Button
        fullWidth
        variant="outlined"
        onClick={() => {
          setState(true);
        }}
        disabled={data?.status === "editing" ? false : true}
      >
        <Stack spacing={1} alignItems="center">
          <PublicIcon fontSize="large" />
          <div>Estrangeiro</div>
        </Stack>
      </Button>
      <Drawer
        anchor="left"
        open={state}
        onClose={() => {
          setState(false);
        }}
      >
        <Box sx={{ p: 3, pt: 2, width: 400 }} width={300} height="100%">
          <Stack height="100%" spacing={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack
                direction="row"
                spacing={1}
                sx={{ flex: 1 }}
                alignItems="center"
              >
                <Typography variant="body1">Estrangeiro</Typography>
                {data.status === "editing" && (
                  <Chip
                    color="success"
                    variant="outlined"
                    icon={
                      isSaving === true ? (
                        <CircularProgress color="warning" size={18} />
                      ) : (
                        <CloudDoneIcon fontSize="small" />
                      )
                    }
                    label={isSaving ? "Salvando..." : "Tudo Salvo"}
                  />
                )}
              </Stack>
              <IconButton
                onClick={() => {
                  setState(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Divider sx={{ my: 2 }} />
            <TextField
              name="naturalizado"
              fullWidth
              variant="filled"
              select
              size="small"
              label="Naturalizado?"
              defaultValue={data.draft?.naturalizado || null}
              SelectProps={{ native: true }}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
            >
              <option value=""></option>
              <option value="Sim">Sim</option>
              <option value="Nao">Não</option>
            </TextField>
            <PatternFormat
              format="##/##/####"
              name="data_naturalizacao"
              fullWidth
              variant="filled"
              label="Data de Naturalização"
              defaultValue={data.draft?.data_naturalizacao || null}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              customInput={TextField}
              sx={{ mb: 1 }}
            />
            <PatternFormat
              format="##/##/####"
              name="data_chegada"
              fullWidth
              variant="filled"
              label="Data de Chegada"
              defaultValue={data.draft?.data_chegada || null}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              customInput={TextField}
              sx={{ mb: 1 }}
            />
            <TextField
              name="tipo_visto"
              fullWidth
              variant="filled"
              select
              size="small"
              label="Tipo de Visto"
              defaultValue={data.draft?.tipo_visto || null}
              SelectProps={{ native: true }}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
            >
              <option value=""></option>
              <option value="Beneficiado pelo Tratado de Amizade, Cooperação e Consulta entre a República Federativa do Brasil e a República Portuguesa">
                Beneficiado pelo Tratado de Amizade, Cooperação e Consulta entre
                a República Federativa do Brasil e a República Portuguesa
              </option>
              <option value="Dependente de agente diplomático e/ou consular de países que mantém convênio de reciprocidade para o exercício de atividade remunerada no Brasil">
                Dependente de agente diplomático e/ou consular de países que
                mantém convênio de reciprocidade para o exercício de atividade
                remunerada no Brasil
              </option>
              <option value="Beneficiado pelo acordo entre países do Mercosul">
                Beneficiado pelo acordo entre países do Mercosul
              </option>
              <option value="Permanência no Brasil em razão de filhos ou cônjuges brasileiros ou em razão de reunião familiar">
                Permanência no Brasil em razão de filhos ou cônjuges brasileiros
                ou em razão de reunião familiar
              </option>
              <option value="Com residência provisória e anistiado, em situação irregular">
                Com residência provisória e anistiado, em situação irregular
              </option>
              <option value="Deficiente físico e com mais de 51 anos">
                Deficiente físico e com mais de 51 anos
              </option>
              <option value="Residente fora do Brasil">
                Residente fora do Brasil
              </option>
              <option value="Solicitante de Refúgio">
                Solicitante de Refúgio
              </option>
              <option value="Refugiado">Refugiado</option>
              <option value="Asilado">Asilado</option>
              <option value="Turista">Turista</option>
              <option value="Visto permanente">Visto permanente</option>
              <option value="Visto temporário">Visto temporário</option>
            </TextField>
            <TextField
              name="conjuge_brasileiro"
              fullWidth
              variant="filled"
              select
              size="small"
              label="Casado(a) com Brasileiro?"
              defaultValue={data.draft?.conjuge_brasileiro || null}
              SelectProps={{ native: true }}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
            >
              <option value=""></option>
              <option value="Sim">Sim</option>
              <option value="Não">Não</option>
            </TextField>
            <TextField
              name="filhos_brasileiros"
              fullWidth
              variant="filled"
              select
              size="small"
              label="Filhos Brasileiros?"
              defaultValue={data.draft?.filhos_brasileiros || null}
              SelectProps={{ native: true }}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
            >
              <option value=""></option>
              <option value="Sim">Sim</option>
              <option value="Não">Não</option>
            </TextField>
            <Divider sx={{ my: 2 }} />
            <TextField
              name="rne_numero"
              fullWidth
              variant="filled"
              label="Número do RNE"
              defaultValue={data.draft?.rne_numero || null}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
            />
            <PatternFormat
              format="##/##/####"
              name="rne_data_expedicao"
              fullWidth
              variant="filled"
              label="Data de Expedição do RNE"
              defaultValue={data.draft?.rne_data_expedicao || null}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              customInput={TextField}
              sx={{ mb: 1 }}
            />
            <TextField
              name="rne_orgao_emissor"
              fullWidth
              variant="filled"
              label="Orgão Emissor do RNE"
              defaultValue={data.draft?.rne_orgao_emissor || null}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
            />
            <PatternFormat
              format="##/##/####"
              name="rne_data_validade"
              fullWidth
              variant="filled"
              label="Data de Validade do RNE"
              defaultValue={data.draft?.rne_data_validade || null}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              customInput={TextField}
              sx={{ mb: 1 }}
            />
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default HRManagementEmployeeForeign;
