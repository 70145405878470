import { useState, useRef, useEffect } from "react";
import { PatternFormat } from "react-number-format";
import DatePickerField from "../../../../../components/DatePickerField";

import {
  Box,
  Drawer,
  Stack,
  Divider,
  Typography,
  IconButton,
  Button,
  Chip,
  CircularProgress,
  TextField,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import BadgeIcon from "@mui/icons-material/Badge";
import CloudDoneIcon from "@mui/icons-material/CloudDone";

import useHrmEmployeeStore from "../../../../../stores/hrEmployee";

const HRManagementEmployeeProfessional = () => {
  const [state, setState] = useState(false);
  const openRef = useRef(null);
  const saveDraft = useHrmEmployeeStore((state: any) => state.saveDraft);
  const changeDraft = useHrmEmployeeStore((state: any) => state.changeDraft);
  const isSaving = useHrmEmployeeStore((state: any) => state.isSaving);
  const data = useHrmEmployeeStore((state: any) => state.data);
  const addDrawersRef = useHrmEmployeeStore(
    (state: any) => state.addDrawersRef,
  );

  useEffect(() => addDrawersRef({ name: "professional", ref: openRef }), []);

  return (
    <>
      <Button
        fullWidth
        ref={openRef}
        variant="outlined"
        onClick={() => {
          setState(true);
        }}
        disabled={data?.status === "editing" ? false : true}
      >
        <Stack spacing={1} alignItems="center">
          <BadgeIcon fontSize="large" />
          <div>Profissional</div>
        </Stack>
      </Button>
      <Drawer
        anchor="left"
        open={state}
        onClose={() => {
          setState(false);
        }}
      >
        <Box sx={{ p: 3, pt: 2, width: 400 }} width={300} height="100%">
          <Stack height="100%" spacing={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack
                direction="row"
                spacing={1}
                sx={{ flex: 1 }}
                alignItems="center"
              >
                <Typography variant="body1">Profissional</Typography>
                {data.status === "editing" && (
                  <Chip
                    color="success"
                    variant="outlined"
                    icon={
                      isSaving === true ? (
                        <CircularProgress color="warning" size={18} />
                      ) : (
                        <CloudDoneIcon fontSize="small" />
                      )
                    }
                    label={isSaving ? "Salvando..." : "Tudo Salvo"}
                  />
                )}
              </Stack>
              <IconButton
                onClick={() => {
                  setState(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Divider sx={{ my: 2 }} />
            <TextField
              name="pis_numero"
              fullWidth
              variant="filled"
              label="Número do PIS"
              defaultValue={data.draft?.pis_numero || null}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
              required
            />
            <Divider sx={{ my: 2 }} />
            <TextField
              name="ctps_numero"
              fullWidth
              variant="filled"
              label="Número da CTPS"
              defaultValue={data.draft?.ctps_numero || null}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
            />
            <TextField
              name="ctps_serie"
              fullWidth
              variant="filled"
              label="Série da CTPS"
              defaultValue={data.draft?.ctps_serie || null}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
            />
            <PatternFormat
              format="##/##/####"
              name="ctps_data_expedicao"
              fullWidth
              variant="filled"
              label="Data de Expedição da CTPS"
              defaultValue={data.draft?.ctps_data_expedicao || null}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              customInput={TextField}
              sx={{ mb: 1 }}
            />
            <TextField
              name="ctps_estado"
              fullWidth
              variant="filled"
              select
              size="small"
              label="Estado da CTPS"
              defaultValue={data.draft?.ctps_estado || null}
              SelectProps={{ native: true }}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
            >
              <option value=""></option>
              <option value="AC">AC</option>
              <option value="AL">AL</option>
              <option value="AM">AM</option>
              <option value="AP">AP</option>
              <option value="BA">BA</option>
              <option value="CE">CE</option>
              <option value="DF">DF</option>
              <option value="ES">ES</option>
              <option value="EX">EX</option>
              <option value="GO">GO</option>
              <option value="MA">MA</option>
              <option value="MG">MG</option>
              <option value="MS">MS</option>
              <option value="MT">MT</option>
              <option value="PA">PA</option>
              <option value="PB">PB</option>
              <option value="PE">PE</option>
              <option value="PI">PI</option>
              <option value="PR">PR</option>
              <option value="RJ">RJ</option>
              <option value="RN">RN</option>
              <option value="RO">RO</option>
              <option value="RR">RR</option>
              <option value="RS">RS</option>
              <option value="SC">SC</option>
              <option value="SE">SE</option>
              <option value="SP">SP</option>
              <option value="TO">TO</option>
            </TextField>
            <Divider sx={{ my: 2 }} />
            <TextField
              name="forma_pagamento"
              fullWidth
              variant="filled"
              select
              size="small"
              label="Forma de Pagamento"
              defaultValue={data.draft?.forma_pagamento || null}
              SelectProps={{ native: true }}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
              required
            >
              <option value=""></option>
              <option value="Crédito em Conta">Crédito em Conta</option>
              <option value="Dinheiro">Dinheiro</option>
              <option value="Cheque">Cheque</option>
            </TextField>
            <TextField
              name="pagamento_tipo_conta"
              fullWidth
              variant="filled"
              select
              size="small"
              label="Tipo de Conta"
              defaultValue={data.draft?.pagamento_tipo_conta || null}
              SelectProps={{ native: true }}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
            >
              <option value=""></option>
              <option value="Conta Eletrônica">Conta Eletrônica</option>
              <option value="Conta/Cartão Salário">Conta/Cartão Salário</option>
              <option value="DOC">DOC</option>
              <option value="Poupança">Poupança</option>
              <option value="Conta Corrente">Conta Corrente</option>
            </TextField>
            <Divider sx={{ my: 2 }} />
            <TextField
              name="pagamento_banco_codigo"
              fullWidth
              variant="filled"
              label="Código do Banco"
              defaultValue={data.draft?.pagamento_banco_codigo || null}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
              required
            />
            <TextField
              name="pagamento_banco_agencia"
              fullWidth
              variant="filled"
              label="Número da Agência"
              defaultValue={data.draft?.pagamento_banco_agencia || null}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
              required
            />
            <TextField
              name="pagamento_banco_conta"
              fullWidth
              variant="filled"
              label="Número da Conta"
              defaultValue={data.draft?.pagamento_banco_conta || null}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
              required
            />
            <TextField
              name="pagamento_banco_conta_digito"
              fullWidth
              variant="filled"
              label="Dígito da Conta"
              defaultValue={data.draft?.pagamento_banco_conta_digito || null}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
              required
            />
            <TextField
              name="pagamento_banco_chave_pix"
              fullWidth
              variant="filled"
              label="Chave PIX"
              defaultValue={data.draft?.pagamento_banco_chave_pix || null}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
              required
            />
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default HRManagementEmployeeProfessional;
