import { useState, useRef, useEffect } from "react";
import { PatternFormat } from "react-number-format";

import {
  Box,
  Drawer,
  Stack,
  Divider,
  Typography,
  IconButton,
  Button,
  Chip,
  CircularProgress,
  TextField,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import CloudDoneIcon from "@mui/icons-material/CloudDone";

import useHrmEmployeeStore from "../../../../../stores/hrEmployee";

const HRManagementEmployeeDocuments = () => {
  const [state, setState] = useState(false);
  const openRef = useRef(null);
  const saveDraft = useHrmEmployeeStore((state: any) => state.saveDraft);
  const changeDraft = useHrmEmployeeStore((state: any) => state.changeDraft);
  const isSaving = useHrmEmployeeStore((state: any) => state.isSaving);
  const data = useHrmEmployeeStore((state: any) => state.data);
  const addDrawersRef = useHrmEmployeeStore(
    (state: any) => state.addDrawersRef,
  );

  useEffect(() => addDrawersRef({ name: "documents", ref: openRef }), []);

  return (
    <>
      <Button
        fullWidth
        ref={openRef}
        variant="outlined"
        onClick={() => {
          setState(true);
        }}
        disabled={data?.status === "editing" ? false : true}
      >
        <Stack spacing={1} alignItems="center">
          <ContactPageIcon fontSize="large" />
          <div>Documentos</div>
        </Stack>
      </Button>
      <Drawer
        anchor="left"
        open={state}
        onClose={() => {
          setState(false);
        }}
      >
        <Box sx={{ p: 3, pt: 2, width: 400 }} width={300} height="100%">
          <Stack height="100%" spacing={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack
                direction="row"
                spacing={1}
                sx={{ flex: 1 }}
                alignItems="center"
              >
                <Typography variant="body1">Documentos</Typography>
                {data.status === "editing" && (
                  <Chip
                    color="success"
                    variant="outlined"
                    icon={
                      isSaving === true ? (
                        <CircularProgress color="warning" size={18} />
                      ) : (
                        <CloudDoneIcon fontSize="small" />
                      )
                    }
                    label={isSaving ? "Salvando..." : "Tudo Salvo"}
                  />
                )}
              </Stack>
              <IconButton
                onClick={() => {
                  setState(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Divider sx={{ my: 2 }} />
            <TextField
              name="rg"
              fullWidth
              variant="filled"
              label="Número do RG"
              defaultValue={data.draft?.rg || null}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
              required
            />
            <TextField
              name="orgao_emissor_rg"
              fullWidth
              variant="filled"
              label="Orgão Emissor do RG"
              defaultValue={data.draft?.orgao_emissor_rg || null}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
            />
            <TextField
              name="estado_rg"
              fullWidth
              variant="filled"
              select
              size="small"
              label="Estado Emissor do RG"
              defaultValue={data.draft?.estado_rg || null}
              SelectProps={{ native: true }}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
            >
              <option value=""></option>
              <option value="AC">AC</option>
              <option value="AL">AL</option>
              <option value="AM">AM</option>
              <option value="AP">AP</option>
              <option value="BA">BA</option>
              <option value="CE">CE</option>
              <option value="DF">DF</option>
              <option value="ES">ES</option>
              <option value="EX">EX</option>
              <option value="GO">GO</option>
              <option value="MA">MA</option>
              <option value="MG">MG</option>
              <option value="MS">MS</option>
              <option value="MT">MT</option>
              <option value="PA">PA</option>
              <option value="PB">PB</option>
              <option value="PE">PE</option>
              <option value="PI">PI</option>
              <option value="PR">PR</option>
              <option value="RJ">RJ</option>
              <option value="RN">RN</option>
              <option value="RO">RO</option>
              <option value="RR">RR</option>
              <option value="RS">RS</option>
              <option value="SC">SC</option>
              <option value="SE">SE</option>
              <option value="SP">SP</option>
              <option value="TO">TO</option>
            </TextField>
            <PatternFormat
              format="##/##/####"
              name="data_emissao_rg"
              fullWidth
              variant="filled"
              label="Data de Emissão do RG"
              defaultValue={data.draft?.data_emissao_rg || null}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              customInput={TextField}
              sx={{ mb: 1 }}
            />
            <Divider sx={{ my: 2 }} />
            <TextField
              name="titulo_eleitor_numero"
              fullWidth
              variant="filled"
              label="Número do Título Eleitoral"
              defaultValue={data.draft?.titulo_eleitor_numero || null}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
              required
            />
            <TextField
              name="titulo_eleitor_zona"
              fullWidth
              variant="filled"
              label="Zona do Título Eleitoral"
              defaultValue={data.draft?.titulo_eleitor_zona || null}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
              required
            />
            <TextField
              name="titulo_eleitor_secao"
              fullWidth
              variant="filled"
              label="Seção do Título Eleitoral"
              defaultValue={data.draft?.titulo_eleitor_secao || null}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
              required
            />
            <Divider sx={{ my: 2 }} />
            <TextField
              name="cnh_numero"
              fullWidth
              variant="filled"
              label="Número da Carteira de Motorista"
              defaultValue={data.draft?.cnh_numero || null}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
            />
            <TextField
              name="cnh_categoria"
              fullWidth
              variant="filled"
              label="Categoria da Carteira de Motorista"
              defaultValue={data.draft?.cnh_categoria || null}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
            />
            <PatternFormat
              format="##/##/####"
              name="cnh_vencimento"
              fullWidth
              variant="filled"
              label="Vencimento da Carteira de Motorista"
              defaultValue={data.draft?.cnh_vencimento || null}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              customInput={TextField}
              sx={{ mb: 1 }}
            />
            <Divider sx={{ my: 2 }} />
            <TextField
              name="reservista_numero"
              fullWidth
              variant="filled"
              label="Número da Carteira de Reservista"
              defaultValue={data.draft?.reservista_numero || null}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
            />
            <TextField
              name="reservista_categoria"
              fullWidth
              variant="filled"
              label="Categoria da Carteira de Reservista"
              defaultValue={data.draft?.reservista_categoria || null}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
            />
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default HRManagementEmployeeDocuments;
