import { useState, useRef, useEffect } from "react";
import { PatternFormat } from "react-number-format";

import {
  Box,
  Drawer,
  Stack,
  Divider,
  Typography,
  IconButton,
  Button,
  Chip,
  CircularProgress,
  TextField,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import CloudDoneIcon from "@mui/icons-material/CloudDone";

import useHrmEmployeeStore from "../../../../../stores/hrEmployee";

const tipo_vinculo = [
  "Nao aparece no sefip",
  "Celetista",
  "Contrato Prazo Determinado Lei 9.601/98",
  "Diretor sem vinculo, SEM FGTS",
  "Autonomo",
  "Aut. Classe 14",
  "Transp. 15",
  "Transp. Classe 16",
  "Cooperado",
  "Transportador cooperado",
  "Estatutario",
  "Contrib. indiv. contratado por contrib. indiv",
  "Contrib. indiv. transportador aut. contratado por contrib. indiv.",
  "Cooperado que presta serv. para entidade beneficente",
  "Transportador cooperado que presta serv. para entidade beneficente",
  "Trabalhador Avulso",
  "Trabalhador Temporario Lei 6.019/74",
  "Celetista Contrato Prazo Determinado ",
  "Estagiario",
  "Contrato Experiência",
  "Empregado Domestico",
  "Menor Aprendiz",
  "Empregado Doméstico - Regime Tempo Parcial",
  "Trabalhador Rural",
  "Diretor s/ Vinculo c/ FGTS",
  "Servidor Público nao Efetivo",
  "Trab. Rural Contrato Prazo Determinado",
  "Celetista regime tempo parcial",
  "Intermitente",
];

const categoria = [
  "Mensalista",
  "Semanalista",
  "Diarista",
  "Horista",
  "Tarefeiro",
  "Comissionado",
  "Diretor",
];

const tipo_admissao = [
  "1º emprego",
  "Normal",
  "Decorrente de ação fiscal",
  "Decorrente de decisão judicial",
];

const HRManagementEmployeeAdmission = () => {
  const [state, setState] = useState(false);
  const openRef = useRef(null);
  const saveDraft = useHrmEmployeeStore((state: any) => state.saveDraft);
  const changeDraft = useHrmEmployeeStore((state: any) => state.changeDraft);
  const isSaving = useHrmEmployeeStore((state: any) => state.isSaving);
  const data = useHrmEmployeeStore((state: any) => state.data);
  const addDrawersRef = useHrmEmployeeStore(
    (state: any) => state.addDrawersRef,
  );

  useEffect(() => addDrawersRef({ name: "admission", ref: openRef }), []);

  return (
    <>
      <Button
        fullWidth
        ref={openRef}
        variant="outlined"
        onClick={() => {
          setState(true);
        }}
        disabled={data?.status === "editing" ? false : true}
      >
        <Stack spacing={1} alignItems="center">
          <VerifiedUserIcon fontSize="large" />
          <div>Admissão</div>
        </Stack>
      </Button>
      <Drawer
        anchor="left"
        open={state}
        onClose={() => {
          setState(false);
        }}
      >
        <Box sx={{ p: 3, pt: 2, width: 400 }} width={300} height="100%">
          <Stack height="100%" spacing={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack
                direction="row"
                spacing={1}
                sx={{ flex: 1 }}
                alignItems="center"
              >
                <Typography variant="body1">Admissão</Typography>
                {data.status === "editing" && (
                  <Chip
                    color="success"
                    variant="outlined"
                    icon={
                      isSaving === true ? (
                        <CircularProgress color="warning" size={18} />
                      ) : (
                        <CloudDoneIcon fontSize="small" />
                      )
                    }
                    label={isSaving ? "Salvando..." : "Tudo Salvo"}
                  />
                )}
              </Stack>
              <IconButton
                onClick={() => {
                  setState(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Divider sx={{ my: 2 }} />
            <TextField
              name="cargo"
              fullWidth
              variant="filled"
              label="Cargo"
              defaultValue={data.draft?.cargo || null}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
              required
            />
            <TextField
              name="centro_de_custo"
              fullWidth
              variant="filled"
              label="Departamento"
              defaultValue={data.draft?.centro_de_custo || null}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
            />
            <TextField
              name="valor_salario"
              fullWidth
              variant="filled"
              label="Valor do Salário"
              defaultValue={data.draft?.valor_salario || null}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
              required
            />
            <PatternFormat
              format="##/##/####"
              name="data_admissao"
              fullWidth
              variant="filled"
              label="Data de Admissão"
              defaultValue={data.draft?.data_admissao || null}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              customInput={TextField}
              sx={{ mb: 1 }}
              required
            />
            <TextField
              name="tipo_vinculo"
              fullWidth
              variant="filled"
              select
              size="small"
              label="Tipo de vínculo"
              defaultValue={data.draft?.tipo_vinculo || null}
              SelectProps={{ native: true }}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
              required
            >
              <option value=""></option>
              {tipo_vinculo.map((row: string, index: number) => (
                <option value={row} key={`tipo_vinculo_${index}`}>
                  {row}
                </option>
              ))}
            </TextField>
            <TextField
              name="horario_trabalho"
              fullWidth
              variant="filled"
              label="Horário de trabalho"
              defaultValue={data.draft?.horario_trabalho || null}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              placeholder="Das 00:00 às 00:00 e das 00:00 às 00:00"
              sx={{ mb: 1 }}
              required
            />
            <TextField
              name="possui_adiantamento"
              fullWidth
              variant="filled"
              select
              size="small"
              label="Possui Adiantamento?"
              defaultValue={data.draft?.possui_adiantamento || null}
              SelectProps={{ native: true }}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
              required
            >
              <option value=""></option>
              <option value="Sim">Sim</option>
              <option value="Nao">Não</option>
            </TextField>
            <TextField
              name="utilizara_vt"
              fullWidth
              variant="filled"
              select
              size="small"
              label="Utilizará VT?"
              defaultValue={data.draft?.utilizara_vt || null}
              SelectProps={{ native: true }}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
              required
            >
              <option value=""></option>
              <option value="Sim">Sim</option>
              <option value="Nao">Não</option>
            </TextField>
            <TextField
              name="multiplos_vinculos"
              fullWidth
              variant="filled"
              select
              size="small"
              label="Múltiplos Vínculos?"
              defaultValue={data.draft?.multiplos_vinculos || null}
              SelectProps={{ native: true }}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
              required
            >
              <option value=""></option>
              <option value="Sim, abaixo do teto">Sim, abaixo do teto</option>
              <option value="Sim">Sim</option>
              <option value="Nao">Não</option>
              <option value="Sim, acima do teto">Sim, acima do teto</option>
            </TextField>
            <TextField
              name="pagou_contribuicao_sindical"
              fullWidth
              variant="filled"
              select
              size="small"
              label="Pagou contribuição sindical?"
              defaultValue={data.draft?.pagou_contribuicao_sindical || null}
              SelectProps={{ native: true }}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
            >
              <option value=""></option>
              <option value="Sim, profissional liberal"></option>
              <option value="Nao">Não</option>
              <option value="Sim">Sim</option>
              <option value="Nao pagar">Não pagar</option>
            </TextField>
            <TextField
              name="categoria"
              fullWidth
              variant="filled"
              select
              size="small"
              label="Categoria"
              defaultValue={data.draft?.categoria || null}
              SelectProps={{ native: true }}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
              required
            >
              <option value=""></option>
              {categoria.map((row: string, index: number) => (
                <option value={row} key={`categoria_${index}`}>
                  {row}
                </option>
              ))}
            </TextField>
            <TextField
              name="tipo_admissao"
              fullWidth
              variant="filled"
              select
              size="small"
              label="Tipo de Admissão"
              defaultValue={data.draft?.tipo_admissao || null}
              SelectProps={{ native: true }}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
            >
              <option value=""></option>
              {tipo_admissao.map((row: string, index: number) => (
                <option value={row} key={`tipo_admissao_${index}`}>
                  {row}
                </option>
              ))}
            </TextField>
            <TextField
              name="jornada"
              fullWidth
              variant="filled"
              label="Jornada do Empregado"
              defaultValue={data.draft?.jornada || null}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
              required
              placeholder="Seg a sexta, Sáb, 6x1"
            />
            <TextField
              name="contrato_experiencia"
              fullWidth
              variant="filled"
              select
              size="small"
              label="Possui Contrato de Experiência?"
              defaultValue={data.draft?.contrato_experiencia || null}
              SelectProps={{ native: true }}
              onBlur={() => saveDraft()}
              onChange={changeDraft}
              sx={{ mb: 1 }}
              required
            >
              <option value=""></option>
              <option value="Não Possui">Não Possui</option>
              <option value="Possui">Possui</option>
            </TextField>
            {data.draft?.contrato_experiencia === "Possui" && (
              <>
                <TextField
                  name="dias_experiencia"
                  fullWidth
                  variant="filled"
                  label="Quantidade de Dias"
                  defaultValue={data.draft?.dias_experiencia || null}
                  onBlur={() => saveDraft()}
                  onChange={changeDraft}
                  sx={{ mb: 1 }}
                  required
                />
                <TextField
                  name="dias_prorrogacao"
                  fullWidth
                  variant="filled"
                  label="Dias de Prorrogação"
                  defaultValue={data.draft?.dias_prorrogacao || null}
                  onBlur={() => saveDraft()}
                  onChange={changeDraft}
                  sx={{ mb: 1 }}
                  required
                />
                <TextField
                  name="motivo_prorrogacao"
                  fullWidth
                  variant="filled"
                  label="Motivo da Prorrogação"
                  defaultValue={data.draft?.motivo_prorrogacao || null}
                  onBlur={() => saveDraft()}
                  onChange={changeDraft}
                  sx={{ mb: 1 }}
                  required
                />
              </>
            )}
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default HRManagementEmployeeAdmission;
