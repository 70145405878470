import DragAndDropFiles from "../../../../components/DragAndDropFiles";
import useTicketStore from "../../../../stores/ticket";

const TicketAttachments = () => {
  const token = useTicketStore((state) => state.token);
  const setToken = useTicketStore((state) => state.setToken);

  const handleResponse = (response: any) => {
    if (response.data && response.data.upload && response.data.upload.token) {
      setToken(response.data.upload.token);
    }
  };

  return (
    <DragAndDropFiles
      baseUrl={`zendesk/upload${token ? `?token=${token}` : ""}`}
      multiple={false}
      callback={
        !token
          ? handleResponse
          : () => {
              return false;
            }
      }
      allowedFiles=".pdf,.jpeg,.jpg,.gif,.png,.zip,.doc,.docx,.xls,.xlsx,.csv"
    />
  );
};

export default TicketAttachments;
