import { useEffect, useState } from "react";
import { Alert, Link, Skeleton, Stack } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import BlockIcon from "@mui/icons-material/Block";
import DownloadIcon from "@mui/icons-material/Download";

import ActionMenu from "../../../../components/ActionMenu";
import DataGrid from "../../../../components/DataGrid";
import HrmEmployeeTaxPayerDetailDrawer from "../drawers/detail";
import HrmEmployeeTerminationDrawer from "../drawers/termination";
import HrmEmployeeVacationDrawer from "../drawers/vacation";

import useAuthStore from "../../../../stores/auth";
import useNavStore from "../../../../stores/nav";
import useHrmStore from "../../../../stores/hrManagement";
import useHrmTerminationStore from "../../../../stores/hrTermination";
import useHrmVacationStore from "../../../../stores/hrVacation";
import { usePDF, PDFDownloadLink } from "@react-pdf/renderer";

import { fetchDominioData } from "../../../../fetchDominioData";
import { formatDate } from "../../../../utils";
import MyDocument from "../../../../components/PdfDocument";

function HRManagementEmployees() {
  const company = useAuthStore((state) => state.company);
  const drawer = useHrmStore((state) => state.toggleDrawer);
  const terminationDrawer = useHrmTerminationStore(
    (state) => state.toggleDrawer,
  );
  const vacationDrawer = useHrmVacationStore((state) => state.toggleDrawer);
  const [progress, setProgress] = useState(false);
  const [error, setError] = useState(false);
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const exportData = useNavStore((state) => state.setExportData);

  const data = async (limit: number, skip: number) => {
    setProgress(true);

    try {
      const response = await fetchDominioData(
        `/empresa/${company.reference}/empregado?limit=${limit}&start=${
          skip + 1
        }`,
        {
          method: "GET",
        },
      );

      if (response) {
        setRowCount(response.count);
        setRows(response.rows);
        exportData(
          response.rows.map((row: any) => ({
            codigo: row.i_empregados,
            nome: row.nome,
            data_admissao: formatDate(row.admissao, "D/M/Y"),
          })),
        );
      }
    } catch (error) {
      console.log(error);
      setError(true);
    }

    setProgress(false);
  };

  const columns: GridColDef[] = [
    {
      field: "i_empregados",
      sortable: false,
      headerName: "Número",
      renderCell: (params: any) => {
        return params.row.i_empregados;
      },
    },
    {
      field: "nome",
      sortable: false,
      headerName: "Nome",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Link component="button" onClick={() => drawer(params.row)}>
            {params.row.nome}
          </Link>
        );
      },
    },
    {
      field: "admissao",
      sortable: false,
      headerName: "Admissão",
      renderCell: (params: any) => {
        return formatDate(params.row.admissao, "D/M/Y");
      },
    },
    {
      field: "id",
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: any) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [instance, updateInstance] = usePDF({
          document: (
            <MyDocument
              name={params.row.nome}
              document={params.row.cpf}
              local={`${params.row.cidade ? params.row.cidade : "Cidade"}, ${
                params.row.estado ? params.row.estado : "Estado"
              }`}
            />
          ),
        });

        const items = [
          {
            title: "Solicitar Férias",
            icon: <TaskAltIcon />,
            onClick: () => vacationDrawer(params.row),
          },
          {
            title: "Solicitar Rescisão",
            icon: <BlockIcon />,
            onClick: () => terminationDrawer(params.row),
          },
          {
            title: "Declaração Étnico-Racial",
            icon: <DownloadIcon />,
            onClick: () => {
              if (instance.url) {
                window.open(instance.url);
              } else {
                console.error("URL não está disponível");
              }
            },
          },
        ];
        return <ActionMenu items={items} />;
      },
      renderHeader: (params: any) => {
        return <MoreHorizIcon />;
      },
    },
  ];

  if (error) {
    return (
      <Alert severity="error">
        Ops, no momento não foi possível realizar a listagem de empregados.
      </Alert>
    );
  }

  return (
    <>
      <DataGrid
        rows={rows}
        data={data}
        loading={progress}
        columns={columns}
        rowCount={rowCount}
      />
      <HrmEmployeeTaxPayerDetailDrawer />
      <HrmEmployeeTerminationDrawer />
      <HrmEmployeeVacationDrawer />
    </>
  );
}

export default HRManagementEmployees;
