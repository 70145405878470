import { useEffect, useState } from "react";

import {
  Box,
  Drawer,
  Stack,
  Divider,
  Typography,
  IconButton,
  Button,
  Card,
  Alert,
  Link,
  Backdrop,
  CircularProgress,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";

import useHrmStore from "../../../../../stores/hrManagement";
import useAuthStore from "../../../../../stores/auth";
import useNavStore from "../../../../../stores/nav";
import pad from "../../../../../utils/pad";
import { fetchData } from "../../../../../fetchData";
import TagManager from "react-gtm-module";

const HRManagementEventsExtract = () => {
  const data: any = useHrmStore((state) => state.data);
  const snackbar = useNavStore((state) => state.setSnackbar);
  const setData = useHrmStore((state) => state.setState);
  const setEmployee = useHrmStore((state) => state.setEmployee);
  const toggleState = useHrmStore((state) => state.toggleState);
  const month = useHrmStore((state: any) => new Date(state.data.month));
  const company = useAuthStore((state) => state.company);
  const user = useAuthStore((state) => state.user);
  const [state, setState] = useState(false);
  const [error, setError] = useState(false);
  const [backdrop, setBackdrop] = useState(false);
  const [empty, setEmpty] = useState(false);

  const validation = () => {
    const employees = data?.draft?.employees || [];
    let isNull = 0;

    employees.map((row: any) => {
      if (!row.events) isNull++;

      if (row.events && row.events.length) {
        row.events.map((evnt: any) => {
          if (!evnt.value) isNull++;
        });
      }
    });

    if (isNull) setEmpty(true);
  };

  useEffect(() => {
    validation();
    if (!state) setEmpty(false);
  }, [state]);

  const EmptyAlert = () => {
    return (
      <Alert
        severity="error"
        sx={{
          flexDirection: "column",
          alignItems: "center",
          textAlign: "cenrer",
          mb: 3,
        }}
      >
        <Typography textAlign="center" variant="body2">
          Você possui empregados sem eventos vinculados ou eventos vinculados a
          empregados sem valor definido.
        </Typography>
      </Alert>
    );
  };

  const handleSubmit = async () => {
    setState(false);
    setBackdrop(true);

    try {
      await fetchData(`/hrm/fopag/${company.reference}/dispatch/${data?.id}`, {
        method: "GET",
      });

      setData({ ...data, status: "processing" });
      setBackdrop(false);

      TagManager.dataLayer({
        dataLayer: {
          event: `sent_folha`,
          organization: company,
          user,
        },
      });

      snackbar("Folha enviada para processamento com sucesso!", "success");
    } catch (error) {
      setError(true);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        size="small"
        color="warning"
        onClick={() => {
          setState(true);
        }}
        startIcon={<SendIcon />}
        disabled={
          !(
            data?.draft?.employees !== undefined &&
            data?.draft?.employees.length > 0
          )
        }
      >
        Enviar
      </Button>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          ml: "0 !important",
        }}
        open={backdrop}
      >
        {!error && <CircularProgress color="inherit" />}
        {error && (
          <Card>
            <Stack spacing={2}>
              <Typography textAlign="center" variant="h4" color="primary.text">
                Ops!
              </Typography>
              <Typography
                textAlign="center"
                variant="body2"
                color="primary.text"
              >
                Não foi possível enviar sua folha no momento.
                <br />
                Tente novamente mais tarde.
              </Typography>
              <Button
                fullWidth
                variant="contained"
                size="small"
                color="primary"
                onClick={() => setBackdrop(false)}
              >
                Voltar
              </Button>
            </Stack>
          </Card>
        )}
      </Backdrop>
      <Drawer
        anchor="right"
        open={state}
        onClose={() => {
          setState(false);
        }}
      >
        <Box sx={{ p: 3, pt: 2, width: 400 }} width={300} height="100%">
          <Stack height="100%" spacing={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack
                direction="row"
                spacing={1}
                sx={{ flex: 1 }}
                alignItems="center"
              >
                <Typography
                  variant="body1"
                  sx={{ width: 170, wordWrap: "break-word" }}
                >
                  Confirme o envio
                </Typography>
              </Stack>
              <IconButton
                onClick={() => {
                  setState(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Divider sx={{ my: 2 }} />

            <Stack
              direction="row"
              spacing={2}
              position="fixed"
              right={0}
              bottom={0}
              zIndex={1}
              padding={2}
              sx={{
                width: 400,
                backgroundColor: "white",
                boxShadow: 5,
              }}
            >
              <Button
                fullWidth
                variant="outlined"
                size="small"
                color="secondary"
                onClick={() => setState(false)}
              >
                Cancelar
              </Button>
              <Button
                fullWidth
                variant="contained"
                size="small"
                color="warning"
                disabled={empty}
                startIcon={<SendIcon />}
                onClick={handleSubmit}
              >
                Enviar
              </Button>
            </Stack>

            <Box sx={{ pb: 10 }}>
              {empty && <EmptyAlert />}
              {!empty && (
                <Card sx={{ border: "1px solid #CCC", p: 2, mb: 2 }}>
                  <Typography variant="body1" fontWeight={700}>
                    {`${pad(month.getMonth() + 1, 2)}/${month.getFullYear()}`}
                  </Typography>
                  <Typography variant="body2" color="text.primary">
                    {company.name}
                  </Typography>
                </Card>
              )}
              {data?.draft?.employees &&
                data?.draft?.employees.map((row: any) => (
                  <Box key={row.i_empregados}>
                    <Stack
                      direction="row"
                      spacing={1}
                      justifyContent="space-between"
                      sx={{
                        backgroundColor: "#1f252c1f",
                        p: 2,
                        borderRadius: 3,
                      }}
                    >
                      <Link
                        component="button"
                        color="text.primary"
                        onClick={() => {
                          toggleState();
                          setEmployee(row);
                          setState(false);
                        }}
                      >
                        <Typography
                          variant="body2"
                          color="text.primary"
                          fontWeight={500}
                        >
                          {row.nome}
                        </Typography>
                      </Link>
                      <Typography
                        variant="body2"
                        color="text.primary"
                        fontWeight={500}
                      >
                        {row.events && (row.events.length || 0)}
                      </Typography>
                    </Stack>
                    {row.events &&
                      row.events.map((evnt: any) => (
                        <Stack
                          direction="row"
                          spacing={1}
                          justifyContent="space-between"
                          key={evnt.i_eventos}
                          sx={{
                            p: "10px 15px",
                          }}
                        >
                          <Typography
                            variant="body2"
                            color={evnt.value ? "text.primary" : "red"}
                            fontWeight={400}
                          >
                            {evnt.nome}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.primary"
                            fontWeight={500}
                          >
                            {evnt.value}
                          </Typography>
                        </Stack>
                      ))}
                  </Box>
                ))}
            </Box>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default HRManagementEventsExtract;
