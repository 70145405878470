import { useEffect, useState } from "react";
import { Link, Button } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import TaskDetailDrawer from "../Tasks/drawers/taskDetailDrawer";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

import useAuthStore from "../../../stores/auth";
import useTaskStore from "../../../stores/task";
import { fetchData } from "../../../fetchData";
import { Progress } from "../../../components/DataGrid";

function DashboardRRTasks() {
  const user = useAuthStore((state) => state.user);
  const company = useAuthStore((state) => state.company);
  const toggleTaskDrawer = useTaskStore((state) => state.toggleDrawer);
  const download = useTaskStore((state) => state.download);
  const [progress, setProgress] = useState(false);
  const [error, setError] = useState(false);
  const [rows, setRows] = useState([]);

  const columns: GridColDef[] = [
    {
      field: "title",
      sortable: false,
      disableColumnMenu: true,
      headerName: "Atividade",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Link component="button" onClick={() => toggleTaskDrawer(params.row)}>
            {params.row.title}
          </Link>
        );
      },
    },
    {
      field: "attachments_count",
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      headerName: "Docs",
      width: 60,
    },
    {
      field: "id",
      align: "center",
      headerAlign: "center",
      sortable: false,
      width: 120,
      disableColumnMenu: true,
      renderCell: (params: any) => {
        return (
          <Button
            size="small"
            startIcon={<CloudDownloadIcon />}
            onClick={() => download(params.id, "zip")}
            variant="outlined"
            color="success"
          >
            Zip
          </Button>
        );
      },
      renderHeader: (params: any) => {
        return <MoreHorizIcon />;
      },
    },
  ];

  const data = async () => {
    setProgress(true);

    try {
      const response = await fetchData(
        `/organizations/${company.reference}/tasks`,
        {
          method: "GET",
        },
      );

      if (response) {
        setRows(response.filter((row: any, index: number) => index < 6));
      }
    } catch (error) {
      setError(true);
    }

    setProgress(false);
  };

  useEffect(() => {
    setRows([]);
    data();
  }, [company.reference]);

  return (
    <>
      <DataGrid
        rows={rows}
        columns={columns}
        hideFooter={true}
        loading={progress}
        slots={{
          loadingOverlay: Progress,
        }}
        autoHeight
        disableRowSelectionOnClick
      />
      <TaskDetailDrawer />
    </>
  );
}

export default DashboardRRTasks;
