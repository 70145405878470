import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Drawer,
  Stack,
  Divider,
  Typography,
  IconButton,
  TextField,
  Alert,
  Button,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  MenuItem,
  CircularProgress,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { fetchData } from "../../../../fetchData";

import useHrmTerminationStore from "../../../../stores/hrTermination";
import useAuthStore from "../../../../stores/auth";
import DatePickerField from "../../../../components/DatePickerField";

interface FormValues {
  type: string;
  reason: string;
  date: string;
  obs: string;
}

const types = [
  {
    value: "1",
    label: "Trabalhado",
  },
  {
    value: "2",
    label: "Indenizado",
  },
  {
    value: "3",
    label: "Ausência/Dispensa",
  },
];

const reasons = [
  {
    value: "1",
    label: "Demissão COM Justa Causa",
  },
  {
    value: "2",
    label: "Demissão SEM Justa Causa",
  },
  {
    value: "4",
    label: "Pedido Demissão",
  },
  {
    value: "11",
    label: "Antecipação Contrato Experiência pelo Empregado",
  },
  {
    value: "10",
    label: "Antecipação Contrato Experiência pela Empresa",
  },
  {
    value: "22",
    label: "Fim do Contrato de Trabalho",
  },
  {
    value: "44",
    label: "Acordo Entre Ambas as Partes",
  },
  {
    value: "46",
    label: "Falecimento",
  },
];

const HrmEmployeeTerminationDrawer = () => {
  const company = useAuthStore((state) => state.company);
  const state = useHrmTerminationStore((state) => state.drawer);
  const employee: any = useHrmTerminationStore((state) => state.drawerData);
  const toggleDrawer = useHrmTerminationStore((state) => state.toggleDrawer);
  const [activeStep, setActiveStep] = useState(0);
  const [err, setErr] = useState(false);
  const [success, setSuccess] = useState(false);
  const [progress, setProgress] = useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    trigger,
    setError,
    reset,
    setFocus,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      type: "",
      reason: "",
      date: "",
      obs: "",
    },
  });

  const handleNext = async () => {
    if (
      activeStep === 0 &&
      !(await trigger(["type", "reason", "date"], {
        shouldFocus: true,
      }))
    )
      return;

    if (activeStep + 1 < steps.length)
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const ErrorAlert = () => {
    return (
      <Alert
        severity="error"
        sx={{
          flexDirection: "column",
          alignItems: "center",
          textAlign: "cenrer",
        }}
      >
        <Stack alignItems="center" direction="column">
          <Typography textAlign="center" variant="h5">
            Ops!
          </Typography>
          <Typography textAlign="center">
            Não foi possível enviar a solicitação no momento. Tente novamente
            mais tarde.
          </Typography>
          <Button
            variant="contained"
            onClick={() => toggleDrawer()}
            sx={{ mt: 2 }}
          >
            Fechar
          </Button>
        </Stack>
      </Alert>
    );
  };

  const SuccessAlert = () => {
    return (
      <Alert
        severity="success"
        sx={{
          height: "100%",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "cenrer",
        }}
      >
        <Stack alignItems="center" direction="column">
          <Typography textAlign="center" variant="h5">
            Muito bem!
          </Typography>
          <Typography textAlign="center">
            Solicitação enviada com sucesso.
          </Typography>
          <Button
            variant="contained"
            onClick={() => toggleDrawer()}
            sx={{ mt: 2 }}
          >
            Fechar
          </Button>
        </Stack>
      </Alert>
    );
  };

  const Progress = () => {
    return (
      <Alert
        severity="info"
        sx={{
          flexDirection: "column",
          alignItems: "center",
          textAlign: "cenrer",
        }}
        icon={false}
      >
        <Stack spacing={1} alignItems="center" direction="column">
          <CircularProgress />
          <Typography textAlign="center" variant="h6">
            Enviando solicitação
          </Typography>
          <Typography textAlign="center">Aguarde...</Typography>
        </Stack>
      </Alert>
    );
  };

  const steps = [
    {
      label: "Solicitação",
      description: ``,
      outlet: (
        <Stack spacing={1}>
          <TextField
            select
            {...register("type", {
              required: "Selecione o tipo de aviso pévio",
            })}
            label="Tipo de Aviso Prévio"
            variant="filled"
            required
          >
            {types.map((option) => (
              <MenuItem key={option.value} value={option.label}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          {errors?.type && (
            <Alert severity="error">{errors.type.message?.toString()}</Alert>
          )}
          <TextField
            select
            required
            {...register("reason", {
              required: "Selecione o motivo de demissão",
            })}
            label="Motivo de Demissão"
            variant="filled"
          >
            {reasons.map((option) => (
              <MenuItem key={option.value} value={option.label}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          {errors?.reason && (
            <Alert severity="error">{errors.reason.message?.toString()}</Alert>
          )}
          <DatePickerField
            name="date"
            register={register}
            get={getValues}
            set={setValue}
            label="Data do Aviso Prévio"
            variant="filled"
            required
            requiredtxt="Informe a data do aviso prévio."
          />
          {errors?.date && (
            <Alert severity="error">{errors.date.message?.toString()}</Alert>
          )}
          <TextField
            {...register("obs")}
            label="Observações"
            variant="filled"
            multiline
            rows={4}
          />
        </Stack>
      ),
    },
    {
      label: "Envio",
      description: "",
      outlet: <></>,
    },
  ];

  async function onSubmit(data: any) {
    setProgress(true);
    data.name = employee.nome;
    data.reference = company.reference;

    try {
      await fetchData(
        `/hrm/fopag/employee/${employee.i_empregados}/termination`,
        {
          method: "POST",
          body: JSON.stringify(data),
        },
      );
      setSuccess(true);
    } catch (error) {
      setErr(true);
      console.log(error);
    }

    setProgress(false);
  }

  useEffect(() => {
    if (!state) {
      reset();
      setActiveStep(0);
      setSuccess(false);
      setErr(false);
    }
  }, [state]);

  return (
    <Drawer
      anchor="left"
      open={state}
      onClose={() => {
        toggleDrawer();
      }}
    >
      <Box sx={{ p: 3, pt: 2, width: 400 }} width={300} height="100%">
        <Stack height="100%" spacing={2}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Stack
              direction="row"
              spacing={1}
              sx={{ flex: 1 }}
              alignItems="center"
            >
              <Typography
                variant="body1"
                sx={{ width: 170, wordWrap: "break-word" }}
              >
                Solicitar Rescisão
              </Typography>
            </Stack>
            <IconButton
              onClick={() => {
                toggleDrawer();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider sx={{ my: 2 }} />
          <Typography variant="body1" fontWeight={600}>
            {employee.nome}
          </Typography>
          <Divider sx={{ my: 1 }} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel>{step.label}</StepLabel>
                  <StepContent>
                    {step.description && (
                      <Typography variant="body2" sx={{ mb: 2 }}>
                        {step.description}
                      </Typography>
                    )}
                    {step.outlet}
                    {index === 0 && (
                      <Box sx={{ mb: 2 }}>
                        <div>
                          <Button
                            size="small"
                            variant="contained"
                            onClick={handleNext}
                            sx={{ mt: 1, mr: 1 }}
                            type="submit"
                          >
                            Enviar
                          </Button>
                        </div>
                      </Box>
                    )}
                    {success && <SuccessAlert />}
                    {err && <ErrorAlert />}
                    {progress && <Progress />}
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </form>
        </Stack>
      </Box>
    </Drawer>
  );
};

export default HrmEmployeeTerminationDrawer;
