const basic = [
  { name: "nome", label: "Nome" },
  { name: "cpf", label: "CPF" },
  { name: "telefone", label: "Telefone" },
].map((field) => ({ ...field, drawer: null }));

const personal = [
  {
    name: "data_de_nascimento",
    label: "Data de Nascimento",
  },
  {
    name: "grau_de_instrucao",
    label: "Grau de Instrução",
  },
  {
    name: "genero",
    label: "Gênero",
  },
  {
    name: "nacionalidade",
    label: "Nacionalidade",
  },
  {
    name: "raca_cor",
    label: "Raça/Cor",
  },
  {
    name: "estadonascimento",
    label: "Estado de Nascimento",
  },
  {
    name: "municipionascimento",
    label: "Município de Nascimento",
  },
  {
    name: "estado_civil",
    label: "Estado Civil",
  },
  {
    name: "nome_mae",
    label: "Nome da Mãe",
  },
].map((field) => ({ ...field, drawer: "personal" }));

const address = [
  {
    name: "tipo_endereco",
    label: "Tipo de Endereço",
  },
  {
    name: "cep",
    label: "CEP do Endereço",
  },
  {
    name: "endereco",
    label: "Endereço",
  },
  {
    name: "endereco_numero",
    label: "Número do Endereço",
  },
  {
    name: "endereco_bairro",
    label: "Bairro do Endereço",
  },
  {
    name: "endereco_cidade",
    label: "Cidade do Endereço",
  },
  {
    name: "endereco_estado",
    label: "Estado do Endereço",
  },
  {
    name: "endereco_comercial",
    label: "Endereço Comercial?",
  },
  {
    name: "residencia_propria",
    label: "Possui residência própria?",
  },
].map((field) => ({ ...field, drawer: "address" }));

const documents = [
  {
    name: "rg",
    label: "Número do RG",
  },
  {
    name: "titulo_eleitor_numero",
    label: "Número do Título Eleitoral",
  },
  {
    name: "titulo_eleitor_zona",
    label: "Zona do Título Eleitoral",
  },
  {
    name: "titulo_eleitor_secao",
    label: "Seção do Título Eleitoral",
  },
].map((field) => ({ ...field, drawer: "documents" }));

const professional = [
  {
    name: "pis_numero",
    label: "Número do PIS",
  },
  {
    name: "forma_pagamento",
    label: "Forma de Pagamento",
  },
  {
    name: "pagamento_banco_codigo",
    label: "Código do Banco",
  },
  {
    name: "pagamento_banco_agencia",
    label: "Número da Agência",
  },
  {
    name: "pagamento_banco_conta",
    label: "Número da Conta",
  },
  {
    name: "pagamento_banco_conta_digito",
    label: "Dígito da Conta",
  },
  {
    name: "pagamento_banco_chave_pix",
    label: "Chave PIX",
  },
].map((field) => ({ ...field, drawer: "professional" }));

const admission = [
  {
    name: "cargo",
    label: "Cargo",
  },
  {
    name: "valor_salario",
    label: "Valor do Salário",
  },
  {
    name: "data_admissao",
    label: "Data de Admissão",
  },
  {
    name: "tipo_vinculo",
    label: "Tipo de Vínculo",
  },
  {
    name: "horario_trabalho",
    label: "Horário de trabalho",
  },
  {
    name: "possui_adiantamento",
    label: "Possui Adiantamento",
  },
  {
    name: "utilizara_vt",
    label: "Utilizará VT",
  },
  {
    name: "multiplos_vinculos",
    label: "Múltiplos Vínculos",
  },
  {
    name: "categoria",
    label: "Categoria",
  },
  {
    name: "jornada",
    label: "Jornada do Empregado",
  },
  {
    name: "contrato_experiencia",
    label: "Possui Contrato de Experiência?",
    validation: "Possui",
    required: [
      {
        name: "dias_experiencia",
        label: "Quantidade de Dias",
      },
      {
        name: "dias_prorrogacao",
        label: "Dias de Prorrogação",
      },
      {
        name: "motivo_prorrogacao",
        label: "Motivo da Prorrogação",
      },
    ].map((field) => ({ ...field, drawer: "admission" })),
  },
].map((field) => ({ ...field, drawer: "admission" }));

const deficiency = [
  {
    name: "deficiencia",
    label: "Possui Deficiência?",
  },
].map((field) => ({ ...field, drawer: "deficiency" }));

export const personRequiredFields = [
  ...basic,
  ...personal,
  ...address,
  ...documents,
  ...professional,
  ...admission,
  ...deficiency,
];
