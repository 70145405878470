import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, CircularProgress, TextField, Typography } from "@mui/material";

import useNavStore from "../../stores/nav";
import useAuthRecoverStore from "../../stores/authRecover";

const ForgetPasswordStepOne = () => {
  const navigate = useNavigate();
  const snackbar = useNavStore((state) => state.setSnackbar);
  const login = useAuthRecoverStore((state) => state.login);
  const nextStep = useAuthRecoverStore((state) => state.nextStep);
  const setLogin = useAuthRecoverStore((state) => state.setLogin);
  const checkLogin = useAuthRecoverStore((state) => state.checkLogin);
  const [progress, setProgress] = useState(false);

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      onSubmit();
    }
  };

  const onSubmit = async () => {
    if (!login) {
      snackbar("Informe seu nome de usuário para continuar.", "warning");
      return;
    }

    setProgress(true);

    const result: any = await checkLogin();

    if (!result.user.status) {
      snackbar(
        "O nome de usuário informado não foi encontrado ou encontra-se inativo",
        "error",
      );
    } else {
      nextStep();
    }

    setProgress(false);
  };

  return (
    <>
      <Typography
        variant="h6"
        component="div"
        gutterBottom
        sx={{ fontWeight: 300, marginBottom: 1 }}
      >
        Informe o seu nome de usuário
      </Typography>
      <TextField
        label="Nome de usuário"
        variant="outlined"
        value={login}
        onChange={(e) => {
          setLogin(e.target.value);
        }}
        onKeyDown={(e) => {
          handleKeyDown(e);
        }}
        color="primary"
        fullWidth={true}
        margin="dense"
      />
      <Button
        fullWidth={true}
        color="primary"
        variant="contained"
        disabled={progress}
        onClick={() => onSubmit()}
        size="large"
        style={{ marginTop: "8px" }}
      >
        {progress && <CircularProgress size={24} />}
        {!progress && "Continuar"}
      </Button>

      <Button
        fullWidth={true}
        color="primary"
        onClick={() => navigate(`/`)}
        size="large"
        style={{ marginTop: "8px" }}
      >
        Voltar
      </Button>
    </>
  );
};

export default ForgetPasswordStepOne;
