import { useEffect, useState } from "react";

import {
  Box,
  Drawer,
  Stack,
  Divider,
  Typography,
  IconButton,
  Button,
  Card,
  CardContent,
  TextField,
  CardActions,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  List,
  ListItem,
  ListItemButton,
  Link,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DragAndDropFiles from "../../../../components/DragAndDropFiles";

import useAuthStore from "../../../../stores/auth";
import useCloudStore from "../../../../stores/cloud";

const CloudUpload = (props: any) => {
  const company = useAuthStore((state) => state.company);
  const path = useCloudStore((state) => state.path);
  const getData = useCloudStore((state) => state.getData);
  const [state, setState] = useState(false);

  useEffect(() => {
    if (!state) getData();
  }, [state]);

  return (
    <>
      <Button
        variant="contained"
        size="small"
        color="primary"
        startIcon={<CloudUploadIcon />}
        onClick={() => {
          setState(true);
        }}
      >
        Enviar
      </Button>
      <Drawer
        anchor="right"
        open={state}
        onClose={() => {
          setState(false);
        }}
      >
        <Box sx={{ p: 3, pt: 2, width: 400 }} width={300} height="100%">
          <Stack height="100%" spacing={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack
                direction="row"
                spacing={1}
                sx={{ flex: 1 }}
                alignItems="center"
              >
                <Typography
                  variant="body1"
                  sx={{ width: 170, wordWrap: "break-word" }}
                >
                  Enviar arquivo
                </Typography>
              </Stack>
              <IconButton
                onClick={() => {
                  setState(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Divider sx={{ my: 2 }} />
            <DragAndDropFiles
              baseUrl={`cloud/${company._id}?path=${path}`}
              allowedFiles=".pdf,.jpeg,.jpg,.gif,.png,.zip,.doc,.docx,.xls,.xlsx"
            />
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default CloudUpload;
