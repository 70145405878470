import { useState, useEffect } from "react";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import DataGrid from "../../../../../components/DataGrid";

import AddIcon from "@mui/icons-material/Add";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import BackspaceIcon from "@mui/icons-material/Backspace";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import SearchIcon from "@mui/icons-material/Search";

import { fetchDominioData } from "../../../../../fetchDominioData";
import useAuthStore from "../../../../../stores/auth";
import useHrmStore from "../../../../../stores/hrManagement";
import useNavStore from "../../../../../stores/nav";

function HRManagementEventsDetailAddEmployee({ filter }: { filter: string }) {
  const closeModal = useNavStore((state) => state.closeModal);
  const company = useAuthStore((state) => state.company);
  const addEmployee = useHrmStore((state) => state.addEmployee);
  const [progress, setProgress] = useState(true);
  const [isFiltering, setIsFiltering] = useState<any>({
    state: false,
    value: "",
  });
  const [error, setError] = useState(false);
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const handleFilter = () => {
    setRowCount(0);
    setRows([]);
    data(5, 0);
  };

  useEffect(() => {
    handleFilter();
  }, [isFiltering.state]);

  useEffect(() => {
    if (!isFiltering.value) {
      setIsFiltering({ value: "", state: false });
    }
  }, [isFiltering.value]);

  const data = async (limit: number, skip: number) => {
    setProgress(true);

    try {
      const response = await fetchDominioData(
        `/empresa/${company.reference}/${filter}?limit=${limit}&start=${
          skip + 1
        }${isFiltering ? `&like=nome|${isFiltering.value}` : ""}`,
        {
          method: "GET",
        },
      );

      if (response) {
        setRowCount(response.count);
        setRows(response.rows);
      }
    } catch (error) {
      console.log(error);
      setError(true);
    }

    setProgress(false);
  };

  const columns: GridColDef[] = [
    {
      field: "nome",
      headerName: "Nome",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Link
            component="button"
            onClick={() => {
              addEmployee(params.row);
              closeModal();
            }}
          >
            {params.row.nome}
          </Link>
        );
      },
    },
    {
      field: "id",
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      width: 50,
      renderCell: (params: any) => {
        return (
          <IconButton
            size="small"
            color="primary"
            onClick={() => {
              addEmployee(params.row);
              closeModal();
            }}
          >
            <ArrowCircleRightIcon />
          </IconButton>
        );
      },
      renderHeader: (params: any) => {
        return <MoreHorizIcon />;
      },
    },
  ];

  return (
    <>
      <FormControl fullWidth sx={{ mb: 1 }} variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">
          Buscar por nome
        </InputLabel>
        <OutlinedInput
          type="text"
          value={isFiltering.value}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              setIsFiltering({ ...isFiltering, state: true });
            }
          }}
          onChange={(e) => {
            setIsFiltering({ ...isFiltering, value: e.target.value });
          }}
          endAdornment={
            <InputAdornment position="end">
              {!isFiltering.value && (
                <IconButton
                  aria-label="Buscar por nome"
                  edge="end"
                  onClick={() => {
                    setIsFiltering({ ...isFiltering, state: true });
                  }}
                >
                  <SearchIcon />
                </IconButton>
              )}
              {isFiltering.value && (
                <IconButton
                  aria-label="Limpar filtro"
                  edge="end"
                  onClick={() => {
                    setIsFiltering({ value: "", state: false });
                  }}
                >
                  <BackspaceIcon />
                </IconButton>
              )}
            </InputAdornment>
          }
          label="Buscar por nome"
        />
      </FormControl>
      <DataGrid
        rows={rows}
        data={data}
        loading={progress}
        columns={columns}
        rowCount={rowCount}
        paginationModel={{ page: 0, pageSize: 5 }}
      />
    </>
  );
}

export default HRManagementEventsDetailAddEmployee;
