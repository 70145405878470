import { Button, CircularProgress, Stack, TextField } from "@mui/material";
import { useState } from "react";
import useNavStore from "../../../../stores/nav";
import useCloudStore from "../../../../stores/cloud";

const CloudNewFolder = () => {
  const [state, setState] = useState("");
  const [progress, setProgress] = useState(false);
  const closeModal = useNavStore((state) => state.closeModal);
  const snackbar = useNavStore((state) => state.setSnackbar);
  const reloadData = useCloudStore((state) => state.getData);
  const createFolder = useCloudStore((state) => state.createFolder);

  const onSubmit = async () => {
    if (!state) return;
    setProgress(true);

    if (await createFolder(state.trim().replace(/[\\/\\:*?"<>|]/g, "_"))) {
      reloadData();
      closeModal();
      snackbar("Pasta criada com sucesso!", "success");
    } else {
      snackbar("Ops, não foi possível criar a pasta no momento.", "error");
    }

    setProgress(false);
  };

  return (
    <>
      <TextField
        label="Nome da pasta"
        fullWidth
        variant="filled"
        onChange={(e: any) => setState(e.target.value)}
      />
      <Stack
        spacing={2}
        direction="row"
        justifyContent="flex-end"
        sx={{ mt: 2 }}
      >
        <Button
          variant="outlined"
          size="small"
          color="secondary"
          onClick={() => closeModal()}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={() => onSubmit()}
        >
          {!progress && "Criar"}
          {progress && <CircularProgress color="inherit" size={22} />}
        </Button>
      </Stack>
    </>
  );
};

export default CloudNewFolder;
