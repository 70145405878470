import { create } from "zustand";
import { fetchData } from "../fetchData";

const localState = localStorage.getItem(
  `${process.env.REACT_APP_KEYNAME}HrmEmployeeStore`,
);

type dataState = {
  _id: string;
  status: "done" | "editing" | "processing" | null;
  reference: number;
  draft?: object;
};

type State = {
  isSaving: boolean;
  isEditing: boolean;
  data?: dataState;
  draft: object;
  drawer: boolean;
  drawerData: object;
  dependentDrawer: boolean;
  currentDependent: number | undefined;
  drawersRef: object[];
};

type Actions = {
  setState: (data: dataState) => void;
  setReference: (reference: number) => void;
  setDraft: (data: object) => void;
  toggleState: () => void;
  toggleDrawer: (data?: object) => void;
  toggleDependentDrawer: () => void;
  saveDraft: () => void;
  changeDraft: (event: any) => void;
  reset: () => void;
  addDependent: (obj: object) => void;
  removeDependent: () => void;
  setCurrentDependent: (index: number) => void;
  addDrawersRef: (obj: object) => void;
};

const dataInitialState: dataState = {
  _id: "",
  status: "editing",
  reference: 0,
  draft: {},
};

const initialState: State = {
  isSaving: false,
  isEditing: false,
  data: localState ? JSON.parse(localState) : dataInitialState,
  draft: {},
  drawer: false,
  drawerData: {},
  dependentDrawer: false,
  currentDependent: undefined,
  drawersRef: [],
};

const useHrmEmployeeStore = create<State & Actions>((set, get) => ({
  ...initialState,

  changeDraft: (event: any) => {
    const data: any = get().data;
    const draft = data.draft;

    draft[event.target.name] = event.target.value;
    set(() => ({ data: { ...data, draft } }));
  },

  addDependent: (obj: object) => {
    const data: any = get().data;
    const draft = data.draft;
    const current = get().currentDependent;
    const dependentes = draft.dependentes || [];

    if (current === undefined) {
      draft.dependentes = [...dependentes, ...[obj]];
    } else {
      draft.dependentes = draft.dependentes.map((row: any, index: number) => {
        if (index === current) {
          return obj;
        }

        return row;
      });
    }

    set(() => ({
      data: { ...data, draft },
      currentDependent: initialState.currentDependent,
    }));

    get().saveDraft();
    get().toggleDependentDrawer();
  },

  removeDependent: () => {
    const data: any = get().data;
    const draft = data.draft;
    const current = get().currentDependent;

    draft.dependentes = draft.dependentes.filter(
      (row: any, index: number) => index !== current,
    );

    set(() => ({
      data: { ...data, draft },
      currentDependent: initialState.currentDependent,
    }));

    get().saveDraft();
    get().toggleDependentDrawer();
  },

  setState: (data: dataState) =>
    set(() => {
      localStorage.setItem(
        `${process.env.REACT_APP_KEYNAME}HrmEmployeeStore`,
        JSON.stringify(data),
      );

      return { data };
    }),

  saveDraft: async () => {
    let data: any = get().data;
    set(() => ({ isSaving: true }));

    try {
      const response = await fetchData(
        `/hrm/fopag/${data?.reference}/person/drafts${
          data?._id ? `/${data?._id}` : ""
        }`,
        {
          method: data?._id ? "PUT" : "POST",
          body: JSON.stringify(data),
        },
      );

      if (!data?._id) {
        data = { ...data, _id: response._id };
        set(() => ({ data }));
      }

      localStorage.setItem(
        `${process.env.REACT_APP_KEYNAME}HrmEmployeeStore`,
        JSON.stringify(data),
      );

      set(() => ({ isSaving: false }));
    } catch (error) {
      set(() => ({ isSaving: false }));
      return false;
    }
  },

  setDraft: (data: object) => set(() => ({ draft: data })),

  setCurrentDependent: (index: number) =>
    set(() => ({ currentDependent: index })),

  setReference: (reference: number) =>
    set(() => {
      const newData: any = { ...get().data, reference };
      localStorage.setItem(
        `${process.env.REACT_APP_KEYNAME}HrmEmployeeStore`,
        JSON.stringify(newData),
      );

      return { data: newData };
    }),

  toggleState: () => set((state: State) => ({ isEditing: !state.isEditing })),

  toggleDrawer: (data?: object) => {
    set((state: State) => ({
      drawer: !state.drawer,
      drawerData: data ? data : [],
    }));
  },

  toggleDependentDrawer: () => {
    set((state: State) => ({
      dependentDrawer: !state.dependentDrawer,
      currentDependent: state.dependentDrawer
        ? undefined
        : state.currentDependent,
    }));
  },

  reset: () =>
    set(() => {
      return {
        ...initialState,
        data: dataInitialState,
        drawersRef: get().drawersRef,
      };
    }),

  addDrawersRef: (obj: object) =>
    set(() => {
      let refs = get().drawersRef;

      if (refs.length === 6) {
        set(() => ({ drawersRef: initialState.drawersRef }));
        refs = initialState.drawersRef;
      }

      if (refs.length <= 5) {
        return { drawersRef: [...refs, ...[obj]] };
      }
      return {};
    }),
}));

export default useHrmEmployeeStore;
