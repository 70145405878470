import { useEffect } from "react";
import { AuthRoutes } from "./auth.routes";
import { ClientRoutes } from "./client.routes";
import useAuthStore from "../stores/auth";

const Routes = () => {
  const tokenVerify = useAuthStore((state) => state.tokenVerify);
  const user = useAuthStore((state) => state.user);

  useEffect(() => {
    tokenVerify(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user) {
    return <AuthRoutes />;
  }

  return <ClientRoutes />;
};

export default Routes;
