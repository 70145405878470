import { useEffect, useRef, Component, createRef } from "react";

import {
  Stack,
  Typography,
  Button,
  Unstable_Grid2 as Grid,
  Avatar,
  LinearProgress,
  LinearProgressProps,
  Box,
} from "@mui/material";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { v4 as uuidv4 } from "uuid";

import api from "../../services/api";

import { humanFileSize } from "../../utils";

import useAuthStore from "../../stores/auth";
import TagManager from "react-gtm-module";

interface Props {
  baseUrl: string;
  multiple: boolean;
  allowedFiles: string;
  callback: (response: any) => void;
}

interface State {
  isUploading: boolean;
  dragActive: boolean;
  uploadedFiles: Array<any>;
  inputRef: any;
}

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number },
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

class DragAndDropFiles extends Component<Props, State> {
  static defaultProps: Partial<Props> = {
    multiple: true,
  };

  constructor(props: Props) {
    super(props);
    // Inicialize os estados, se necessário
    this.state = {
      isUploading: false,
      dragActive: false,
      uploadedFiles: [],
      inputRef: createRef(),
    };
  }

  handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      this.setState({ dragActive: true });
    } else if (e.type === "dragleave") {
      this.setState({ dragActive: false });
    }
  };

  handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ dragActive: false });

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      this.handleUpload(e.dataTransfer.files);
    }
  };

  handleChange = (e: any) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      this.handleUpload(e.target.files);
    }
  };

  onButtonClick = () => {
    if (this.state.inputRef.current != null) {
      this.state.inputRef.current.click();
    }
  };

  handleUpload = (files: any) => {
    const organization = useAuthStore.getState().company;
    const user = useAuthStore.getState().user;
    const newFiles = Array.from(files).map((file: any) => ({
      file,
      id: uuidv4(),
      name: file.name.normalize("NFD").replace(/[^a-zA-Z0-9.\s]/g, ""),
      size: humanFileSize(file.size),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    this.setState({ uploadedFiles: this.state.uploadedFiles.concat(newFiles) });

    TagManager.dataLayer({
      dataLayer: {
        event: `sent_files`,
        organization,
        user,
      },
    });

    newFiles.forEach(this.processUpload);
  };

  updateFile = (id: string, data: any) => {
    this.setState({
      uploadedFiles: this.state.uploadedFiles.map((uploadedFile: any) => {
        return id === uploadedFile.id
          ? { ...uploadedFile, ...data }
          : uploadedFile;
      }),
    });
  };

  processUpload = (uploadedFile: any) => {
    const data = new FormData();

    data.append("file", uploadedFile.file, uploadedFile.name);

    if (!this.props.multiple) {
      this.setState({ isUploading: true });
    }

    api()
      .post(this.props.baseUrl, data, {
        onUploadProgress: (e) => {
          const progress = Math.round((e.loaded * 100) / e.total);

          this.updateFile(uploadedFile.id, { progress });
        },
      })
      .then((response) => {
        if (this.props.callback) {
          this.props.callback(response);
        }
        this.updateFile(uploadedFile.id, {
          uploaded: true,
        });

        if (!this.props.multiple) {
          this.setState({ isUploading: false });
        }
      })
      .catch(() => {
        this.updateFile(uploadedFile.id, {
          error: true,
        });
      });
  };

  render() {
    return (
      <>
        <form
          id="form-file-upload"
          onDragEnter={
            this.props.multiple
              ? this.handleDrag
              : () => {
                  return false;
                }
          }
          onDragLeave={
            this.props.multiple
              ? this.handleDrag
              : () => {
                  return false;
                }
          }
          onDragOver={
            this.props.multiple
              ? this.handleDrag
              : () => {
                  return false;
                }
          }
          onDrop={
            this.props.multiple
              ? this.handleDrop
              : () => {
                  return false;
                }
          }
          onSubmit={(e) => e.preventDefault()}
        >
          <input
            ref={this.state.inputRef}
            hidden
            multiple={this.props.multiple}
            accept={this.props.allowedFiles}
            type="file"
            onChange={this.handleChange}
          />
          <label>
            <Stack
              spacing={1}
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{
                p: 3,
                border: "1px dashed #000",
                borderColor: "primary.main",
                borderRadius: 1,
                backgroundColor: this.state.dragActive
                  ? "#009aff2b"
                  : "transparent",
              }}
            >
              <CloudUploadIcon sx={{ fontSize: "5em" }} color="primary" />
              {this.props.multiple && (
                <Typography textAlign="center">
                  {this.state.dragActive
                    ? "Agora solte o(s) arquivo(s)"
                    : "Arraste e solte arquivos aqui ou"}
                </Typography>
              )}
              <Button
                variant="contained"
                size="small"
                onClick={this.onButtonClick}
                disabled={
                  this.props.multiple
                    ? this.state.dragActive
                    : this.state.isUploading
                }
              >
                Selecione um arquivo
              </Button>
            </Stack>
          </label>
        </form>
        <Grid container sx={{ mt: 2 }} spacing={2}>
          {this.state.uploadedFiles &&
            this.state.uploadedFiles.map((file: any) => (
              <Grid key={file.id} xs={12}>
                <Stack
                  direction="row"
                  spacing={2}
                  alignContent="center"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    p: 1,
                    borderRadius: 2,
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: file.uploaded
                      ? "secondary.main"
                      : "primary.main",
                  }}
                >
                  <Avatar
                    sx={{
                      fontSize: 14,
                      fontWeight: "bold",
                      bgcolor: "primary.light",
                    }}
                  >
                    {file.name.split(".").pop().toLocaleUpperCase()}
                  </Avatar>
                  <Box sx={{ width: "100%" }}>
                    <Typography
                      sx={{
                        width: "100%",
                        fontWeight: "bold",
                        wordWrap: "break-word",
                      }}
                      component="div"
                      variant="body2"
                    >
                      {file.name}
                    </Typography>
                    <Typography component="div" variant="caption">
                      {file.size}
                    </Typography>
                    {!file.uploaded ? (
                      <Box sx={{ width: "100%" }}>
                        <LinearProgressWithLabel
                          value={file.uploaded ? 100 : file.progress}
                        />
                      </Box>
                    ) : (
                      <Typography
                        color="success"
                        component="div"
                        variant="caption"
                      >
                        Envio concluído.
                      </Typography>
                    )}
                  </Box>
                </Stack>
              </Grid>
            ))}
        </Grid>
      </>
    );
  }
}

export default DragAndDropFiles;
