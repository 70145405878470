export const profiles = [
  { key: "ADMIN", value: "Administrativo" },
  { key: "FINANC", value: "Financeiro" },
  { key: "HR", value: "Recursos Humanos" },
  { key: "FTAX", value: "Fiscal e Tributário" },
];

export const profileSwitch = (profile: string, reverse = false) => {
  let output;

  switch (profile) {
    case "ADMIN":
    case "Administrativo":
      output = reverse ? "ADMIN" : "Administrativo";
      break;
    case "FINANC":
    case "Financeiro":
      output = reverse ? "FINANC" : "Financeiro";
      break;
    case "HR":
    case "Recursos Humanos":
      output = reverse ? "HR" : "Recursos Humanos";
      break;
    case "FTAX":
    case "Fiscal e Tributário":
      output = reverse ? "FTAX" : "Fiscal e Tributário";
      break;
  }

  return output;
};
