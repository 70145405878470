import { create } from "zustand";
import { fetchData } from "../fetchData";
import { SystemSecurityUpdateGoodOutlined } from "@mui/icons-material";

type TicketState = {
  type: any;
  subject: string;
  until_date: Date | undefined;
  description: string;
  reference?: number;
  uploads?: Array<string>;
};

type State = {
  token: string | undefined;
  id: number;
  step: number;
  ticket: TicketState;
  drawer: boolean;
};

type Actions = {
  setState: (state: TicketState) => void;
  setToken: (token: string) => void;
  toggleDrawer: () => void;
  create: () => Promise<boolean>;
  reset: () => void;
  prevStep: () => void;
  nextStep: () => void;
  setStep: (step: number) => void;
};

const initialState: State = {
  token: undefined,
  id: 0,
  step: 0,
  ticket: {
    type: "",
    subject: "",
    description: "",
    until_date: undefined,
    reference: 0,
  },
  drawer: false,
};

const useTicketStore = create<State & Actions>((set, get) => ({
  ...initialState,

  setState: (state: TicketState) => set(() => ({ ticket: state })),

  setToken: (token: string) => set(() => ({ token })),

  prevStep: () =>
    set(() => ({
      step: get().step - 1,
    })),

  nextStep: () =>
    set(() => ({
      step: get().step + 1,
    })),

  setStep: (step: number) =>
    set(() => ({
      step,
    })),

  toggleDrawer: () => set((state: State) => ({ drawer: !state.drawer })),

  create: async () => {
    try {
      const token = get().token;
      const payload = get().ticket;

      if (token) {
        payload.uploads = [token];
      }

      const result = await fetchData(`/zendesk/ticket`, {
        method: "POST",
        body: JSON.stringify(payload),
      });

      set(() => ({ id: result.ticket.id }));

      return true;
    } catch (error) {
      return false;
    }
  },

  reset: () =>
    set(() => ({
      ...initialState,
    })),
}));

export default useTicketStore;
