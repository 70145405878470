import { useState, useEffect } from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  Divider,
  IconButton,
  Link,
  Stack,
  Typography,
  CircularProgress,
} from "@mui/material";
import { DataGrid, GridColDef, ptBR } from "@mui/x-data-grid";

import AddIcon from "@mui/icons-material/Add";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import BackspaceIcon from "@mui/icons-material/Backspace";
import CopyAllIcon from "@mui/icons-material/CopyAll";

import HRManagementEventsDetailAddEvent from "./modals/AddEvent";
import HRManagementEventsDetailAddEmployee from "./modals/AddEmployee";

import useHrmStore from "../../../../stores/hrManagement";
import useNavStore from "../../../../stores/nav";

function HRManagementEventsDetailList() {
  const data = useHrmStore((state: any) => state.data);
  const setModal = useNavStore((state: any) => state.setModal);
  const toggleState = useHrmStore((state: any) => state.toggleState);
  const setEmployee = useHrmStore((state: any) => state.setEmployee);
  const removeEmployee = useHrmStore((state: any) => state.removeEmployee);
  const snackbar = useNavStore((state: any) => state.setSnackbar);
  const copyFromLastMonth = useHrmStore(
    (state: any) => state.copyFromLastMonth,
  );
  const [isCopying, setIsCopying] = useState(false);

  const columns: GridColDef[] = [
    {
      field: "i_empregados",
      sortable: false,
      headerName: "Número",
      align: "center",
      headerAlign: "center",
      renderCell: (params: any) => {
        return params.row.i_empregados;
      },
    },
    {
      field: "nome",
      sortable: false,
      headerName: "Nome",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Link
            component="button"
            onClick={() => {
              toggleState();
              setEmployee(params.row);
            }}
          >
            {params.row.nome}
          </Link>
        );
      },
    },
    {
      field: "events",
      sortable: false,
      align: "center",
      headerAlign: "center",
      headerName: "Eventos",
      renderCell: (params: any) => {
        return params.row?.events?.length || 0;
      },
    },
    {
      field: "id",
      align: "center",
      headerAlign: "center",
      sortable: false,
      width: data.status === "editing" ? 150 : 0,
      disableColumnMenu: true,
      renderCell: (params: any) => {
        if (data.status !== "editing") return "";

        return (
          <>
            <Button
              startIcon={<AddIcon />}
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => {
                setModal(
                  "Novo Evento",
                  <HRManagementEventsDetailAddEvent />,
                  `${params.row.nome}`,
                );
                setEmployee(params.row);
              }}
            >
              Evento
            </Button>
            <IconButton
              sx={{ ml: 1 }}
              size="small"
              color="error"
              onClick={() => removeEmployee(params.row)}
            >
              <BackspaceIcon />
            </IconButton>
          </>
        );
      },
      renderHeader: (params: any) => {
        if (data.status !== "editing") return "";
        return <MoreHorizIcon />;
      },
    },
  ];

  const handleCopyFromLastMonth = async () => {
    setIsCopying(true);
    if (await copyFromLastMonth()) {
      snackbar("Folha copiada com sucesso!", "success");
    } else {
      snackbar(
        "Ops! Não foi possível copiar do mês passado no momento.",
        "error",
      );
    }
    setIsCopying(false);
  };

  const EmptyAlert = () => {
    return (
      <Alert
        severity="info"
        sx={{
          flexDirection: "column",
          alignItems: "center",
          textAlign: "cenrer",
        }}
      >
        <Stack spacing={2} alignItems="center" direction="column">
          <Typography>
            Nenhum empregado/contribuinte vinculado até o momento.
          </Typography>
          <Button
            variant="outlined"
            color="info"
            startIcon={!isCopying ? <CopyAllIcon /> : false}
            onClick={handleCopyFromLastMonth}
            disabled={isCopying}
          >
            {isCopying && <CircularProgress size={20} />}
            {!isCopying && "Copiar do último mês"}
          </Button>
          <Divider>OU</Divider>
          <ButtonGroup variant="outlined" color="info">
            <Button
              startIcon={<AddIcon />}
              onClick={() =>
                setModal(
                  "Selecione o empregado",
                  <HRManagementEventsDetailAddEmployee filter="empregado" />,
                )
              }
            >
              Empregado
            </Button>
            <Button
              startIcon={<AddIcon />}
              onClick={() =>
                setModal(
                  "Selecione o contribuinte",
                  <HRManagementEventsDetailAddEmployee filter="contribuinte" />,
                )
              }
            >
              Contribuinte
            </Button>
          </ButtonGroup>
        </Stack>
      </Alert>
    );
  };

  return (
    <Card>
      <Typography variant="h5">Pessoas/Eventos</Typography>
      <Divider sx={{ my: 3 }} />

      {!data.draft?.employees && <EmptyAlert />}
      {data.draft?.employees && data.draft.employees.length === 0 && (
        <EmptyAlert />
      )}

      {data.draft?.employees && data.draft.employees.length > 0 && (
        <DataGrid
          getRowId={(row) => row.i_empregados}
          rows={data.draft?.employees}
          columns={columns}
          autoHeight
          disableRowSelectionOnClick
          disableColumnMenu
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        />
      )}
    </Card>
  );
}

export default HRManagementEventsDetailList;
