import { useState, useEffect } from "react";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import DataGrid from "../../../../../components/DataGrid";

import AddIcon from "@mui/icons-material/Add";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import BackspaceIcon from "@mui/icons-material/Backspace";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import SearchIcon from "@mui/icons-material/Search";

import { fetchDominioData } from "../../../../../fetchDominioData";
import useAuthStore from "../../../../../stores/auth";
import useHrmStore from "../../../../../stores/hrManagement";
import useNavStore from "../../../../../stores/nav";

function HRManagementEventsDetailAddEvent() {
  const modal = useNavStore((state) => state.modal.state);
  const closeModal = useNavStore((state) => state.closeModal);
  const company = useAuthStore((state) => state.company);
  const addEvent = useHrmStore((state) => state.addEvent);
  const [progress, setProgress] = useState(true);
  const [inputValue, setInputValue] = useState<string>("");
  const [error, setError] = useState(false);
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const handleFilter = () => {
    setRowCount(0);
    setRows([]);
    data(5, 0);
  };

  const handleCustomEvent = () => {
    const i_eventos = Math.floor(Math.random() * 900000 + 100000);
    addEvent({
      nome: inputValue.toLocaleUpperCase(),
      i_eventos,
      codi_emp: company.reference,
      tipo_info: "CUSTOM",
    });
    closeModal();
  };

  const data = async (limit: number, skip: number) => {
    setProgress(true);

    try {
      const response = await fetchDominioData(
        `/empresa/${company.reference}/eventos?limit=${limit}&start=${
          skip + 1
        }${inputValue ? `&like=NOME|${inputValue}` : ""}`,
        {
          method: "GET",
        },
      );

      if (response) {
        setRowCount(response.count);
        setRows(
          response.rows.map((row: any) => ({
            nome: row.NOME,
            i_eventos: row.I_EVENTOS,
            codi_emp: row.CODI_EMP,
            tipo_info: row.TIPO_INF,
          })),
        );
      }
    } catch (error) {
      console.log(error);
      setError(true);
    }

    setProgress(false);
  };

  useEffect(() => {
    if (!progress) {
      const timer = setTimeout(() => handleFilter(), 500);

      return () => clearTimeout(timer);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  useEffect(() => {
    if (!modal) {
      setProgress(true);
      setInputValue("");
      setRowCount(0);
      setRows([]);
    }
  }, [modal]);

  const columns: GridColDef[] = [
    {
      field: "nome",
      headerName: "Nome",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Link
            component="button"
            onClick={() => {
              addEvent(params.row);
              closeModal();
            }}
          >
            {params.row.nome}
          </Link>
        );
      },
    },
    {
      field: "id",
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      width: 50,
      renderCell: (params: any) => {
        return (
          <IconButton
            size="small"
            color="primary"
            onClick={() => {
              addEvent(params.row);
              closeModal();
            }}
          >
            <ArrowCircleRightIcon />
          </IconButton>
        );
      },
      renderHeader: (params: any) => {
        return <MoreHorizIcon />;
      },
    },
  ];

  const AddCustomEventOverlay = () => {
    return (
      <>
        <Typography variant="body2" color="text.secondary">
          Nenhum evento encontrado.
        </Typography>
        {inputValue && (
          <>
            <Typography variant="body1">
              Deseja incluir como evento customizado?
            </Typography>
            <Button
              size="medium"
              variant="contained"
              color="primary"
              sx={{ mt: 1 }}
              onClick={() => handleCustomEvent()}
            >
              Incluir evento customizado
            </Button>
          </>
        )}
      </>
    );
  };

  return (
    <>
      <FormControl fullWidth sx={{ mb: 1 }} variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">
          Buscar por nome
        </InputLabel>
        <OutlinedInput
          type="text"
          value={inputValue}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              handleFilter();
            }
          }}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          endAdornment={
            <InputAdornment position="end">
              {inputValue && (
                <IconButton
                  aria-label="Limpar filtro"
                  edge="end"
                  onClick={() => setInputValue("")}
                >
                  <BackspaceIcon />
                </IconButton>
              )}
            </InputAdornment>
          }
          label="Buscar por nome"
        />
      </FormControl>
      <DataGrid
        rows={rows}
        data={data}
        loading={progress}
        columns={columns}
        rowCount={rowCount}
        paginationModel={{ page: 0, pageSize: 5 }}
        noRowsOverlay={<AddCustomEventOverlay />}
      />
    </>
  );
}

export default HRManagementEventsDetailAddEvent;
