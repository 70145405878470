import { create } from "zustand";
import { fetchData } from "../fetchData";

const localState = localStorage.getItem(
  `${process.env.REACT_APP_KEYNAME}HrmStore`,
);

type draftState = {
  employees: object[];
};

type dataState = {
  id: string;
  status: "done" | "editing" | "processing" | null;
  reference: number;
  draft?: draftState;
  month: null | Date;
};

type State = {
  isSaving: boolean;
  isEditing: boolean;
  data?: dataState;
  employee: object;
  drawer: boolean;
  drawerData: object;
};

type Actions = {
  setState: (data: dataState) => void;
  setEmployee: (data: object) => void;
  addEmployee: (employee: object) => void;
  removeEmployee: (employee: object) => void;
  addEvent: (event: object) => void;
  removeEvent: (event: object) => void;
  updateEvent: (event: object, value: string | number) => void;
  toggleState: () => void;
  toggleDrawer: (data?: object) => void;
  saveDraft: () => void;
  export: () => Array<any>;
  copyFromLastMonth: () => Promise<boolean>;
};

const draftInitialState: draftState = {
  employees: [],
};

const dataInitialState: dataState = {
  id: "",
  status: null,
  reference: 0,
  draft: draftInitialState,
  month: null,
};

const initialState: State = {
  isSaving: false,
  isEditing: false,
  data: localState ? JSON.parse(localState) : dataInitialState,
  employee: {},
  drawer: false,
  drawerData: {},
};

const useHrmStore = create<State & Actions>((set, get) => ({
  ...initialState,

  setState: (data: dataState) =>
    set(() => {
      localStorage.setItem(
        `${process.env.REACT_APP_KEYNAME}HrmStore`,
        JSON.stringify(data),
      );

      return { data };
    }),

  saveDraft: async () => {
    const data = get().data;
    set(() => ({ isSaving: true }));

    try {
      await fetchData(`/hrm/fopag/${data?.id}`, {
        method: "PUT",
        body: JSON.stringify(data),
      });

      set(() => ({ isSaving: false }));
    } catch (error) {
      return false;
    }
  },

  setEmployee: (data: object) => set(() => ({ employee: data })),

  addEmployee: (employee: any) => {
    const data: dataState = get().data || dataInitialState;
    const draft: draftState = data?.draft || draftInitialState;
    const employees: object[] = draft?.employees || [];
    const newData = {
      ...data,
      draft: { ...draft, employees: [...employees, employee] },
    };
    const exists = employees.filter(
      (row: any) => row.i_empregados === employee.i_empregados,
    );

    if (exists.length) return;

    set(() => ({ isEditing: true, employee }));
    get().setState(newData);
    get().saveDraft();
  },

  removeEmployee: (employee: any) => {
    const data: dataState = get().data || dataInitialState;
    const draft: draftState = data?.draft || draftInitialState;
    const employees: object[] =
      draft?.employees.filter(
        (row: any) => row.i_empregados !== employee.i_empregados,
      ) || [];
    const newData = {
      ...data,
      draft: { ...draft, employees },
    };

    set(() => ({ isEditing: false, employee: {} }));
    get().setState(newData);
    get().saveDraft();
  },

  addEvent: (event: any) => {
    const data: dataState = get().data || dataInitialState;
    const draft: draftState = data?.draft || draftInitialState;
    const employee: any = get().employee;
    const employees: object[] = draft?.employees || [];
    const newEmployees = employees.map((row: any) => {
      const events = row.events || [];
      const exists = events.filter(
        (row: any) => row.i_eventos === event.i_eventos,
      );

      if (row.i_empregados === employee.i_empregados && !exists.length) {
        row.events = [...events, event];
      }

      return row;
    });

    const newData = {
      ...data,
      draft: { ...draft, employees: newEmployees },
    };

    set(() => ({ isEditing: true }));
    get().setState(newData);
    get().saveDraft();
  },

  removeEvent: (event: any) => {
    const data: dataState = get().data || dataInitialState;
    const draft: draftState = data?.draft || draftInitialState;
    const employee: any = get().employee;
    const employees: object[] = draft?.employees || [];
    const newEmployees = employees.map((row: any) => {
      const events = row.events || [];

      if (row.i_empregados === employee.i_empregados) {
        row.events = events.filter(
          (row: any) => row.i_eventos !== event.i_eventos,
        );
      }

      return row;
    });

    const newData = {
      ...data,
      draft: { ...draft, employees: newEmployees },
    };

    get().setState(newData);
    get().saveDraft();
  },

  updateEvent: (event: any, value: string | number) => {
    const data: dataState = get().data || dataInitialState;
    const draft: draftState = data?.draft || draftInitialState;
    const employee: any = get().employee;
    const employees: object[] = draft?.employees || [];
    const newEmployees = employees.map((row: any) => {
      const events = row.events || [];

      if (row.i_empregados === employee.i_empregados) {
        row.events = events.map((row: any) => {
          if (row.i_eventos === event.i_eventos) {
            row.value = value;
          }

          return row;
        });
      }

      return row;
    });

    const newData = {
      ...data,
      draft: { ...draft, employees: newEmployees },
    };

    get().setState(newData);
    get().saveDraft();
  },

  toggleState: () => set((state: State) => ({ isEditing: !state.isEditing })),

  export: () => {
    const employees = get().data?.draft?.employees || [];
    let rows: Array<any> = [];

    employees.map((row: any) => {
      let eventos: any = [];

      if (row.events) {
        eventos = row.events.map((evnt: any) => ({
          codigo_empregado: row.i_empregados,
          nome: row.nome,
          evento: evnt.nome,
          valor: evnt.value,
        }));
      } else {
        eventos = [
          {
            codigo_empregado: row.i_empregados,
            nome: row.nome,
            evento: "",
            valor: "",
          },
        ];
      }

      rows = [...rows, ...eventos];
    });

    return rows;
  },

  toggleDrawer: (data?: object) => {
    set((state: State) => ({
      drawer: !state.drawer,
      drawerData: data ? data : [],
    }));
  },

  copyFromLastMonth: async () => {
    const data: dataState = get().data || dataInitialState;

    try {
      const reference = data.reference;

      const lastOne = await fetchData(
        `/hrm/fopag/${reference}?limit=1&skip=1`,
        {
          method: "GET",
        },
      );

      if (lastOne.count) {
        const newData = {
          ...data,
          draft: lastOne.rows[0].draft,
        };

        get().setState(newData);
        get().saveDraft();

        return true;
      }

      return false;
    } catch (error) {
      return false;
    }
  },
}));

export default useHrmStore;
