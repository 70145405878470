import { useEffect, useState } from "react";
import {
  Card,
  Button,
  Tabs,
  Tab,
  Stack,
  Unstable_Grid2 as Grid,
  Typography,
  Skeleton,
  Alert,
  LinearProgress,
  Link,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Progress } from "../../../components/DataGrid";

import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import useNavStore from "../../../stores/nav";
import useAuthStore from "../../../stores/auth";
import useTaskStore from "../../../stores/task";

import { fetchData } from "../../../fetchData";
import { fetchRRData } from "../../../fetchRRData";

import TaskDetailDrawer from "./drawers/taskDetailDrawer";

function Tasks() {
  const setHeaderTitle = useNavStore((state: any) => state.setHeader);
  const company = useAuthStore((state) => state.company);
  const toggleTaskDrawer = useTaskStore((state) => state.toggleDrawer);
  const download = useTaskStore((state) => state.download);
  const [initProgress, setInitProgress] = useState(true);
  const [progress, setProgress] = useState(false);
  const [page, setPage] = useState(1);
  const [error, setError] = useState(false);
  const [rows, setRows] = useState<any>([]);

  const data = async () => {
    setProgress(true);

    try {
      const response = await fetchData(
        `/organizations/${company.reference}/tasks?page=${page}`,
        {
          method: "GET",
        },
      );

      if (response) {
        setRows([...rows, ...response]);
      }
    } catch (error) {
      setError(true);
    }

    setProgress(false);
    setInitProgress(false);
  };

  useEffect(() => {
    data();
    setHeaderTitle("Atividades");
  }, []);

  useEffect(() => {
    data();
  }, [page]);

  const columns: GridColDef[] = [
    {
      field: "title",
      sortable: false,
      disableColumnMenu: true,
      headerName: "Atividade",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Link component="button" onClick={() => toggleTaskDrawer(params.row)}>
            {params.row.title}
          </Link>
        );
      },
    },
    {
      field: "attachments_count",
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      headerName: "Docs",
      width: 60,
    },
    {
      field: "id",
      align: "center",
      headerAlign: "center",
      sortable: false,
      width: 120,
      disableColumnMenu: true,
      renderCell: (params: any) => {
        return (
          <Button
            size="small"
            startIcon={<CloudDownloadIcon />}
            onClick={() => download(params.id, "zip")}
            variant="outlined"
            color="success"
          >
            Zip
          </Button>
        );
      },
      renderHeader: (params: any) => {
        return <MoreHorizIcon />;
      },
    },
  ];

  return (
    <Card>
      <DataGrid
        rows={rows}
        columns={columns}
        hideFooter={true}
        autoHeight
        loading={initProgress}
        disableRowSelectionOnClick
        slots={{
          loadingOverlay: Progress,
        }}
      />
      {progress && !initProgress && <Progress />}
      {!progress && !initProgress && (
        <Button
          fullWidth
          sx={{ mt: 2 }}
          onClick={() => {
            setPage(page + 1);
          }}
        >
          Carregar mais
        </Button>
      )}
      <TaskDetailDrawer />
    </Card>
  );
}

export default Tasks;
