import axios from "axios";

const headers = {
  Authorization: "",
  "Content-Type": "application/json",
};

const api = () => {
  const token = localStorage.getItem(`${process.env.REACT_APP_KEYNAME}token`);

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers,
  });
};

export default api;
