import { useState } from "react";
import {
  Button,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";

import EmailIcon from "@mui/icons-material/Email";
import TextsmsIcon from "@mui/icons-material/Textsms";

import useNavStore from "../../stores/nav";
import useAuthRecoverStore from "../../stores/authRecover";
import { Stack } from "@mui/system";

const ForgetPasswordStepTwo = () => {
  const snackbar = useNavStore((state) => state.setSnackbar);
  const prevStep = useAuthRecoverStore((state) => state.prevStep);
  const nextStep = useAuthRecoverStore((state) => state.nextStep);
  const result = useAuthRecoverStore((state) => state.result);
  const sendCode = useAuthRecoverStore((state) => state.sendCode);
  const [progress, setProgress] = useState(false);
  const [value, setValue] = useState("");

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newOption: string,
  ) => {
    setValue(newOption);
  };

  const onSubmit = async () => {
    if (!value) {
      snackbar("Selecione como deseja receber o código.", "warning");
      return;
    }

    setProgress(true);

    const result: any = await sendCode(value);

    if (result) {
      nextStep();
    } else {
      snackbar(
        "Ops, no momento não foi possível enviar o código de verificação.",
        "error",
      );
    }

    setProgress(false);
  };

  return (
    <>
      <Typography
        variant="h6"
        component="div"
        gutterBottom
        sx={{ fontWeight: 300, marginBottom: 1 }}
      >
        Como deseja receber o código de verificação?
      </Typography>
      <ToggleButtonGroup
        fullWidth
        color="primary"
        size="large"
        orientation={"vertical"}
        value={value}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
      >
        {result.emails.length > 0 && (
          <ToggleButton value="email">
            <Stack alignItems="center">
              <Stack direction="row" alignItems="center">
                <EmailIcon fontSize="small" sx={{ mr: 1 }} /> Receber por e-mail
              </Stack>
              {result.emails.map((email: string, index: number) => (
                <Typography
                  key={index}
                  variant="body2"
                  textTransform="lowercase"
                >
                  {email}
                </Typography>
              ))}
            </Stack>
          </ToggleButton>
        )}
        {result.phones.length > 0 && (
          <ToggleButton value="sms">
            <Stack alignItems="center">
              <Stack direction="row" alignItems="center">
                <TextsmsIcon fontSize="small" sx={{ mr: 1 }} /> Receber por SMS
              </Stack>
              {result.phones.map((phone: string, index: number) => (
                <Typography
                  key={index}
                  variant="body2"
                  textTransform="lowercase"
                >
                  {phone}
                </Typography>
              ))}
            </Stack>
          </ToggleButton>
        )}
      </ToggleButtonGroup>
      <Button
        fullWidth={true}
        color="primary"
        variant="contained"
        disabled={progress}
        onClick={() => onSubmit()}
        size="large"
        style={{ marginTop: "8px" }}
      >
        {progress && <CircularProgress size={24} />}
        {!progress && "Continuar"}
      </Button>
      <Button
        fullWidth={true}
        color="primary"
        onClick={() => prevStep()}
        size="large"
        style={{ marginTop: "8px" }}
      >
        Voltar
      </Button>
    </>
  );
};

export default ForgetPasswordStepTwo;
