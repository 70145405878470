import { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import {
  Box,
  Drawer,
  Stack,
  Divider,
  Typography,
  IconButton,
  Button,
  Card,
  CardContent,
  TextField,
  CardActions,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  List,
  ListItem,
  ListItemButton,
  Link,
  ToggleButtonGroup,
  ToggleButton,
  Skeleton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import AttachmentIcon from "@mui/icons-material/Attachment";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FolderIcon from "@mui/icons-material/Folder";

import DragAndDropFiles from "../../../../../components/DragAndDropFiles";
import CloudFileFolderDetailDrawer from "../../../Cloud/drawers/detail";

import useAuthStore from "../../../../../stores/auth";
import useCloudStore from "../../../../../stores/cloud";
import useHrmEmployeeStore from "../../../../../stores/hrEmployee";

const HRManagementEmployeeAttachments = (props: any) => {
  const company = useAuthStore((state) => state.company);
  const path = useCloudStore((state) => state.path);
  const progress = useCloudStore((state) => state.progress);
  const getData = useCloudStore((state) => state.getData);
  const setPath = useCloudStore((state) => state.setPath);
  const data = useCloudStore((state) => state.data);
  const person = useHrmEmployeeStore((state) => state.data);
  const detailDrawer = useCloudStore((state) => state.toggleDrawer);
  const [state, setState] = useState(false);
  const [type, setType] = useState("upload");

  useEffect(() => {
    if (state) {
      getData();
    } else {
      setType("upload");
    }
  }, [state]);

  useEffect(() => {
    if (person?._id) {
      setPath(`person_drafts/${person?._id}`);
    }
  }, [person]);

  useEffect(() => {
    if (type === "list") getData();
  }, [type]);

  const handleTypeChange = (e: any, value: string) => {
    if (value !== null) {
      setType(value);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Nome",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Stack
            direction="row"
            spacing={1}
            color="text.main"
            sx={{ cursor: "pointer" }}
            alignItems="center"
            onClick={() =>
              params.row.type === "folder"
                ? setPath(params.row.path)
                : detailDrawer(params.row)
            }
          >
            {params.row.type === "file" && (
              <InsertDriveFileIcon color="secondary" />
            )}
            {params.row.type === "folder" && <FolderIcon color="primary" />}
            <Typography variant="body2">{params.row.name}</Typography>
          </Stack>
        );
      },
    },
  ];

  const Progress = () => {
    return (
      <>
        {Array.from({ length: 2 }, (_, index) => (
          <Stack
            key={index}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            height={52}
            spacing={2}
            sx={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Skeleton
                variant="circular"
                animation="wave"
                width={30}
                height={30}
                sx={{ ml: 1 }}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                width={150}
                height={20}
              />
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              sx={{ pr: 3 }}
            >
              <Skeleton
                variant="rectangular"
                animation="wave"
                width={120}
                height={20}
                sx={{ mr: 2 }}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                width={70}
                height={20}
              />
            </Stack>
          </Stack>
        ))}
      </>
    );
  };

  return (
    <>
      <Button
        fullWidth
        variant="outlined"
        onClick={() => {
          setState(true);
        }}
        disabled={person?.status === "editing" ? !person?._id : true}
      >
        <Stack spacing={1} alignItems="center">
          <AttachmentIcon fontSize="large" />
          <div>Anexos</div>
        </Stack>
      </Button>
      <Drawer
        anchor="left"
        open={state}
        onClose={() => {
          setState(false);
        }}
      >
        <Box sx={{ p: 3, pt: 2, width: 400 }} width={300} height="100%">
          <Stack height="100%" spacing={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack
                direction="row"
                spacing={1}
                sx={{ flex: 1 }}
                alignItems="center"
              >
                <Typography
                  variant="body1"
                  sx={{ width: 170, wordWrap: "break-word" }}
                >
                  Anexos
                </Typography>
              </Stack>
              <IconButton
                onClick={() => {
                  setState(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Divider sx={{ my: 2 }} />
            <ToggleButtonGroup
              color="primary"
              value={type}
              exclusive
              onChange={handleTypeChange}
              fullWidth
              size="small"
            >
              <ToggleButton value="upload">Upload</ToggleButton>
              <ToggleButton value="list">Enviados</ToggleButton>
            </ToggleButtonGroup>
            {type === "upload" && (
              <DragAndDropFiles
                baseUrl={`cloud/${company._id}?path=${path}`}
                allowedFiles=".pdf,.jpeg,.jpg,.gif,.png,.zip,.doc,.docx,.xls,.xlsx"
              />
            )}
            {type === "list" && (
              <>
                <DataGrid
                  rows={data}
                  columnHeaderHeight={0}
                  columns={columns}
                  hideFooter={true}
                  autoHeight
                  loading={progress}
                  disableRowSelectionOnClick
                  slots={{
                    noRowsOverlay: () => (
                      <Stack
                        height="100%"
                        alignItems="center"
                        justifyContent="center"
                      >
                        Nenhum anexo.
                      </Stack>
                    ),
                    loadingOverlay: Progress,
                  }}
                />
                <CloudFileFolderDetailDrawer />
              </>
            )}
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default HRManagementEmployeeAttachments;
