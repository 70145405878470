import { createRef, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import useNavStore from "../../stores/nav";
import useAuthRecoverStore from "../../stores/authRecover";

const ForgetPasswordStepThree = () => {
  const [codes, setCodes] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef<Array<HTMLInputElement | null>>(
    Array(6)
      .fill(null)
      .map(() => null),
  );
  const snackbar = useNavStore((state) => state.setSnackbar);
  const login = useAuthRecoverStore((state) => state.login);
  const nextStep = useAuthRecoverStore((state) => state.nextStep);
  const setLogin = useAuthRecoverStore((state) => state.setLogin);
  const checkCode = useAuthRecoverStore((state) => state.checkCode);
  const [progress, setProgress] = useState(false);

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      onSubmit();
    }
  };

  const handleInputChange = (index: number, value: string) => {
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newCodes = [...codes];
      newCodes[index] = value;
      setCodes(newCodes);

      // Move focus to the next input
      if (index < 5 && value !== "" && inputRefs.current[index + 1]) {
        inputRefs.current[index + 1]!.focus();
      }
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedData = event.clipboardData.getData("text");

    if (/^\d{1,6}$/.test(pastedData)) {
      const newCodes = pastedData.split("").slice(0, 6);
      setCodes(newCodes);

      // Update the input values and move focus to the last input
      newCodes.forEach((code, index) => {
        if (inputRefs.current[index]) {
          inputRefs.current[index]!.value = code;
        }
        if (index < 5 && inputRefs.current[index + 1]) {
          inputRefs.current[index + 1]!.focus();
        }
      });
    }

    event.preventDefault();
  };

  const onSubmit = async () => {
    const isCodeComplete = codes.every((code) => code.length === 1);
    const joinedCode = codes.join("");

    if (!isCodeComplete) {
      snackbar("Informe o código completo para continuar.", "error");
      return;
    }

    setProgress(true);

    const result: any = await checkCode(Number(joinedCode));

    if (!result) {
      snackbar("O código informado expirou ou não é válido", "error");
    } else {
      nextStep();
    }

    setProgress(false);
  };

  return (
    <>
      <Typography
        variant="h6"
        component="div"
        gutterBottom
        sx={{ fontWeight: 300, marginBottom: 1 }}
      >
        Informe o código de verificação para continuar.
      </Typography>
      <Typography
        variant="body2"
        component="div"
        gutterBottom
        sx={{ fontWeight: 300, marginBottom: 1 }}
      >
        O código é válido por até 1 hora.
      </Typography>
      <Stack direction="row" spacing={1}>
        {codes.map((code, index) => (
          <TextField
            key={index}
            inputRef={(el) => (inputRefs.current[index] = el)}
            value={code}
            onChange={(e) => handleInputChange(index, e.target.value)}
            variant="outlined"
            color="primary"
            onPaste={handlePaste}
            margin="dense"
            sx={{ m: 0 }}
            inputProps={{ style: { textAlign: "center" } }}
            autoFocus={index === 0}
          />
        ))}
      </Stack>
      <Button
        fullWidth={true}
        color="primary"
        variant="contained"
        disabled={progress}
        onClick={() => onSubmit()}
        size="large"
        style={{ marginTop: "8px" }}
      >
        {progress && <CircularProgress size={24} />}
        {!progress && "Validar"}
      </Button>
    </>
  );
};

export default ForgetPasswordStepThree;
