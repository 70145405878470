import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Alert, Autocomplete, Stack, TextField } from "@mui/material";
import DatePickerField from "../../../../components/DatePickerField";

import { fetchData } from "../../../../fetchData";
import useTicketStore from "../../../../stores/ticket";
import useAuthStore from "../../../../stores/auth";

const TicketForm = () => {
  const [options, setOptions] = useState<object[]>([]);
  const company = useAuthStore((state) => state.company);
  const organizations: object[] = useAuthStore(
    (state) => state.user?.client || [],
  );
  const ticket = useTicketStore((state) => state.ticket);
  const { type, subject, until_date, description } = useTicketStore(
    (state) => state.ticket,
  );
  const drawer = useTicketStore((state) => state.drawer);
  const setState = useTicketStore((state) => state.setState);
  const reset = useTicketStore((state) => state.reset);
  const step = useTicketStore((state) => state.step);
  const prevStep = useTicketStore((state) => state.prevStep);
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      until_date,
      organization: `[${company.reference}] ${company.name}`,
      subject,
      description,
      type,
    },
  });

  const getOptions = async () => {
    try {
      const response = await fetchData(`/zendesk/ticket/options`, {
        method: "GET",
      });

      if (response) {
        setOptions([{ option: "", _id: "" }, ...response]);
      }
    } catch (error) {
      console.log(error);
      // setError(true);
    }
  };

  async function onSubmit(data: any) {
    const regex = /\[(.*?)\]/;
    const reference = regex.exec(data.organization);

    setState({
      type: data.type,
      subject: data.subject,
      description: data.description,
      until_date: data.until_date,
      reference: parseInt(reference ? reference[1] : ""),
    });
  }

  useEffect(() => {
    getOptions();
  }, []);

  const validate = async () => {
    if (step === 1) {
      console.log(
        await trigger(
          ["until_date", "organization", "subject", "description", "type"],
          {
            shouldFocus: true,
          },
        ),
      );
      if (
        await trigger(
          ["until_date", "organization", "subject", "description", "type"],
          {
            shouldFocus: true,
          },
        )
      ) {
        handleSubmit(onSubmit)();
      } else {
        prevStep();
      }
    }
  };

  useEffect(() => {
    if (!drawer) {
      setValue("organization", `[${company.reference}] ${company.name}`);
      reset();
    }
  }, [drawer]);

  useEffect(() => {
    validate();
  }, [step]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <Autocomplete
          disablePortal
          defaultValue={{
            label: `[${company.reference}] ${company.name}`,
            id: company.reference,
          }}
          options={organizations.map((row: any) => ({
            label: `[${row.reference}] ${row.name}`,
            id: row.reference,
          }))}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              {...register("organization", {
                required: "Informe a empresa da solicitação.",
              })}
              variant="filled"
              label="Empresa"
            />
          )}
        />
        {errors?.organization && (
          <Alert severity="error">
            {errors.organization.message?.toString()}
          </Alert>
        )}
        <Autocomplete
          disablePortal
          value={type}
          onChange={(event: any, value: string) => {
            setState({ ...ticket, type: value });
          }}
          options={options.map((row: any) => row.option)}
          renderInput={(params) => (
            <TextField
              {...params}
              {...register("type", {
                required: "Informe o tipo da solicitação.",
              })}
              variant="filled"
              label="Tipo"
            />
          )}
        />
        {errors?.type && (
          <Alert severity="error">{errors.type.message?.toString()}</Alert>
        )}
        <TextField
          {...register("subject", {
            required: "Informe o assunto da solicitação.",
          })}
          label="Assunto"
          variant="filled"
          helperText="Ex: Admissão Maria do Bairro"
        />
        {errors?.subject && (
          <Alert severity="error">{errors.subject.message?.toString()}</Alert>
        )}
        <TextField
          {...register("description", {
            required: "Forneça detalhes sobre esta solicitação.",
          })}
          label="Descrição"
          variant="filled"
          multiline
          rows={5}
        />
        {errors?.description && (
          <Alert severity="error">
            {errors.description.message?.toString()}
          </Alert>
        )}
        <DatePickerField
          name="until_date"
          register={register}
          get={getValues}
          set={setValue}
          label="Data de Expectativa"
          variant="filled"
          required
          requiredtxt="Informe a data de expectativa."
        />
        {errors?.until_date && (
          <Alert severity="error">
            {errors.until_date.message?.toString()}
          </Alert>
        )}
      </Stack>
    </form>
  );
};

export default TicketForm;
