import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Card,
  Button,
  Tabs,
  Tab,
  Stack,
  Unstable_Grid2 as Grid,
  Typography,
  Skeleton,
  Alert,
  LinearProgress,
  FilledInput,
  Drawer,
  Box,
  IconButton,
  Divider,
  FormControl,
  InputLabel,
  InputAdornment,
  TextField,
  Autocomplete,
  CircularProgress,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";

import DatePickerField from "../../../../components/DatePickerField";
import useNavStore from "../../../../stores/nav";
import useAuthStore from "../../../../stores/auth";
import useContactStore from "../../../../stores/contact";
import { fetchData } from "../../../../fetchData";
import SelectChipField from "../../../../components/SelectChipField";
import TextFieldMasked from "../../../../components/TextFieldMasked";
import { validateCPF } from "../../../../utils/validateCPF";
import { profiles, profileSwitch } from "../../../../utils/profileHandler";
import { removeSpecialCharacters } from "../../../../utils/removeSpecialCharacters";

interface FormValues {
  profile: string[];
  document: string;
  name: string;
  birthdate: string;
  email: string;
  phone: string;
  zipcode: string;
  street: string;
  number: string;
  complement: string;
  district: string;
  city: string;
  state: string;
  client: string[];
  terms: boolean;
}

function NewContactDrawer(props: {
  getData: (limit: number, skip: number) => void;
}) {
  const { getData } = props;
  const snackbar = useNavStore((state) => state.setSnackbar);
  const company = useAuthStore((state) => state.company);
  const organizations = useAuthStore((state) => state.user?.client);
  const state = useContactStore((state) => state.drawer);
  const toggleDrawer = useContactStore((state) => state.toggleDrawer);
  const [activeStep, setActiveStep] = useState(0);
  const [progress, setProgress] = useState(false);
  const [err, setErr] = useState(false);
  const [success, setSuccess] = useState(false);
  const [zipcodeProgress, setZipcodeProgress] = useState(true);
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    trigger,
    setError,
    setFocus,
    reset,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      profile: [],
      document: "",
      name: "",
      birthdate: "",
      email: "",
      phone: "",
      zipcode: "",
      street: "",
      number: "",
      complement: "",
      district: "",
      city: "",
      state: "",
      client: [],
      terms: false,
    },
  });

  const profile: string[] = watch("profile");
  const document: string = watch("document");
  const zipcode: string = watch("zipcode");

  const findAddressByZipcode = async (zipCodeString: string) => {
    await fetch(`https://viacep.com.br/ws/${zipCodeString}/json/`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setValue("state", data.uf);
        setValue("street", data.logradouro);
        setValue("city", data.localidade);
        setValue("district", data.bairro);
      });

    setZipcodeProgress(false);
  };

  useEffect(() => {
    const zipcodeAux = zipcode.replace(/[^0-9]/g, "");
    if (zipcodeAux.length === 8) {
      findAddressByZipcode(zipcodeAux);
    }
  }, [zipcode]);

  const handleNext = async () => {
    if (
      activeStep === 0 &&
      !(await trigger(["name", "document", "birthdate", "email", "phone"], {
        shouldFocus: true,
      }))
    )
      return;
    if (activeStep === 0 && !validateCPF(document)) {
      setError("document", {
        type: "custom",
        message: "CPF inválido. Verifique o número digitado.",
      });
      return;
    }
    if (
      activeStep === 1 &&
      !(await trigger(
        ["zipcode", "street", "number", "district", "city", "state"],
        {
          shouldFocus: true,
        },
      ))
    )
      return;
    if (
      activeStep === 2 &&
      !(await trigger(["profile"], {
        shouldFocus: true,
      }))
    )
      return;

    if (activeStep + 1 < steps.length)
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  async function onSubmit(data: any) {
    const randomNumber = Math.floor(Math.random() * 1000);
    const name = data.name.split(" ");
    const firstName = removeSpecialCharacters(name[0]).toLowerCase();
    setProgress(true);

    data.status = true;
    data.login = `${firstName}.${company.reference}.${randomNumber}`;
    data.profile = data.profile.map((item: string) =>
      profileSwitch(item, true),
    );
    data.email = [{ label: "work", value: data.email, primary: true }];
    data.phone = [{ label: "mobile", value: data.phone, primary: true }];

    try {
      await fetchData(`/users`, {
        method: "POST",
        body: JSON.stringify(data),
      });

      setSuccess(true);
      snackbar("Cadastro realizado com sucesso!", "success");
      getData(23, 0);
    } catch (error) {
      setErr(true);
      snackbar("Ops! No momento não foi possível realizar a ação.", "error");
    }

    setProgress(false);
  }

  useEffect(() => {
    if (!state) {
      setErr(false);
      setSuccess(false);
      reset();
      setActiveStep(0);
    }
  }, [state]);

  const ErrorAlert = () => {
    return (
      <Alert
        severity="error"
        sx={{
          flexDirection: "column",
          alignItems: "center",
          textAlign: "cenrer",
        }}
      >
        <Stack alignItems="center" direction="column">
          <Typography textAlign="center" variant="h5">
            Ops!
          </Typography>
          <Typography textAlign="center">
            Não foi possível cadatrar o contato no momento. Tente novamente mais
            tarde.
          </Typography>
          <Button
            variant="contained"
            onClick={() => toggleDrawer()}
            sx={{ mt: 2 }}
          >
            Fechar
          </Button>
        </Stack>
      </Alert>
    );
  };

  const SuccessAlert = () => {
    return (
      <Alert
        severity="success"
        sx={{
          height: "100%",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "cenrer",
        }}
      >
        <Stack alignItems="center" direction="column">
          <Typography textAlign="center" variant="h5">
            Muito bem!
          </Typography>
          <Typography textAlign="center">
            Cadastro realizado com sucesso.
          </Typography>
          <Button
            variant="contained"
            onClick={() => toggleDrawer()}
            sx={{ mt: 2 }}
          >
            Fechar
          </Button>
        </Stack>
      </Alert>
    );
  };

  const steps = [
    {
      label: "Dados Pessoais",
      description: ``,
      outlet: (
        <Stack spacing={1}>
          <TextField
            {...register("name", {
              required: "Informe o nome do contato.",
            })}
            label="Nome"
            variant="filled"
          />
          {errors?.name && (
            <Alert severity="error">{errors.name.message?.toString()}</Alert>
          )}
          <TextFieldMasked
            register={register}
            getValues={getValues}
            format="###.###.###-##"
            name="document"
            autoComplete="password"
            fullWidth
            variant="filled"
            required="Informe o CPF do contato."
            label="CPF"
          />
          {errors?.document && (
            <Alert severity="error">
              {errors.document.message?.toString()}
            </Alert>
          )}
          <DatePickerField
            name="birthdate"
            register={register}
            get={getValues}
            set={setValue}
            label="Data de Nascimento"
            variant="filled"
            required
            requiredtxt="Informe a data de nascimento."
          />
          {errors?.birthdate && (
            <Alert severity="error">
              {errors.birthdate.message?.toString()}
            </Alert>
          )}
          <TextField
            {...register("email", {
              required: "Informe o E-mail do contato.",
            })}
            label="E-mail"
            variant="filled"
          />
          {errors?.email && (
            <Alert severity="error">{errors.email.message?.toString()}</Alert>
          )}
          <TextFieldMasked
            register={register}
            getValues={getValues}
            format="(##) #####-####"
            name="phone"
            autoComplete="password"
            fullWidth
            variant="filled"
            required="Informe o Telefone do contato."
            label="Telefone"
          />
          {errors?.phone && (
            <Alert severity="error">{errors.phone.message?.toString()}</Alert>
          )}
        </Stack>
      ),
    },
    {
      label: "Endereço",
      description: "",
      outlet: (
        <Stack spacing={1}>
          <TextFieldMasked
            register={register}
            getValues={getValues}
            format="#####-###"
            name="zipcode"
            autoComplete="password"
            fullWidth
            variant="filled"
            required="Informe o CEP do endereço."
            label="CEP"
          />
          {errors?.zipcode && (
            <Alert severity="error">{errors.zipcode.message?.toString()}</Alert>
          )}
          <TextField
            {...register("street", {
              required: "Informe o logradouro do endereço.",
            })}
            label="Logradouro"
            variant="filled"
            InputLabelProps={{ shrink: true }}
            disabled={zipcodeProgress}
          />
          {errors?.street && (
            <Alert severity="error">{errors.street.message?.toString()}</Alert>
          )}
          <TextField
            {...register("number", {
              required: "Informe o número do endereço.",
            })}
            label="Número"
            variant="filled"
          />
          {errors?.number && (
            <Alert severity="error">{errors.number.message?.toString()}</Alert>
          )}
          <TextField
            {...register("complement")}
            label="Complemento"
            variant="filled"
          />
          <TextField
            {...register("district", {
              required: "Informe o bairro do endereço.",
            })}
            label="Bairro"
            variant="filled"
            InputLabelProps={{ shrink: true }}
            disabled={zipcodeProgress}
          />
          {errors?.district && (
            <Alert severity="error">
              {errors.district.message?.toString()}
            </Alert>
          )}
          <TextField
            {...register("city", {
              required: "Informe a cidade do endereço.",
            })}
            label="Cidade"
            variant="filled"
            InputLabelProps={{ shrink: true }}
            disabled={zipcodeProgress}
          />
          {errors?.city && (
            <Alert severity="error">{errors.city.message?.toString()}</Alert>
          )}
          <TextField
            {...register("state", {
              required: "Informe o Estado do endereço.",
            })}
            label="Estado"
            variant="filled"
            InputLabelProps={{ shrink: true }}
            disabled={zipcodeProgress}
          />
          {errors?.state && (
            <Alert severity="error">{errors.state.message?.toString()}</Alert>
          )}
        </Stack>
      ),
    },
    {
      label: "Perfil de Acesso",
      description: ``,
      outlet: (
        <Stack spacing={1}>
          <SelectChipField
            name="profile"
            register={register}
            get={getValues}
            required="Informe o perfil de acesso do contato."
            label="Perfil"
            error={errors?.profile ? true : false}
          >
            {profiles.map((profile) => (
              <MenuItem key={profile.value} value={profile.value}>
                {profile.value}
              </MenuItem>
            ))}
          </SelectChipField>
          {errors?.profile && (
            <Alert severity="error">{errors.profile.message?.toString()}</Alert>
          )}
          {profile.length > 0 && (
            <Alert severity="info">
              <Typography variant="body2">
                {profile.includes("Administrativo") && (
                  <>
                    <b>Administrativo</b>
                    <br />O usuário terá acesso as informações/documentos de
                    todas as áreas.
                    <br />
                  </>
                )}
                {profile.includes("Financeiro") && (
                  <>
                    <b>Financeiro</b>
                    <br />O usuário terá acesso as informações/documentos
                    somente da área Contábil (Balancete, Balanço e DRE) e o que
                    corresponde ao financeiro junto ao Atlântico.
                    <br />
                  </>
                )}
                {profile.includes("Recursos Humanos") && (
                  <>
                    <b>Recursos Humanos</b>
                    <br />O usuário terá acesso as informações/documentos
                    somente da área de Departamento Pessoal (Folha de Pagamento,
                    Férias, Admissão, Rescisão, etc).
                    <br />
                  </>
                )}
                {profile.includes("Fiscal e Tributário") && (
                  <>
                    <b>Fiscal e Tributário</b>
                    <br />O usuário terá acesso as informações/documentos
                    somente da área Fiscal (Guias de Impostos, Taxas, etc).
                    <br />
                  </>
                )}
              </Typography>
            </Alert>
          )}
        </Stack>
      ),
    },
    {
      label: "Empresas de Acesso",
      description: ``,
      outlet: (
        <Stack spacing={1}>
          <Controller
            name="client"
            rules={{
              required:
                "Selecione ao menos uma empresa que o contato terá acesso.",
            }}
            control={control}
            render={({ field }) => (
              <FormGroup>
                {organizations?.map((row: any) => (
                  <>
                    <FormControlLabel
                      key={row._id}
                      sx={{ fontSize: ".6em" }}
                      control={
                        <Checkbox
                          {...field}
                          value={row.reference}
                          checked={field.value.includes(row.reference)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              field.onChange([...field.value, row.reference]);
                            } else {
                              field.onChange(
                                field.value.filter(
                                  (org) => org !== row.reference,
                                ),
                              );
                            }
                          }}
                        />
                      }
                      label={
                        <Box width={250}>
                          <Tooltip title={row.name}>
                            <Typography variant="body2" noWrap>
                              [{row.reference}] {row.name}
                            </Typography>
                          </Tooltip>
                        </Box>
                      }
                    />
                    <Divider />
                  </>
                ))}
              </FormGroup>
            )}
          />
          {errors?.client && (
            <Alert severity="error">{errors.client.message?.toString()}</Alert>
          )}
        </Stack>
      ),
    },
    {
      label: "Termos de Uso",
      description:
        "Submetendo este formulário, você autoriza esta pessoa para manter o relacionamento com os canais de comunicação disponibilizados pelo Atlântico. O relacionamento se dará tanto para obter informações, tanto para fornecer como base para os serviços realizados pelo Atlântico, observando suas respectivas áreas de atuação acima especificadas.",
      outlet: (
        <>
          <FormControlLabel
            control={
              <Checkbox
                {...register("terms", {
                  required: "Você precisa concordar com os termos de uso.",
                })}
              />
            }
            label="Declaro que li e concordo com os termos de uso do Atlântico."
          />
          {errors?.terms && (
            <Alert severity="error">{errors.terms.message?.toString()}</Alert>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Drawer
        anchor="left"
        open={state}
        onClose={() => {
          toggleDrawer();
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ p: 3, pt: 2, width: 400 }} width={300} height="100%">
            <Stack height="100%" spacing={2} sx={{ pb: 10 }}>
              <Stack direction="row" spacing={1} alignItems="center">
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ flex: 1 }}
                  alignItems="center"
                >
                  <Typography
                    variant="body1"
                    sx={{ width: 170, wordWrap: "break-word" }}
                  >
                    Novo Contato
                  </Typography>
                </Stack>
                <IconButton
                  onClick={() => {
                    toggleDrawer();
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>
              <Divider sx={{ my: 2 }} />
              {success && <SuccessAlert />}
              {err && <ErrorAlert />}
              {!err && !success && (
                <Stepper activeStep={activeStep} orientation="vertical">
                  {steps.map((step, index) => (
                    <Step key={step.label}>
                      <StepLabel>{step.label}</StepLabel>
                      <StepContent>
                        {step.description && (
                          <Typography variant="body2" sx={{ mb: 2 }}>
                            {step.description}
                          </Typography>
                        )}
                        {step.outlet}
                        <Box sx={{ mb: 2 }}>
                          <div>
                            <Button
                              size="small"
                              variant="contained"
                              onClick={handleNext}
                              sx={{ mt: 1, mr: 1 }}
                              type={
                                index === steps.length - 1 ? "submit" : "button"
                              }
                            >
                              {index === steps.length - 1
                                ? "Salvar"
                                : "Continuar"}
                            </Button>
                            <Button
                              size="small"
                              disabled={index === 0}
                              onClick={handleBack}
                              sx={{ mt: 1, mr: 1 }}
                            >
                              Voltar
                            </Button>
                          </div>
                        </Box>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              )}
            </Stack>
          </Box>
        </form>
      </Drawer>
    </>
  );
}

export default NewContactDrawer;
