import { useState, useEffect } from "react";
import {
  DataGrid as XDataGrid,
  DataGridProps,
  GridPaginationModel,
  ptBR,
} from "@mui/x-data-grid";
import { Skeleton, Stack } from "@mui/material";

export const Progress = () => {
  return (
    <>
      {Array.from({ length: 2 }, (_, index) => (
        <Stack
          key={index}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          height={52}
          sx={{ borderBottom: "1px solid rgba(224, 224, 224, 1)", px: 2 }}
        >
          <Skeleton variant="rectangular" width="100%" height={20} />
        </Stack>
      ))}
    </>
  );
};

interface Props extends DataGridProps {
  rows: any;
  data: (limit: number, skip: number) => void;
  loading: boolean;
  columns: any;
  rowCount: number;
  paginationModel: GridPaginationModel;
  noRowsOverlay: any;
}

const DataGrid = (props: Props) => {
  const [paginationModel, setPaginationModel] = useState(props.paginationModel);

  useEffect(() => {
    props.data(
      paginationModel.pageSize,
      paginationModel.page * paginationModel.pageSize,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel]);

  return (
    <>
      <XDataGrid
        getRowId={() => Math.floor(Math.random() * 100000000)}
        rows={props.rows}
        disableRowSelectionOnClick
        disableDensitySelector
        disableColumnSelector
        disableColumnMenu
        autoHeight
        loading={props.loading}
        columns={props.columns}
        rowCount={props.rowCount}
        pageSizeOptions={[props.paginationModel.pageSize]}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        // onCellClick={(params)=>{console.log(params)}}
        slots={{
          noRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              {props.noRowsOverlay}
            </Stack>
          ),
          loadingOverlay: Progress,
        }}
      />
    </>
  );
};

DataGrid.defaultProps = {
  noRowsOverlay: "Nenhuma linha",
  paginationModel: {
    page: 0,
    pageSize: 25,
  },
};

export default DataGrid;
