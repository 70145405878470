import { PatternFormat } from "react-number-format";
import { TextField } from "@mui/material";

const TextFieldMasked = (props: any) => {
  const { register, required, getValues, ...inputProps } = props;
  const { name } = props;
  // eslint-disable-next-line react/destructuring-assignment
  const { onChange, onBlur, ref } = props.register(name, {
    // eslint-disable-next-line react/destructuring-assignment
    required: props.required,
  });

  return (
    <PatternFormat
      {...inputProps}
      // eslint-disable-next-line react/destructuring-assignment
      value={props.getValues(name)}
      customInput={TextField}
      inputRef={ref}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

export default TextFieldMasked;
