export function validateCPF(cpf: string): boolean {
  // Remove any non-numeric characters from the CPF string
  const cleanedCPF = cpf.replace(/[^0-9]/g, "");

  // Check for special cases where CPF is invalid
  if (cleanedCPF === "00000000000") return false;
  if (cleanedCPF === "11111111111") return false;

  // Calculate the first verification digit
  let sum = 0;
  for (let i = 1; i <= 9; i++) {
    sum += parseInt(cleanedCPF.charAt(i - 1)) * (11 - i);
  }
  let remainder = (sum * 10) % 11;

  if (remainder === 10 || remainder === 11) remainder = 0;

  // Verify the first verification digit
  if (remainder !== parseInt(cleanedCPF.charAt(9))) return false;

  // Calculate the second verification digit
  sum = 0;
  for (let i = 1; i <= 10; i++) {
    sum += parseInt(cleanedCPF.charAt(i - 1)) * (12 - i);
  }
  remainder = (sum * 10) % 11;

  if (remainder === 10 || remainder === 11) remainder = 0;

  // Verify the second verification digit
  if (remainder !== parseInt(cleanedCPF.charAt(10))) return false;

  // If all checks pass, the CPF is valid
  return true;
}
