import React, { useState } from "react";
import { IconButton, Menu, MenuItem, Box, Typography } from "@mui/material";

import MoreVertIcon from "@mui/icons-material/MoreVert";

interface Props {
  items: Array<object>;
}

const ActionMenu = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {props.items.map((item: any) => (
          <MenuItem
            key={item.title}
            onClick={() => {
              item.onClick();
              handleClose();
            }}
            disableRipple
          >
            {item.icon}
            <Typography fontSize={14} sx={{ ml: 1 }}>
              {item.title}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ActionMenu;
