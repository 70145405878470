import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import TextFieldMasked from "../../../../../components/TextFieldMasked";

import {
  Box,
  Drawer,
  Stack,
  Divider,
  Typography,
  IconButton,
  Button,
  TextField,
  Alert,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import useHrmEmployeeStore from "../../../../../stores/hrEmployee";

const HRManagementEmployeeDependentDrawer = () => {
  const data = useHrmEmployeeStore((state: any) => state.data);
  const state = useHrmEmployeeStore((state: any) => state.dependentDrawer);
  const add = useHrmEmployeeStore((state: any) => state.addDependent);
  const remove = useHrmEmployeeStore((state: any) => state.removeDependent);
  const [isDeleting, setIsDeleting] = useState(false);
  const currentDependent = useHrmEmployeeStore(
    (state: any) => state.currentDependent,
  );
  const setState = useHrmEmployeeStore(
    (state: any) => state.toggleDependentDrawer,
  );
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      nome: "",
      cpf: "",
      data_de_nascimento: "",
      parentesco: "",
      genero: "",
      estado_civil: "",
      grau_de_instrucao: "",
      nacionalidade: "",
      imposto_renda: "",
    },
  });

  async function onSubmit(data: any) {
    add(data);
  }

  useEffect(() => {
    if (!state) {
      reset();
      setIsDeleting(false);
    } else {
      if (currentDependent !== undefined) {
        const dependente = data.draft.dependentes[currentDependent];

        setValue("nome", dependente.nome);
        setValue("cpf", dependente.cpf);
        setValue("data_de_nascimento", dependente.data_de_nascimento);
        setValue("parentesco", dependente.parentesco);
        setValue("genero", dependente.genero);
        setValue("estado_civil", dependente.estado_civil);
        setValue("grau_de_instrucao", dependente.grau_de_instrucao);
        setValue("nacionalidade", dependente.nacionalidade);
        setValue("imposto_renda", dependente.imposto_renda);
      }
    }
  }, [state]);

  return (
    <>
      <Drawer anchor="right" open={state} onClose={() => setState()}>
        <Box sx={{ p: 3, pt: 2, width: 400 }} width={300} height="100%">
          <Stack height="100%" spacing={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack
                direction="row"
                spacing={1}
                sx={{ flex: 1 }}
                alignItems="center"
              >
                <Typography variant="body1">Dependente</Typography>
              </Stack>
              <IconButton
                onClick={() => {
                  setState();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Divider sx={{ my: 2 }} />
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField
                {...register("nome", {
                  required: "Informe o nome.",
                })}
                fullWidth
                variant="filled"
                label="Nome Completo"
                defaultValue={state.draft?.nome || null}
                sx={{ mb: 1 }}
              />
              {errors?.nome && (
                <Alert severity="error">
                  {errors.nome.message?.toString()}
                </Alert>
              )}
              <TextFieldMasked
                register={register}
                getValues={getValues}
                format="###.###.###-##"
                name="cpf"
                autoComplete="password"
                fullWidth
                variant="filled"
                required="Informe o CPF."
                label="CPF"
                sx={{ mb: 1 }}
              />
              {errors?.cpf && (
                <Alert severity="error">{errors.cpf.message?.toString()}</Alert>
              )}
              <TextFieldMasked
                register={register}
                getValues={getValues}
                format="##/##/####"
                name="data_de_nascimento"
                fullWidth
                variant="filled"
                required="Informe a data de nascimento."
                label="Data de Nascimento"
                sx={{ mb: 1 }}
              />
              {errors?.data_de_nascimento && (
                <Alert severity="error">
                  {errors.data_de_nascimento.message?.toString()}
                </Alert>
              )}
              <TextField
                {...register("parentesco", {
                  required: "Informe o grau de parentesco.",
                })}
                fullWidth
                variant="filled"
                select
                size="small"
                label="Parentesco"
                SelectProps={{ native: true }}
                sx={{ mb: 1 }}
              >
                <option value=""></option>
                <option value="Outros">Outros</option>
                <option value="Menor pobre, até 21 (vinte e um) anos, que crie e eduque e do qual detenha a guarda judicial">
                  Menor pobre, até 21 (vinte e um) anos, que crie e eduque e do
                  qual detenha a guarda judicial
                </option>
                <option value="Menor Sob Tutela">Menor Sob Tutela</option>
                <option value="Filho(a) ou enteado(a) em qualquer idade, quando incapacitado física e/ou mentalmente para o trabalho">
                  Filho(a) ou enteado(a) em qualquer idade, quando incapacitado
                  física e/ou mentalmente para o trabalho
                </option>
                <option value="Filho(a) ou enteado(a) universitário(a) ou cursando escola técnica de 2º grau, até 24 (vinte e quatro) anos">
                  Filho(a) ou enteado(a) universitário(a) ou cursando escola
                  técnica de 2º grau, até 24 (vinte e quatro) anos
                </option>
                <option value="Filho(a) Adotivo(a)">Filho(a) Adotivo(a)</option>
                <option value="Irmão(ã), neto(a) ou bisneto(a) sem arrimo dos pais, do(a) qual detenha a guarda judicial, em qualquer idade, quando incapacitado física e/ou mentalmente para o trabalho">
                  Irmão(ã), neto(a) ou bisneto(a) sem arrimo dos pais, do(a)
                  qual detenha a guarda judicial, em qualquer idade, quando
                  incapacitado física e/ou mentalmente para o trabalho
                </option>
                <option value="Irmão(ã), neto(a) ou bisneto(a) sem arrimo dos pais, com idade até 24 anos, se ainda estiver cursando estabelecimento de nível superior ou escola técnica de 2º grau, desde que tenha detido sua guarda judicial até os 21 anos">
                  Irmão(ã), neto(a) ou bisneto(a) sem arrimo dos pais, com idade
                  até 24 anos, se ainda estiver cursando estabelecimento de
                  nível superior ou escola técnica de 2º grau, desde que tenha
                  detido sua guarda judicial até os 21 anos
                </option>
                <option value="Irmão/Irmã">Irmão/Irmã</option>
                <option value="Enteado/Enteada">Enteado/Enteada</option>
                <option value="Genro/Nora">Genro/Nora</option>
                <option value="Sogro/Sogra">Sogro/Sogra</option>
                <option value="Neto/Neta">Neto/Neta</option>
                <option value="Tio/Tia">Tio/Tia</option>
                <option value="Sobrinho/Sobrinha">Sobrinho/Sobrinha</option>
                <option value="Bisavô/Bisavó">Bisavô/Bisavó</option>
                <option value="Avô/Avó">Avô/Avó</option>
                <option value="Pai/Mãe">Pai/Mãe</option>
                <option value="Companheiro(a) com o(a) qual tenha filho ou viva há mais de 5 (cinco) anos">
                  Companheiro(a) com o(a) qual tenha filho ou viva há mais de 5
                  (cinco) anos
                </option>
                <option value="Ex-cônjuge que Receba Pensão de Alimentos">
                  Ex-cônjuge que Receba Pensão de Alimentos
                </option>
                <option value="Cônjuge">Cônjuge</option>
                <option value="Filho/Filha">Filho/Filha</option>
              </TextField>
              {errors?.parentesco && (
                <Alert severity="error">
                  {errors.parentesco.message?.toString()}
                </Alert>
              )}
              <TextField
                {...register("genero", {
                  required: "Informe o gênero.",
                })}
                fullWidth
                variant="filled"
                select
                size="small"
                label="Gênero"
                SelectProps={{ native: true }}
                sx={{ mb: 1 }}
              >
                <option value=""></option>
                <option value="Masculino">Masculino</option>
                <option value="Feminino">Feminino</option>
              </TextField>
              {errors?.genero && (
                <Alert severity="error">
                  {errors.genero.message?.toString()}
                </Alert>
              )}
              <TextField
                {...register("estado_civil", {
                  required: "Informe o estado civil.",
                })}
                fullWidth
                variant="filled"
                select
                size="small"
                label="Estado Civil"
                defaultValue={data.draft?.estado_civil || null}
                SelectProps={{ native: true }}
                sx={{ mb: 1 }}
              >
                <option value=""></option>
                <option value="Outros">Outros</option>
                <option value="Concubinato">Concubinato</option>
                <option value="Viúvo">Viúvo</option>
                <option value="Divorciado ou Separado Judicialmente">
                  Divorciado ou Separado Judicialmente
                </option>
                <option value="Casado - Comunhão Parcial de Bens">
                  Casado - Comunhão Parcial de Bens
                </option>
                <option value="Casado - Sem Comunhão de Bens">
                  Casado - Sem Comunhão de Bens
                </option>
                <option value="Casado - Comunhão Total de Bens">
                  Casado - Comunhão Total de Bens
                </option>
                <option value="Casado">Casado</option>
                <option value="Solteiro">Solteiro</option>
                <option value="Selecione o Estado Civil">
                  Selecione o Estado Civil
                </option>
              </TextField>
              {errors?.estado_civil && (
                <Alert severity="error">
                  {errors.estado_civil.message?.toString()}
                </Alert>
              )}
              <TextField
                {...register("grau_de_instrucao", {
                  required: "Informe o grau de instrução.",
                })}
                fullWidth
                variant="filled"
                select
                size="small"
                label="Grau de Instrução"
                SelectProps={{ native: true }}
                sx={{ mb: 1 }}
              >
                <option value=""></option>
                <option value="Ph. D.">Ph. D.</option>
                <option value="Doutorado completo">Doutorado completo</option>
                <option value="Mestrado completo">Mestrado completo</option>
                <option value="Especialização">Especialização</option>
                <option value="Educação Superior completa">
                  Educação Superior completa
                </option>
                <option value="Educação Superior incompleta">
                  Educação Superior incompleta
                </option>
                <option value="Ensino médio completo">
                  Ensino médio completo
                </option>
                <option value="Ensino médio incompleto">
                  Ensino médio incompleto
                </option>
                <option value="Ensino fundamental completo">
                  Ensino fundamental completo
                </option>
                <option value="Do 6º ao 9º ano do Ensino Fundamental incompleto">
                  Do 6º ao 9º ano do Ensino Fundamental incompleto
                </option>
                <option value="5º ano completo do Ensino Fundamental">
                  5º ano completo do Ensino Fundamental
                </option>
                <option value="Até o 5º ano incompleto do Ensino Fundamental">
                  Até o 5º ano incompleto do Ensino Fundamental
                </option>
                <option value="Analfabeto">Analfabeto</option>
              </TextField>
              {errors?.grau_de_instrucao && (
                <Alert severity="error">
                  {errors.grau_de_instrucao.message?.toString()}
                </Alert>
              )}
              <TextField
                {...register("nacionalidade", {
                  required: "Informe a nacionalidade.",
                })}
                fullWidth
                variant="filled"
                select
                size="small"
                label="Nacionalidade"
                defaultValue={data.draft?.nacionalidade || null}
                SelectProps={{ native: true }}
                sx={{ mb: 1 }}
              >
                <option value=""></option>
                <option value="Brasileiro">Brasileiro</option>
                <option value="Brasileiro nascido no exterior">
                  Brasileiro nascido no exterior
                </option>
                <option value="Naturalizado brasileiro">
                  Naturalizado brasileiro
                </option>
                <option value="Argentino">Argentino</option>
                <option value="Boliviano">Boliviano</option>
                <option value="Chileno">Chileno</option>
                <option value="Paraguaio">Paraguaio</option>
                <option value="Uruguaio">Uruguaio</option>
                <option value="Venezuelano">Venezuelano</option>
                <option value="Colombiano">Colombiano</option>
                <option value="Peruano">Peruano</option>
                <option value="Equatoriano">Equatoriano</option>
                <option value="Alemão">Alemão</option>
                <option value="Belga">Belga</option>
                <option value="Britânico">Britânico</option>
                <option value="Canadense">Canadense</option>
                <option value="Espanhol">Espanhol</option>
                <option value="Norte-americano(EUA)">
                  Norte-americano(EUA)
                </option>
                <option value="Francês">Francês</option>
                <option value="Suíço">Suíço</option>
                <option value="Italiano">Italiano</option>
                <option value="Haitiano">Haitiano</option>
                <option value="Japonês">Japonês</option>
                <option value="Chinês">Chinês</option>
                <option value="Coreano">Coreano</option>
                <option value="Russo">Russo</option>
                <option value="Português">Português</option>
                <option value="Paquistanês">Paquistanês</option>
                <option value="Indiano">Indiano</option>
                <option value="Outros latino-americanos">
                  Outros latino-americanos
                </option>
                <option value="Outros asiáticos">Outros asiáticos</option>
                <option value="Outros">Outros</option>
                <option value="Outros Europeus">Outros Europeus</option>
                <option value="Angolano">Angolano</option>
                <option value="Congolês">Congolês</option>
                <option value="Sul-Africano">Sul-Africano</option>
                <option value="Outros Africanos">Outros Africanos</option>
              </TextField>
              {errors?.nacionalidade && (
                <Alert severity="error" sx={{ mb: 1 }}>
                  {errors.nacionalidade.message?.toString()}
                </Alert>
              )}
              <TextField
                {...register("imposto_renda", {
                  required:
                    "Informe se é dependente para fins de imposto de renda.",
                })}
                fullWidth
                variant="filled"
                select
                size="small"
                label="É dependente para fins de Imposto de Renda?"
                SelectProps={{ native: true }}
                sx={{ mb: 1 }}
              >
                <option value=""></option>
                <option value="Sim">Sim</option>
                <option value="Não">Não</option>
              </TextField>
              {errors?.imposto_renda && (
                <Alert severity="error">
                  {errors.imposto_renda.message?.toString()}
                </Alert>
              )}
              <Button type="submit" variant="contained" size="medium" fullWidth>
                Salvar
              </Button>
              <Button
                type="button"
                variant="outlined"
                size="small"
                color="secondary"
                fullWidth
                sx={{ mt: 1, mb: 2 }}
                onClick={() => setState()}
              >
                Cancelar
              </Button>
              {currentDependent !== undefined && (
                <>
                  <Divider sx={{ mb: 2 }} />
                  {!isDeleting && (
                    <Button
                      type="button"
                      variant="contained"
                      size="small"
                      color="error"
                      fullWidth
                      sx={{ mb: 2 }}
                      onClick={() => setIsDeleting(true)}
                    >
                      Excluir
                    </Button>
                  )}
                  {isDeleting && (
                    <>
                      <Typography
                        variant="body2"
                        fontWeight="bold"
                        textAlign="center"
                        sx={{ mb: 1 }}
                      >
                        Confirma exclusão?
                      </Typography>
                      <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                        <Button
                          variant="outlined"
                          size="small"
                          color="error"
                          fullWidth
                          onClick={() => remove()}
                        >
                          SIM
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          color="secondary"
                          fullWidth
                          onClick={() => setIsDeleting(false)}
                        >
                          NÃO
                        </Button>
                      </Stack>
                    </>
                  )}
                </>
              )}
            </form>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default HRManagementEmployeeDependentDrawer;
