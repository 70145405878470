import { BrowserRouter, Route, Routes } from "react-router-dom";

import Client from "../layouts/Client/";
import Dashboard from "../views/Client/Dashboard";
import HRManagement from "../views/Client/HRManagement";
import HRManagementEventsDetail from "../views/Client/HRManagement/EventsDetail";
import HRManagementEmployee from "../views/Client/HRManagement/Employee";
import MyCompany from "../views/Client/MyCompany";
import Tasks from "../views/Client/Tasks";
import Cloud from "../views/Client/Cloud";
import Contacts from "../views/Client/Contacts";
import Help from "../views/Client/Help";

export const ClientRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Client />}>
          <Route index element={<Dashboard />} />
          <Route path="/hr">
            <Route index element={<HRManagement />} />
            {["competence/:id", "competence/:id/detail"].map((path) => (
              <Route
                key={path}
                path={path}
                element={<HRManagementEventsDetail />}
              />
            ))}
            {["employee", "employee/:id"].map((path) => (
              <Route
                key={path}
                path={path}
                element={<HRManagementEmployee />}
              />
            ))}
          </Route>
          <Route path="contacts" element={<Contacts />} />
          <Route path="mycompany" element={<MyCompany />} />
          <Route path="tasks" element={<Tasks />} />
          <Route path="cloud" element={<Cloud />} />
          <Route path="help" element={<Help />} />
          <Route path="*" element={<Dashboard />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
