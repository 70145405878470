import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import { CloseSharp } from "@mui/icons-material";
import useNavStore from "../../stores/nav";

interface SnackbarProps {
  anchorOrigin?: any;
}

const SimpleSnackbar: React.FC<SnackbarProps> = ({
  anchorOrigin,
}: SnackbarProps) => {
  const snackbar = useNavStore((state) => state.snackbar);
  const close = useNavStore((state) => state.closeSnackbar);

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={close}
      >
        <CloseSharp fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      open={snackbar.state}
      autoHideDuration={6000}
      onClose={close}
      action={action}
    >
      <Alert
        onClose={close}
        severity={snackbar.severity}
        sx={{ width: "100%" }}
      >
        {snackbar.message}
      </Alert>
    </Snackbar>
  );
};

SimpleSnackbar.defaultProps = {
  anchorOrigin: {
    vertical: "top",
    horizontal: "right",
  },
};

export default SimpleSnackbar;
